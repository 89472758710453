<div id="installer_buttons">
  <h5 class="brandfont-bold" *ngIf="betaOnly">Installers</h5>
  <div class="installer_btn_holder" [class.d-flex]="!isSideBar" [class.flex-wrap]="!isSideBar">
    <ng-container *ngIf="(myOs == 'mac' || showAllSystems) && downloads && downloads.installers && downloads.installers.mac">
      <div class="m-b-10" [class.installer-btn-pill]="!isSideBar" *ngFor="let dl of downloads.installers.mac">
        <span (click)="downloadTokenizedAsset(dl)">
          <ng-container *ngIf="dl.stringId != 'capture.public.mac' && dl.stringId != 'uc.public.mac'">
            <h6 class="brandfont-bold m-b-10">Installer for Mac</h6>
            <button class="btn btn-primary">
              <i class="fa fa-apple" *ngIf="product.contractId != 'subscriptiontrial'"></i>
              {{ (product.contractId == "subscriptiontrial" ? "product_detail.start_trial" : "download.download") | translate }}
            </button>
          </ng-container>
          <ng-container *ngIf="dl.stringId == 'capture.public.mac' || dl.stringId == 'uc.public.mac'">
            <h6 class="brandfont-bold m-b-10">{{ dl.stringId == "capture.public.mac" ? "Capture" : "Universal Control" }} for Mac</h6>
            <button class="btn btn-primary">
              <i class="fa fa-apple" *ngIf="product.contractId != 'subscriptiontrial'"></i>
              {{ "download.download" | translate }}
            </button>
          </ng-container>
        </span>

        <div>
          <span [class]="dl.beta ? 'badge badge-success' : 'badge badge-secondary'">{{ dl.beta ? "BETA v" : "v" }}{{ dl.version }}</span>
          <br />
          <span class="text-muted d-block t-12">{{ dl.releaseDate | date }}</span>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="(myOs == 'win' || showAllSystems) && downloads && downloads.installers && downloads.installers.win">
      <div class="m-b-20" [class.installer-btn-pill]="!isSideBar" *ngFor="let dl of downloads.installers.win">
        <span (click)="downloadTokenizedAsset(dl)">
          <ng-container *ngIf="dl.stringId != 'capture.public.win' && dl.stringId != 'uc.public.win'">
            <h6 class="brandfont-bold m-b-10" [class.m-t-20]="isSideBar">Installer for Windows</h6>
            <button class="btn btn-primary">
              <i class="fa fa-windows" *ngIf="product.contractId != 'subscriptiontrial'"></i>
              {{ (product.contractId == "subscriptiontrial" ? "product_detail.start_trial" : "download.download") | translate }}
            </button>
          </ng-container>
          <ng-container *ngIf="dl.stringId == 'capture.public.win' || dl.stringId == 'uc.public.win'">
            <h6 class="brandfont-bold m-b-10" [class.m-t-20]="isSideBar">{{ dl.stringId == "capture.public.win" ? "Capture" : "Universal Control" }} for Windows</h6>
            <button class="btn btn-primary">
              <i class="fa fa-windows" *ngIf="product.contractId != 'subscriptiontrial'"></i>
              {{ "download.download" | translate }}
            </button>
          </ng-container>
        </span>
        <div>
          <span [class]="dl.beta ? 'badge badge-success' : 'badge badge-secondary'">{{ dl.beta ? "BETA v" : "v" }}{{ dl.version }}</span>
          <br />
          <span class="text-muted d-block t-12">{{ dl.releaseDate | date }}</span>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="(myOs == 'win' || showAllSystems) && downloads && downloads.installers && downloads.installers.win64">
      <div class="m-b-10" [class.installer-btn-pill]="!isSideBar" *ngFor="let dl of downloads.installers.win64">
        <span (click)="downloadTokenizedAsset(dl)">
          <ng-container *ngIf="dl.stringId != 'capture.public.win' && dl.stringId != 'uc.public.win'">
            <h6 class="brandfont-bold m-b-10" [class.m-t-20]="isSideBar">Installer for Windows</h6>
            <button class="btn btn-primary">
              <i class="fa fa-windows" *ngIf="product.contractId != 'subscriptiontrial'"></i>
              {{ (product.contractId == "subscriptiontrial" ? "product_detail.start_trial" : "download.download") | translate }}
            </button>
          </ng-container>
          <ng-container *ngIf="dl.stringId == 'capture.public.win' || dl.stringId == 'uc.public.win'">
            <h6 class="brandfont-bold m-b-10" [class.m-t-20]="isSideBar">{{ dl.stringId == "capture.public.win" ? "Capture" : "Universal Control" }} for Windows (64 bit)</h6>
            <button class="btn btn-primary">
              <i class="fa fa-windows" *ngIf="product.contractId != 'subscriptiontrial'"></i>
              {{ "download.download" | translate }}
            </button>
          </ng-container>
        </span>
        <div>
          <span [class]="dl.beta ? 'badge badge-success' : 'badge badge-secondary'">{{ dl.beta ? "BETA v" : "v" }}{{ dl.version }}</span>
          <br />
          <span class="text-muted d-block t-12">{{ dl.releaseDate | date }}</span>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="(myOs == 'linux' || showAllSystems) && downloads && downloads.installers && downloads.installers.linux">
      <div class="m-b-10" [class.installer-btn-pill]="!isSideBar" *ngFor="let dl of downloads.installers.linux">
        <span (click)="downloadTokenizedAsset(dl)">
          <h6 class="brandfont-bold m-b-10" [class.m-t-20]="isSideBar">Installer for Linux (deb)</h6>
          <button class="btn btn-primary">
            <i class="fa fa-linux" *ngIf="product.contractId != 'subscriptiontrial'"></i>
            {{ (product.contractId == "subscriptiontrial" ? "product_detail.start_trial" : "download.download") | translate }}
          </button>
        </span>
        <div>
          <span [class]="dl.beta ? 'badge badge-success' : 'badge badge-secondary'">{{ dl.beta ? "BETA v" : "v" }}{{ dl.version }}</span>
          <br />
          <span class="text-muted d-block t-12">{{ dl.releaseDate | date }}</span>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="(myOs == 'linux' || showAllSystems) && downloads && downloads.installers && downloads.installers.flatpak">
      <div class="m-b-10" [class.installer-btn-pill]="!isSideBar" *ngFor="let dl of downloads.installers.flatpak">
        <span (click)="downloadTokenizedAsset(dl)">
          <h6 class="brandfont-bold m-b-10" [class.m-t-20]="isSideBar">Installer for Linux (flatpak)</h6>
          <button class="btn btn-primary">
            <i class="fa fa-linux" *ngIf="product.contractId != 'subscriptiontrial'"></i>
            {{ (product.contractId == "subscriptiontrial" ? "product_detail.start_trial" : "download.download") | translate }}
          </button>
        </span>
        <div>
          <span [class]="dl.beta ? 'badge badge-success' : 'badge badge-secondary'">{{ dl.beta ? "BETA v" : "v" }}{{ dl.version }}</span>
          <br />
          <span class="text-muted d-block t-12">{{ dl.releaseDate | date }}</span>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="downloads && downloads.installers && downloads.installers.all">
      <div class="m-b-10" [class.installer-btn-pill]="!isSideBar" *ngFor="let dl of downloads.installers.all">
        <span (click)="downloadTokenizedAsset(dl)">
          <h6 class="brandfont-bold m-b-10" [class.m-t-20]="isSideBar">Installer</h6>
          <button class="btn btn-primary">
            <fa-icon icon="download" *ngIf="product.contractId != 'subscriptiontrial'"></fa-icon>
            {{ (product.contractId == "subscriptiontrial" ? "product_detail.start_trial" : "download.download") | translate }}
          </button>
        </span>
        <small class="text-muted d-block m-t-10">{{ dl.title }}</small>
        <div>
          <span [class]="dl.beta ? 'badge badge-success' : 'badge badge-secondary'">{{ dl.beta ? "BETA v" : "v" }}{{ dl.version }}</span>
          <br />
          <span class="text-muted d-block t-12">{{ dl.releaseDate | date }}</span>
        </div>
      </div>
    </ng-container>
  </div>

  <div *ngIf="product.partnerName && product.partnerName == 'pace'">
    {{ "products.requires_ilok" | translate }} -
    <span class="btn btn-sm btn-primary" (click)="showiLokOverlay()">{{ "general.learn_more" | translate }}</span>
  </div>

  <div class="installer_btn_holder" *ngIf="product.partnerDownloadUrl && product.partnerName != 'pace' && product.stringId != 'eastwest.composercloud'">
    <a (click)="ga.trackOutboundLink(product.partnerDownloadUrl)">
      <button class="btn btn-primary" *ngIf="product.contractId == 'subscriptiontrial'">
        {{ "product_detail.start_trial" | translate }}
      </button>
      <button class="btn btn-primary" *ngIf="product.contractId != 'subscriptiontrial' && !product.partnerDownloadUrl">
        <fa-icon icon="download"></fa-icon>
        {{ "product_detail.download_installer" | translate }}
      </button>
      <button class="btn btn-primary" *ngIf="product.partnerDownloadUrl">
        {{ "general.redeem" | translate }}
      </button>
    </a>
  </div>
  <div class="text-muted m-b-30" *ngIf="hasNoInstallers && betaOnly">No beta installers are available for this product.</div>
</div>

<div class="m-b-10" *ngIf="firmwareList?.length || (betaOnly && product.categoryId == 'hardware')">
  <h5 class="brandfont-bold" *ngIf="betaOnly">Firmware</h5>
  <div class="installer_btn_holder" *ngIf="firmwareList?.length">
    <a (click)="showFirmwareInstructions(firmwareList[0])">
      <button class="btn btn-primary">
        <fa-icon icon="download"></fa-icon>
        {{ "product_detail.get_latest_firmware" | translate }}
      </button>
    </a>
    <br />
    <span [class]="firmwareList[0].beta ? 'badge badge-success' : 'badge badge-secondary'">{{ (firmwareList[0].beta ? "BETA " : "") + "Version " + firmwareList[0].version }}</span>
  </div>
  <div class="text-muted m-b-30" *ngIf="!firmwareList?.length && betaOnly">No beta firmware is available for this product.</div>
</div>

<div class="installer_btn_actions m-t-20" *ngIf="!betaOnly || !hasNoInstallers">
  <button
    [class]="isSideBar ? 'sidebar-btn' : 'brand-btn-reverse sm m-r-10'"
    *ngIf="downloads && downloads.installers && downloads.installers.mac && (downloads.installers.win || downloads.installers.win64)"
    (click)="showAllSystems = !showAllSystems"
  >
    <span>{{ (showAllSystems ? "product_detail.show_my_system" : "product_detail.show_all_systems") | translate }}</span>
    &nbsp;
    <fa-icon icon="chevron-right"></fa-icon>
  </button>
  <button [class]="isSideBar ? 'sidebar-btn' : 'brand-btn-reverse sm m-r-10'" *ngIf="previousVersions && previousVersions.length" (click)="showPreviousVersions()">
    <span>{{ "product_detail.view_previous_versions" | translate }}</span>
    &nbsp;
    <fa-icon icon="chevron-right"></fa-icon>
  </button>
  <button
    [class]="isSideBar ? 'sidebar-btn' : 'brand-btn-reverse sm m-r-10'"
    *ngIf="downloads && downloads.release_notes"
    (keyup.enter)="downloadTokenizedAsset(downloads.release_notes[0])"
    (click)="downloadTokenizedAsset(downloads.release_notes[0])"
  >
    <span>{{ "product_detail.download_release_notes" | translate }}</span>
    &nbsp;
    <fa-icon icon="chevron-right"></fa-icon>
  </button>
</div>
