<div class="d-flex d-md-flex justify-content-between align-items-center m-b-10">
  <h4 class="text-truncate content-title">Exchange Featured Creators</h4>
  <button class="btn btn-secondary" (click)="addEditFeaturedCreator()">
    <fa-icon icon="plus"></fa-icon>
    Add
  </button>
</div>
<div class="row">
  <div class="col-md-12">
    <myp-list-item
      *ngFor="let item of featuredCreators"
      [photo]="item.image_url ? item.image_url : './assets/images/default_avatar.jpg'"
      [title]="item.name"
      [item]="item"
      [includeChevron]="false"
      [subData]="['Description: ' + item.blurb]"
    >
      <fa-icon icon="edit" class="action circle-icon-btn m-r-5" tabindex="0" (keyup.enter)="addEditFeaturedCreator(item)" (click)="addEditFeaturedCreator(item)"></fa-icon>
      <fa-icon icon="times" class="action circle-icon-btn" tabindex="0" (keyup.enter)="deleteFeaturedCreator(item, i)" (click)="deleteFeaturedCreator(item, i)"></fa-icon>
    </myp-list-item>
  </div>
</div>
