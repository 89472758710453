import { Component, Input, Output, OnInit, EventEmitter } from "@angular/core";

import { BaseComponent, UserService, StorageService, StorageKeys, ProductService } from "@mypxplat/xplat/core";
import { AppService } from "@mypxplat/xplat/web/core";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { filter, takeUntil } from "rxjs/operators";
import * as moment from "moment";
import { SphereSubscribeStripeComponent } from "../modals";

@Component({
  selector: "myp-shared-products-list",
  templateUrl: "products-list.component.html",
})
export class ProductsListComponent extends BaseComponent implements OnInit {
  @Input() displayedSection: "software" | "hardware" | "add-ons" | "all" = "software";

  private _selectedCategory: any;
  @Input() set selectedCategory(category) {
    this._selectedCategory = category;
    this.selectCategory(this.selectedCategory);
  }
  get selectedCategory() {
    return this._selectedCategory;
  }
  @Output() listLoaded: EventEmitter<boolean> = new EventEmitter();
  @Output() registerClicked: EventEmitter<boolean> = new EventEmitter();
  public loading: boolean = false;
  public refreshing: boolean = false;
  public noProducts: boolean;

  public products: any;

  public allProducts: Array<any> = [];
  public displayedProducts: Array<any> = [];
  public uncategorizedProducts: Array<any> = [];

  constructor(
    public storageService: StorageService,
    public userService: UserService,
    public productService: ProductService,
    public appService: AppService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    public translate: TranslateService
  ) {
    super(userService);
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.loading = true;
    this.displayedSection = this._activatedRoute.snapshot.params["category"];
    this.productService.products$
      .pipe(
        filter((products) => !!products),
        takeUntil(this.destroy$)
      )
      .subscribe((result: any) => {
        this.loading = false;
        this.listLoaded.next(true);
        this.setupProducts(result);
        this.filterProducts("");
      });
  }

  setupProducts(result) {
    if (result && result.products.length) {
      this.noProducts = false;
    } else {
      this.noProducts = true;
    }

    this.allProducts = [];
    this.uncategorizedProducts = [];
    this.loading = false;
    this.products = result.products;
    this.products.forEach((prod) => {
      this.allProducts.push(prod);
      if (!prod.categories || !prod.categories.length) this.uncategorizedProducts.push(prod);
    });

    this.selectCategory(this.selectedCategory);
    this.sortProducts();
  }

  selectCategory(category_id) {
    if (this.allProducts && this.allProducts.length) {
      if (category_id == "all") {
        this.displayedProducts = [...this.allProducts];
      } else if (category_id == "other") {
        this.displayedProducts = [...this.uncategorizedProducts];
      } else {
        this.displayedProducts = [];
        this.allProducts.forEach((item) => {
          if (item.categories && item.categories.length) {
            let matching = item.categories.filter((item) => item.id_producttypes == this.selectedCategory);
            if (matching && matching.length) {
              this.displayedProducts.push(item);
            }
          }
        });
      }
    }
  }

  public sortProducts() {
    const sorter = (a, b) => {
      if (this.appService.sortBy == "date") {
        let dateA = a.releaseDate || a.aquired;
        let dateB = b.releaseDate || b.aquired;
        if (!dateA && !dateB) {
          return 0; // Both are missing, treat as equal
        } else if (!dateA) {
          return this.appService.sortDirection === "asc" ? -1 : 1; // Missing dateA goes to top if 'asc', to bottom if 'desc'
        } else if (!dateB) {
          return this.appService.sortDirection === "asc" ? 1 : -1; // Missing dateB goes to top if 'asc', to bottom if 'desc'
        }
        if (moment(dateA) < moment(dateB)) {
          return this.appService.sortDirection == "asc" ? -1 : 1;
        } else if (moment(dateA) > moment(dateB)) {
          return this.appService.sortDirection == "asc" ? 1 : -1;
        }
      } else {
        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return this.appService.sortDirection == "asc" ? -1 : 1;
        } else if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return this.appService.sortDirection == "asc" ? 1 : -1;
        }
      }
    };

    this.displayedProducts.sort(sorter);
  }

  filterProducts(args) {
    const processFn = (item) => {
      let filterBy = item.title.toLowerCase();
      if (!args) {
        item.filtered = false;
      } else if (filterBy.indexOf(args.toLowerCase()) == -1) {
        item.filtered = true;
      } else {
        item.filtered = false;
      }
    };
    this.products.forEach(processFn);
  }

  tileClick(args, prod) {
    let className = args.srcElement.className.baseVal ? args.srcElement.className.baseVal : args.srcElement.className;
    if (!args.srcElement.classList.contains("tile-action-btn")) {
      this._router.navigate(["products/detail/", prod.id]);
    }
  }

  go(prod) {
    this._router.navigate(["products/detail/", prod.id]);
  }

  upgrade() {
    this._router.navigate(["/onboarding"], { queryParams: { subscribeOnly: true } });
  }

  refresh() {
    this.refreshing = true;
    this.storageService.removeItem(StorageKeys.PRODUCTDETAILSMAP);
    this.storageService.removeItem(StorageKeys.PRODUCTS);
    this.productService.getProducts(true).subscribe((result) => {
      this.refreshing = false;
    });
  }
}
