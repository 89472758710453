<div class="row h-100" *ngIf="!loading && !allProducts.length">
  <div class="col h-100 text-center">
    <div class="h-100 d-flex justify-content-center align-items-center">
      <div style="max-width: 800px">
        <div>
          <img src="./assets/images/products-bundle.png" style="max-width: 300px; width: 100%" alt="" />
          <br />
          <br />
          <h1 class="brandfont-black">{{ "header.nav.products" | translate }}</h1>
        </div>
        <p class="m-t-20">
          {{ "newdash.prod_page_slug" | translate }}
          <br />
          <br />
        </p>
        <button class="btn btn-primary" (click)="registerClicked.next(true)">{{ "products.register.register_product" | translate }}</button>
      </div>
    </div>
  </div>
</div>

<div class="content-constrained">
  <div class="col m-t-10" *ngIf="!loading && allProducts.length && allProducts.length > 1">
    <myp-product-filter (filterChanged)="filterProducts($event)" (sortChanged)="sortProducts()"></myp-product-filter>
  </div>

  <myp-inline-loader [loading]="loading" height="400px" spinnerName="productsLoader"></myp-inline-loader>

  <div class="product-list m-t-10 m-x-10" *ngIf="productService.simpleProductList">
    <div *ngIf="appService.prefersGrid" class="grid-container pad-10">
      <ng-container *ngFor="let prod of displayedProducts">
        <myp-product-tile *ngIf="!prod.filtered" (tileClicked)="go(prod)" [product]="prod"></myp-product-tile>
      </ng-container>
      <!-- <ng-container *ngFor="let prod of productService.simpleProductList?.software">
        <myp-product-tile *ngIf="!prod.filtered" (tileClicked)="go(prod)" [product]="prod"></myp-product-tile>
      </ng-container> -->
    </div>
    <div *ngIf="!appService.prefersGrid">
      <ng-container *ngFor="let prod of displayedProducts">
        <myp-product-item *ngIf="!prod.filtered" [product]="prod"></myp-product-item>
      </ng-container>
    </div>
  </div>

  <div *ngIf="allProducts.length" style="height: 20px"></div>
</div>
