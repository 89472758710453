import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import * as moment from "moment";
import { BehaviorSubject, of as observableOf, of, Subject, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HelperService, StorageKeys, StorageService, UserService, WindowService } from ".";
import { environment, MessageEmbed } from "..";
@Injectable({
  providedIn: "root",
})
export class CommunityService {
  sanitizer = inject(DomSanitizer);
  public postDataUpdated$: Subject<any> = new Subject();

  public isMobile: boolean;
  public lastVisiblePost: any;
  public lastVisiblePostsByKey: any = {};

  public connectionMap: any = {};
  public connectionRequests: Array<any>;
  public availableSkills: Array<any>;
  public taggableChannels: Array<any>;
  public taggableChannelsMap: any = {};
  public skillsMap: any = {};
  public topicMap: any = {};
  public topics: Array<any>;
  public extraChannelsMap: any = {};
  public extraChannels: Array<any> = [
    {
      id: "all_posts",
      string_id: "all_posts",
      title: "All Posts",
      active: true,
      public: true,
      beta: true,
    },
    {
      id: "my_posts",
      string_id: "my_posts",
      title: "My Posts",
      active: true,
      public: false,
      beta: true,
    },
    {
      id: "general",
      string_id: "general",
      title: "General",
      active: true,
      public: true,
    },
    {
      id: "pinned_posts",
      string_id: "pinned_posts",
      title: "My Pinned Posts",
      active: true,
      public: true,
    },
    {
      id: "learning_studio_one",
      string_id: "learning_studio_one",
      title: "Learning Studio One",
      active: true,
      taggable: true,
      alphabetical: true,
      public: true,
    },
    {
      id: "collaboration",
      string_id: "collaboration",
      title: "Collaboration",
      active: true,
      taggable: true,
      alphabetical: true,
      public: true,
    },
    // {
    //   id: "experts",
    //   string_id: "experts",
    //   title: "Studio One Experts",
    //   active: true,
    //   taggable: false,
    //   alphabetical: false,
    //   public: false,
    //   role: ["is_s1_expert"],
    // },
  ];

  public userHasSkills: boolean = false;

  public communityWizards: any = {
    804975: true,
    4148021: true,
    2640561: true,
    54059: true,
  };

  public currentlyPlayingWaveformComponent: any;
  public displayAudioWaveforms: boolean = false;
  public cachedSearchResults: Array<any>;
  public cachedSearchParentsMap: any = {};
  public cachedSearchPage: number;
  public cachedFirebaseProfiles: any = {};
  public cachedProfiles: any = {};
  public cachedNames: any = {};
  public cachedPhotos: any = {};
  public playingAudioFile: any;

  public activeParentPosts: Array<any>;
  public activeParentThreadMap: any = {};
  public activeParentThreadMapTruncated: any = {};

  public activeParentPostTypes: any = {};

  public activePostsLimitReached: boolean = false;
  public activePostDetailSubscription: any;
  public activePostsSubscription: any;
  public displayedPostsMap: any = {};
  public processedReplies: any = {};
  public parentThreadKeys: any;

  public selectedThread: any;

  public cachedModeratingPosts: any;

  public showBetaType: string;
  public showBetaStatus: string;
  public showBetaReviewStatus: any;
  public showBetaAuthor: any;

  private _hasSetupCommunity: boolean;
  set hasSetupCommunity(value) {
    if (value) {
      this.storage.setItem(StorageKeys.HAS_SETUP_COMMUNITY, value);
    } else {
      this.storage.removeItem(StorageKeys.HAS_SETUP_COMMUNITY);
    }
    this._hasSetupCommunity = value;
  }

  get hasSetupCommunity() {
    return this._hasSetupCommunity;
  }

  public profileUpdated$: Subject<any> = new Subject();
  public notificationReceived$: Subject<any> = new Subject();
  public firebaseProfile$: BehaviorSubject<any> = new BehaviorSubject(null);
  private _profile: any;
  set profile(value) {
    if (value) {
      this.profileUpdated$.next(value);
      this.storage.setItem(StorageKeys.COMMUNITY_PROFILE, value);
    } else {
      this.storage.removeItem(StorageKeys.COMMUNITY_PROFILE);
    }
    this._profile = value;
  }

  get profile() {
    if (!this._profile) {
      this.profile = this.storage.getItem(StorageKeys.COMMUNITY_PROFILE);
    }
    return this._profile;
  }

  private _connections: any;
  get connections() {
    if (!this._connections) this._connections = this.storage.getItem(StorageKeys.CONNECTIONS);
    return this._connections;
  }
  set connections(data) {
    if (data) {
      this._connections = data;
      this.storage.setItem(StorageKeys.CONNECTIONS, data);
    } else {
      this._connections = false;
      this.storage.removeItem(StorageKeys.CONNECTIONS);
    }
  }

  private urlRegex: RegExp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private _win: WindowService,
    public helperService: HelperService,
    public userService: UserService
  ) {
    this.extraChannels.forEach((item) => {
      this.extraChannelsMap[item.id] = item;
    });
  }

  updateConnections(connections) {
    this.connectionRequests = undefined;
    connections.forEach((item) => {
      let theirID = item.initiated_by == this.userService.user.id ? item.requested_user_id : item.initiated_by;
      item.theirID = theirID;
      this.connectionMap[theirID] = item;
      if (item.requested_user_id == this.userService.user.id && item.status == "pending") {
        if (!this.connectionRequests) this.connectionRequests = [];
        this.connectionRequests.push(item);
      }
    });
    this.connections = connections;
  }

  getConnections(forceRefresh?) {
    const savedData = this.connections;
    if (savedData && !forceRefresh) {
      this.updateConnections(savedData);
      return observableOf(savedData);
    } else {
      return this.http.get(environment.paeApiUrl + "user/connections/", this.helperService.getHttpOptions()).pipe(
        map((result: any) => {
          this.updateConnections(result);
          return result;
        })
      );
    }
  }

  addConnection(user_id, message = "") {
    return this.http.post(environment.apiUrl + "community/create_connection/", JSON.stringify({ user_id, message }), this.helperService.getHttpOptions()).pipe(
      map((result: any) => {
        this.updateConnections(result.connections);
        let me = this.userService.user;
        me.connections = this.connections;
        this.userService.user = me;
        return result.connections;
      })
    );
  }

  removeConnection(connectionId, userId?) {
    return this.http.post(environment.apiUrl + "community/remove_connection/", JSON.stringify({ id: connectionId }), this.helperService.getHttpOptions()).pipe(
      map((result: any) => {
        this.updateConnections(result.connections);
        let me = this.userService.user;
        me.connections = this.connections;
        this.userService.user = me;
        return result.connections;
      })
    );
  }

  updateConnection(connection) {
    return this.http.post(environment.apiUrl + "community/update_connection/", JSON.stringify({ connection }), this.helperService.getHttpOptions()).pipe(
      map((result: any) => {
        this.updateConnections(result.connections);
        let me = this.userService.user;
        me.connections = this.connections;
        this.userService.user = me;
        return result.connections;
      })
    );
  }

  sendConnectionInviteEmail(user_id) {
    return this.http.post(environment.apiUrl + "community/send_connection_request_email/", JSON.stringify({ user_id: user_id }), this.helperService.getHttpOptions()).pipe();
  }

  sendNewThreadMessageNotification(args, autoSubscribe = true) {
    let fn = this.http.post(environment.apiUrl + "community/notify_thread_message/", JSON.stringify(args), this.helperService.getHttpOptions()).pipe();
    if (autoSubscribe) {
      fn.subscribe();
    } else {
      return fn;
    }
  }

  urlify(text) {
    if (text) {
      var urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
      return text.replace(urlRegex, function (url) {
        return '<a href="' + url + '" target="_blank">' + url + "</a>";
      });
    } else {
      return text;
    }
  }

  getProfileDetails(user_id) {
    if (this.cachedProfiles[user_id]) {
      return of(this.cachedProfiles[user_id]);
    } else {
      return this.http.get(environment.apiUrl + "exchange/creator/" + user_id, this.helperService.getHttpOptions(true, undefined, undefined, this.userService.user.active_subscription)).pipe(
        map((data: any) => {
          this.cachedProfiles[user_id] = data;
          return data;
        })
      );
    }
  }

  getUserSkills(userId?, fresh?) {
    if (!userId) {
      userId = this.userService.user.id;
    } else {
      fresh = true;
    }

    let userSkills = this.storage.getItem(StorageKeys.USER_SKILLS);
    if (userSkills && userSkills.length && !fresh && this.storage.checkTimestamp("user_skills")) {
      this.userHasSkills = true;
      userSkills.forEach((item) => {
        if (item.notes) {
          item.htmlNotes = this.urlify(item.notes);
        }
      });
      return observableOf(userSkills);
    } else {
      return this.http.get(environment.apiUrl + "community/user_skills/" + userId, this.helperService.getHttpOptions()).pipe(
        map((data: any) => {
          if (data.skills && data.skills.length) {
            this.userHasSkills = true;
            this.storage.setTimestamp("user_skills", 24);
            this.storage.setItem(StorageKeys.USER_SKILLS, data.skills);
            data.skills.forEach((item) => {
              if (item.notes) {
                item.htmlNotes = this.urlify(item.notes);
              }
            });
          }
          return data.skills;
        })
      );
    }
  }

  addUserSkills(args) {
    //to do: need to sync the query map in the web communtiy service.
    return this.http.post(environment.apiUrl + "community/update_user_skills/", JSON.stringify(args), this.helperService.getHttpOptions()).pipe();
  }

  getAvailableSkills(fresh?) {
    let availableSkills = this.storage.getItem(StorageKeys.AVAILABLE_SKILLS);

    if (!fresh && availableSkills && availableSkills.length) {
      this.availableSkills = availableSkills;
      this.availableSkills.sort((a, b) => (a.title > b.title ? 1 : -1));
      availableSkills.forEach((item) => (this.skillsMap[item.string_id] = item));
      this.taggableChannels = [...this.availableSkills, ...this.extraChannels.filter((item) => item.taggable)];
      // make sure availableSkills and taggableChannels has only unqiue values, as we may have accidentally saved the extraChannels to local storage.
      if (availableSkills && availableSkills.length) {
        const uniqueSkills = Array.from(new Set(availableSkills.map((item) => item.id))).map((id) => availableSkills.find((item) => item.id === id));
        availableSkills = uniqueSkills;
      }
      if (this.taggableChannels && this.taggableChannels.length) {
        const uniqueTaggableChannels = Array.from(new Set(this.taggableChannels.map((item) => item.id))).map((id) => this.taggableChannels.find((item) => item.id === id));
        this.taggableChannels = uniqueTaggableChannels;
      }
      this.taggableChannels.sort((a, b) => (a.title > b.title ? 1 : -1));
      this.taggableChannels.forEach((item) => (this.taggableChannelsMap[item.string_id] = item));
      return observableOf(availableSkills);
    } else {
      return this.http.get(environment.apiUrl + "community/skills/", this.helperService.getHttpOptions()).pipe(
        map((data: any) => {
          this.availableSkills = data.skills;
          this.availableSkills.sort((a, b) => {
            return a.title > b.title ? 1 : -1;
          });
          this.taggableChannels = [...this.availableSkills, ...this.extraChannels.filter((item) => item.taggable)];
          this.taggableChannels.sort((a, b) => (a.title > b.title ? 1 : -1));
          this.taggableChannels.forEach((item) => (this.taggableChannelsMap[item.string_id] = item));
          data.skills.forEach((item) => (this.skillsMap[item.string_id] = item));
          this.storage.setItem(StorageKeys.AVAILABLE_SKILLS, this.availableSkills);
          return data.skills;
        })
      );
    }
  }

  adminAddSkill(args) {
    return this.http.post(environment.apiUrl + "community/create_skill/", JSON.stringify(args), this.helperService.getHttpOptions()).pipe(
      map((result: any) => {
        return result;
      })
    );
  }

  adminDeleteSkill(id) {
    return this.http.post(environment.apiUrl + "community/delete_skill/", JSON.stringify({ id: id }), this.helperService.getHttpOptions()).pipe();
  }

  updateShowcaseSkill(args) {
    return this.http.post(environment.apiUrl + "community/update_showcase_skill/", JSON.stringify(args), this.helperService.getHttpOptions()).pipe();
  }

  getShowcases(userId?) {
    return this.http.get(environment.apiUrl + "community/showcases/" + (userId ? userId : "false/true"), this.helperService.getHttpOptions()).pipe(
      map((data: any) => {
        if (data.showcases && data.showcases.length) {
          data.showcases.forEach((item) => {
            item.url = this.buildFileUrl(item.user_id, item);
          });
        }
        return data.showcases;
      })
    );
  }

  notifyPostEngagement(args) {
    return this.http.post(environment.apiUrl + "community/notify_post_engagement", JSON.stringify(args), this.helperService.getHttpOptions()).pipe(
      map((result) => {
        return result;
      })
    );
  }

  notifyPostMentions(args) {
    return this.http.post(environment.apiUrl + "community/notify_post_mentions", JSON.stringify(args), this.helperService.getHttpOptions()).pipe(
      map((result) => {
        return result;
      })
    );
  }

  updateNotificationPreferences(args) {
    return this.http.post(environment.apiUrl + "community/update_notification_preferences", JSON.stringify(args), this.helperService.getHttpOptions()).pipe();
  }

  buildFileUrl(user_id, file) {
    let url;
    if (file.url) url = file.url;
    if (file.type == "post_attachment") {
      url = (file.storage_location == "aws" ? environment.awsContentUrl : environment.wasabiContentUrl) + user_id + "/post-attachments/" + encodeURI(file.storage_id ? file.storage_id : file.filename);
    } else if (file.type == "thread_attachment") {
      url =
        (file.storage_location == "aws" ? environment.awsContentUrl : environment.wasabiContentUrl) +
        user_id +
        "/thread-attachments/" +
        file.thread_id +
        "/" +
        (file.storage_id ? file.storage_id : file.filename);
    } else if (file.type == "showcase") {
      url = (file.storage_location == "aws" ? environment.awsContentUrl : environment.wasabiContentUrl) + user_id + "/showcases/" + encodeURI(file.storage_id ? file.storage_id : file.filename);
    } else if (file.workspace_id) {
      url =
        (file.storage_location == "aws" ? environment.awsContentUrl : environment.wasabiContentUrl) +
        user_id +
        "/workspace-uploads/" +
        file.workspace_id +
        "/" +
        (file.storage_id ? file.storage_id : file.filename);
    }
    return url;
  }

  clearCache() {
    this.connections = [];
    this.profile = undefined;
  }

  addPost(data, collectionReference) {
    // parentID must exist for firebase to index it (for finding parent posts)
    if (!data.parentID && data.parentID !== null) data.parentID = null;
    for (var i in data) {
      if (data[i] === undefined) delete data[i];
    }
    delete data.iLike;
    return collectionReference.add(data).then((result) => {
      return result;
    });
  }

  findMembersBySkills(skills, limit = 2000, collectionRef) {
    return collectionRef
      .where("skillsQueryMap", "array-contains-any", skills)
      .where("id", "!=", null)
      .where("public", "==", true)
      .limit(limit)
      .get()
      .then((result: any) => {
        let profiles = [];
        result.docs.forEach((item) => {
          let data = item.data();
          let skillsString = "";
          if (data.skillsQueryMap && data.skillsQueryMap.length) {
            data.skillsQueryMap.forEach((string_id, index) => {
              skillsString += this.skillsMap[string_id]?.title + (index == data.skillsQueryMap.length - 1 ? "" : ", ");
            });
          }
          profiles.push({
            ...data,
            skills_string: skillsString,
          });
        });
        return profiles;
      });
  }

  findMembersByName(skills, collectionRef) {
    return collectionRef
      .where("name", "array-contains-any", skills)
      .where("public", "==", true)
      .get()
      .then((result: any) => {
        let profiles = [];
        result.docs.forEach((item) => {
          profiles.push(item.data());
        });
        return profiles;
      });
  }

  findModeratedUsers(collectionRef) {
    return collectionRef
      .where("status", "in", ["probation", "banned"])
      .get()
      .then((result: any) => {
        let profiles = [];
        result.docs.forEach((item) => {
          profiles.push(item.data());
        });
        return profiles;
      });
  }

  findMembers(collectionRef, skills?, nameQuery?, emailQuery?, limit = 5, includePsuedoExperts?) {
    let allResults = [];
    let queries = [];
    let map = {};
    if (skills && skills.length) {
      queries.push(
        collectionRef
          .where("skillsQueryMap", "array-contains-any", skills)
          .where("public", "==", true)
          .limit(limit)
          .get()
          .then((result) => {
            result.docs.forEach((item: any) => {
              if (!map[item.data().id]) {
                allResults.push(item.data());
                map[item.data().id] = true;
              }
            });
          })
      );
    }
    if (nameQuery) {
      queries.push(
        collectionRef
          .where("name_lowercase", ">=", nameQuery.toLowerCase())
          .where("name_lowercase", "<=", nameQuery.toLowerCase() + "~")
          .where("public", "==", true)
          .limit(limit)
          .get()
          .then((result) => {
            result.docs.forEach((item: any) => {
              if (!map[item.data().id]) {
                allResults.push(item.data());
                map[item.data().id] = true;
              }
            });
          })
      );
    }
    if (emailQuery) {
      collectionRef
        .where("email", ">=", emailQuery.toLowerCase())
        .where("email", "<=", emailQuery.toLowerCase() + "~")
        .where("public", "==", true)
        .limit(limit)
        .get()
        .then((result) => {
          result.docs.forEach((item: any) => {
            if (!map[item.data().id]) {
              allResults.push(item.data());
              map[item.data().id] = true;
            }
          });
        });
    }

    return Promise.all(queries).then((result) => {
      if (includePsuedoExperts && "studio one experts".indexOf(nameQuery.toLowerCase()) >= 0) {
        allResults.unshift({
          description: null,
          email: null,
          id: "studio_one_experts",
          firstName: "Studio One",
          firstName_lowercase: "studio one",
          lastName: "Experts",
          lastName_lowercase: "experts",
          name: "Studio One Experts",
          name_lowercase: "studio one experts",
          photo: "./assets/images/s16-6.png",
          public: true,
        });
      }
      return allResults;
    });
  }

  public unreadNotificationCount: number = 0;
  public notificationCount: number = 0;
  getUnreadNotificationCount(collectionRef) {
    return collectionRef
      .get()
      .then((result) => {
        this.notificationCount = result.size;
        this.unreadNotificationCount = 0;
        result.docs.forEach((doc) => {
          let data = doc.data();
          if (!data.seen) this.unreadNotificationCount++;
        });
      })
      .catch(() => {});
  }

  getNotifications(collectionRef) {
    return collectionRef
      .get()
      .then((result) => {
        if (result.empty) {
          return [];
        } else {
          let docs = [];
          result.docs.forEach((doc) => {
            let data: any = doc.data();
            data.id = doc.id;
            docs.push(data);
          });
          docs.sort((a, b) => {
            if (a.created && b.created) {
              return a.created.toDate() > b.created.toDate() ? -1 : 1;
            } else {
              return 1;
            }
          });
          return docs;
        }
      })
      .catch(() => {});
  }

  deleteNotification(documentRef) {
    return documentRef.delete();
  }

  batchDeleteNotifications(collectionRef, ids: Array<string>) {}

  updateNotification(documentRef, data) {
    return documentRef.update(data);
  }

  updatePost(documentReference, args) {
    return documentReference.update(args);
  }

  deletePost(id, collectionRef, admin?) {
    let args: any = {
      deleted: {
        timestamp: new Date(),
        deleted_by: this.userService.user.id,
      },
    };
    if (admin) args.deleted.admin = true;
    return collectionRef.doc(id).update(args);
  }

  createConnectedUserMessageThread(collectionRef, user_id) {
    return new Promise(async (resolve, reject) => {
      let threadExists = false;
      // Firebase only allows one array-contains clause per query, so we search
      // all the current user's threads for one containing the provided user_id.
      const threads = await this.getConnectedUserMessageThreads(collectionRef);
      threads.forEach(({ users }) => {
        if (users.includes(this.userService.user.id) && users.includes(user_id)) {
          threadExists = true;
        }
      });

      if (threadExists) {
        reject("This thread already exists.");
      } else {
        collectionRef
          .add({
            users: [this.userService.user.id, user_id],
            created: new Date(),
          })
          .then(() => {
            resolve(true);
          });
      }
    });
  }

  getConnectedUserMessageThreads(collectionRef) {
    return collectionRef
      .where("users", "array-contains-any", [this.userService.user.id])
      .get()
      .then((result) => {
        const threads = [];
        if (!result.empty) {
          result.docs.forEach((thread) => {
            const data = thread.data();
            data.them = data.users.filter((id) => id != this.userService.user.id)[0];
            if (threads.findIndex((i) => i.them == data.them) >= 0) {
              // There should not be multiple threads with the same user.
              return;
            }
            data.id = thread.id;
            data.connection = this.connectionMap[data.them];
            threads.push(data);
          });
        }
        return threads;
      });
  }

  getPostByID(id, collectionRef) {
    return new Promise((resolve, reject) => {
      if (!this.activeParentThreadMap) this.activeParentThreadMap = {};

      collectionRef
        .doc(id)
        .get()
        .then((result) => {
          let promises = [];
          let post: any = result.data();
          if (post && !post.deleted) {
            post.id = result.id;
            post.key = result.id;
            this.buildHtmlComment(post);
            promises.push(
              collectionRef
                .where("parentID", "==", result.id)
                .get()
                .then((replies) => {
                  this.activeParentThreadMap[id] = [];
                  replies.docs.forEach((replyDoc) => {
                    let reply: any = replyDoc.data();
                    reply.key = replyDoc.id;
                    reply.id = replyDoc.id;
                    this.buildHtmlComment(reply);
                    if (this._win.location.host == "nativescript" && reply.created && reply.created.seconds) reply.created = moment.unix(reply.created.seconds).toDate();
                    this.activeParentThreadMap[id].push(reply);
                  });
                  this.activeParentThreadMap[id].sort((a, b) => {
                    return a.created > b.created ? 1 : -1;
                  });
                })
            );
            return Promise.all(promises).then((result) => {
              resolve(post);
            });
          } else {
            reject("Post not found!");
          }
        });
    });
  }

  getPostsWithReplies(
    args: {
      channel?: any;
      topic?: any;
      postsAccountID?: string;
      moderationAccountID?: string;
      pins?: boolean;
      mostPopular?: boolean;
      limit?: number;
      storageKey?: string;
      clear?: boolean;
      id?: string;
      betaStringId?: string;
    },
    collectionReference
  ) {
    if (!args.limit) args.limit = 30;
    if (args.clear) this.lastVisiblePost = undefined;
    return new Promise((resolve, reject) => {
      let posts = [];
      let method = collectionReference;
      if (args.id) {
        method = method.where("parentID", "==", null).where("author.id", "==", args.postsAccountID);
      } else if (args.postsAccountID) {
        method = method.where("parentID", "==", null).where("author.id", "==", args.postsAccountID);
      } else if (args.moderationAccountID) {
        method = method.where("author.id", "==", args.moderationAccountID);
      } else if (args.topic) {
        method = method.where("parentID", "==", null).where("topics", "array-contains", args.topic.string_id);
      } else if (args.channel && args.channel.string_id != "general" && args.channel.string_id != "all_posts" && args.channel.string_id != "pinned_posts" && args.channel.string_id != "my_posts") {
        if (args.betaStringId) {
          method = method.where("parentID", "==", null).where("channels", "array-contains", args.channel.string_id).where("beta_string_id", "==", args.betaStringId);
          if (this.showBetaType) method = method.where("beta_type", "==", this.showBetaType);
          if (this.showBetaStatus) method = method.where("beta_status", "==", this.showBetaStatus);
          if (this.showBetaReviewStatus) method = method.where("reviewed", "==", this.showBetaReviewStatus == "true");
          if (this.showBetaAuthor) method = method.where("author.id", "==", this.showBetaAuthor);
        } else {
          method = method.where("parentID", "==", null).where("channels", "array-contains", args.channel.string_id);
        }
      } else if (args.mostPopular) {
        method = method.where("parentID", "==", null).where("likeCount", ">", 4);
      } else if (args.channel && args.channel.string_id == "my_posts") {
        if (args.betaStringId) {
          method = method.where("parentID", "==", null).where("beta_string_id", "==", args.betaStringId).where("author.id", "==", this.userService.user.id);
        } else {
          method = method.where("parentID", "==", null).where("author.id", "==", this.userService.user.id);
        }
      } else if ((args.channel && args.channel.string_id == "pinned_posts") || args.pins) {
        method = method.where("parentID", "==", null).where("pins", "array-contains", this.userService.user.id);
      } else {
        if (args.betaStringId) {
          method = method.where("parentID", "==", null).where("beta_string_id", "==", args.betaStringId);
          if (this.showBetaType) method = method.where("beta_type", "==", this.showBetaType);
          if (this.showBetaStatus) method = method.where("beta_status", "==", this.showBetaStatus);
          if (this.showBetaReviewStatus) method = method.where("reviewed", "==", this.showBetaReviewStatus == "true");
          if (this.showBetaAuthor) method = method.where("author.id", "==", this.showBetaAuthor);
        } else {
          method = method.where("parentID", "==", null);
        }
      }
      let postsPromise;
      if (this.lastVisiblePost && !args.storageKey && !args.mostPopular) {
        postsPromise = method.orderBy("created", "desc").startAfter(this.lastVisiblePost).limit(args.limit).get();
      } else if (args.storageKey && !args.clear && this.lastVisiblePostsByKey[args.storageKey]) {
        postsPromise = method.orderBy("created", "desc").startAfter(this.lastVisiblePostsByKey[args.storageKey]).limit(args.limit).get();
      } else {
        if (args.mostPopular) {
          postsPromise = method.orderBy("likeCount", "desc").limit(20).get();
        } else {
          postsPromise = method.orderBy("created", "desc").limit(args.limit).get();
        }
      }

      postsPromise.then((result) => {
        let promises = [];
        this.lastVisiblePost = result.docs[result.docs.length - 1];
        if (args.storageKey) {
          this.lastVisiblePostsByKey[args.storageKey] = result.docs[result.docs.length - 1];
        }
        if (result.docs && result.docs.length) {
          this.activePostsLimitReached = result.docs.length < args.limit ? true : false;
          result.docs.forEach((doc) => {
            let post: any = doc.data();
            post.key = doc.id;
            post.id = doc.id;
            if (post.likes && post.likes.length) post.iLike = post.likes.filter((id) => id == this.userService.user.id).length;
            if (args.moderationAccountID) {
              posts.push(post); // all posts should be returned for moderation
            } else if (!this.userService.user.active_subscription && post.sphere_only) {
              // dont process this post because its sphere only and this user doesn't have an active subscription.
            } else if (post.pinned) {
              // dont process this post because its a pinned post in a beta program.
            } else if (!this.userService.isEmployee && post.employee_only) {
              // dont process this post because its employee only and this user is not an employee.
            } else if (post.deleted) {
              // dont process this post because its deleted.
            } else if (!args.channel || args.channel.string_id == "general" || args.channel.string_id == "all_posts") {
              if (args.postsAccountID) {
                if (post.topics && post.topics.length) {
                  // dont add it if its in a topic.
                } else {
                  posts.push(post);
                }
              } else if (args.channel && args.channel.string_id == "all_posts") {
                if ((post.channels && post.channels.includes("feedback")) || (!args.topic && post.topics && post.topics.length)) {
                  // includes feedback so ignore it.
                  // or was posted in a topic, it should not show in all posts.
                } else {
                  posts.push(post);
                }
              } else if (args.channel && args.channel.string_id == "general") {
                if ((post.channels && post.channels.includes("feedback")) || (!args.topic && post.topics && post.topics.length)) {
                  // includes feedback so ignore it.
                  // or was posted in a topic, it should not show in general.
                } else if (!post.channels || !post.channels.length) {
                  posts.push(post);
                }
              } else if (args.pins || args.topic) {
                posts.push(post);
              }
            } else {
              posts.push(post);
            }
          });
          if (posts.length) {
            posts.forEach((doc) => {
              if (!args.moderationAccountID) {
                promises.push(
                  collectionReference
                    .where("parentID", "==", doc.id)
                    .get()
                    .then((replies) => {
                      replies.docs.forEach((replyDoc) => {
                        let reply: any = replyDoc.data();
                        if (!reply.deleted) {
                          reply.key = replyDoc.id;
                          reply.id = replyDoc.id;
                          posts.push(reply);
                        }
                      });
                    })
                );
              }
            });
            return Promise.all(promises).then((result) => {
              this.processPostsAndReplies(posts, args.clear, args.storageKey);
              resolve(posts);
            });
          } else {
            this.processPostsAndReplies([], args.clear, args.storageKey);
            resolve([]);
          }
        } else {
          this.activePostsLimitReached = true;
          this.processPostsAndReplies([], args.clear, args.storageKey);
          resolve([]);
        }
      });
    });
  }

  addSoftReply(data, post) {
    if (!this.activeParentThreadMap[post.id]) this.activeParentThreadMap[post.id] = [];
    if (!this.activeParentThreadMapTruncated[post.id]) this.activeParentThreadMapTruncated[post.id] = [];
    this.activeParentThreadMap[post.id].push(data);
    this.activeParentThreadMapTruncated[post.id].push(data);
  }

  processPostsAndReplies(posts, clear?, storageKey?) {
    if (clear || !this.activeParentThreadMap) this.activeParentThreadMap = {};
    if (clear || !this.activeParentThreadMapTruncated) this.activeParentThreadMapTruncated = {};
    if (clear || !this.parentThreadKeys) this.parentThreadKeys = {};
    if (storageKey) {
      if (clear || !this.activeParentPostTypes[storageKey]) this.activeParentPostTypes[storageKey] = [];
    } else {
      if (clear || !this.activeParentPosts) this.activeParentPosts = [];
    }
    if (clear || !this.displayedPostsMap) this.displayedPostsMap = {};
    this.cachedNames[this.userService.user.id] = this.userService.user.firstName + " " + this.userService.user.lastName;
    this.cachedPhotos[this.userService.user.id] = this.userService.user.photoURL;
    posts.forEach((item) => {
      this.cachedNames[item.author.id] = item.author.name;
      this.cachedPhotos[item.author.id] = item.author.photo;
      if (!item.parentID) this.parentThreadKeys[item.key] = true;
      this.buildHtmlComment(item, !this.isMobile);
      if (item.file) item.file.url = this.buildFileUrl(item.file.user_id, item.file);

      if (this._win.location.host == "nativescript" && item.created && item.created.seconds) item.created = moment.unix(item.created.seconds).toDate();
    });
    posts = posts.filter((item) => {
      return !item.parentID || this.parentThreadKeys[item.parentID];
    });

    if (posts && posts.length) {
      posts.sort((a, b) => {
        return a.created > b.created ? -1 : 1;
      });
      posts.forEach((item) => {
        if ((item.timestamp || item.created) && !item.parentID) {
          if (!this.activeParentThreadMap[item.key]) this.activeParentThreadMap[item.key] = [];
          if (!this.activeParentThreadMapTruncated[item.key]) this.activeParentThreadMapTruncated[item.key] = [];
          this.displayedPostsMap[item.key] = item;
          if (!item.parentID) {
            if (storageKey) {
              this.activeParentPostTypes[storageKey].push(item);
            } else {
              this.activeParentPosts.push(item);
            }
          }
        } else {
          if (!this.activeParentThreadMap[item.parentID]) this.activeParentThreadMap[item.parentID] = [];
          if (!this.activeParentThreadMapTruncated[item.parentID]) this.activeParentThreadMapTruncated[item.parentID] = [];
          this.displayedPostsMap[item.key] = item;
          if (!this.activeParentThreadMap[item.parentID].filter((post) => post.id == item.id).length) this.activeParentThreadMap[item.parentID].push(item);
          if (this.activeParentThreadMapTruncated[item.parentID].length < 2 && !this.activeParentThreadMapTruncated[item.parentID].filter((post) => post.id == item.id).length)
            this.activeParentThreadMapTruncated[item.parentID].push(item); // this array contains only two replies.
        }
      });

      for (var i in this.activeParentThreadMapTruncated) {
        this.activeParentThreadMapTruncated[i].sort((a, b) => (a.created < b.created ? -1 : 1));
      }

      if (storageKey) {
        this.activeParentPostTypes[storageKey].sort((a, b) => {
          if (a.timestamp) {
            return a.timestamp > b.timestamp ? -1 : 1;
          } else if (a.created) {
            return a.created > b.created ? -1 : 1;
          }
        });
      } else {
        this.activeParentPosts.sort((a, b) => {
          if (a.timestamp) {
            return a.timestamp > b.timestamp ? -1 : 1;
          } else if (a.created) {
            return a.created > b.created ? -1 : 1;
          }
        });
      }
      let shownPostsCount = 0;
      for (var x in this.activeParentThreadMap) {
        shownPostsCount++;
        this.activeParentThreadMap[x].sort((a, b) => {
          return a.created > b.created ? 1 : -1;
        });
      }
    }
  }

  getUserProfile(userId, documentReference, bypassCache = false) {
    if (this.cachedFirebaseProfiles[userId] && !bypassCache) {
      return new Promise((resolve, reject) => {
        this.cachedNames[userId] = this.cachedFirebaseProfiles[userId].firstName + " " + this.cachedFirebaseProfiles[userId].lastName;
        this.cachedPhotos[userId] = this.cachedFirebaseProfiles[userId].photo;
        resolve(this.cachedFirebaseProfiles[userId]);
      });
    } else {
      return documentReference.get().then((result) => {
        if (result.data()) {
          this.cachedFirebaseProfiles[userId] = result.data();
          this.cachedNames[userId] = this.cachedFirebaseProfiles[userId].firstName + " " + this.cachedFirebaseProfiles[userId].lastName;
          this.cachedPhotos[userId] = this.cachedFirebaseProfiles[userId].photo;
        }
        return result.data();
      });
    }
  }

  buildHtmlComment(item, includeMentions = true, makeEditable = false) {
    item.noHtmlMessage = this.helperService.stripHtml(item.message, true);
    item.noHtmlMessageFlat = this.helperService.stripHtml(item.message, false, true);
    if (item.message || item.text) {
      item.htmlMessage = this.urlify(item.message || item.text);
      item.htmlMessage = item.htmlMessage.replace(/(?:\n\n\n)/g, "<br /><br />");
      item.htmlMessage = item.htmlMessage.replace(/(?:\r\r\r)/g, "<br /><br />");
      item.htmlMessage = item.htmlMessage.replace(/(?:\n\n)/g, "<br />");
      item.htmlMessage = item.htmlMessage.replace(/(?:\r\r)/g, "<br />");
      item.htmlMessage = item.htmlMessage.replace(/(?:\r\n|\r|\n)/g, "<br />");
    }
    item.htmlViewContent =
      '<span style="font-size: ' +
      (item.parentID ? "14" : "18") +
      "px; font-family: sans-serif; background-color: transparent; color: " +
      (this.helperService.mobileTheme == "dark" ? "white" : "#333") +
      ';">' +
      item.htmlMessage +
      "</span>";
    if (item.file) {
      item.file.url = this.buildFileUrl(item.file.user_id, item.file);
      item.files = [item.file];
    } else if (item.files) {
      item.files.sort((a, b) => {
        return a.extension > b.extension ? 1 : -1;
      });
    }

    if (item.mentions && includeMentions) {
      item.mentions.forEach((mention) => {
        item.htmlMessage = item.htmlMessage.replace(
          mention.selector,
          "<a " + (makeEditable ? ' contenteditable="false"' : "") + ' class="inline-mention font-weight-light" data-mentionid="' + mention.id + '">' + mention.selector + "</a>"
        );
      });
    }
    if (item.articles) {
      item.articles.forEach((articleItem) => {
        if (articleItem.selector) {
          item.htmlMessage = item.htmlMessage.replace(
            articleItem.selector,
            "<span " +
              (makeEditable ? ' contenteditable="false"' : "") +
              ' class="inline-article" data-sectionid="' +
              articleItem.article.section_id +
              '" data-articleid="' +
              articleItem.article.id +
              '">' +
              articleItem.article.name +
              "</span>"
          );
        }
      });
    }
    if (item.public_file_links) {
      item.public_file_links.forEach((linkItem) => {
        if (linkItem.selector) {
          item.htmlMessage = item.htmlMessage.replace(
            linkItem.selector,
            "<span " +
              (makeEditable ? ' contenteditable="false"' : "") +
              ' class="inline-share" data-hash="' +
              linkItem.publicFile.hash +
              '" data-fileid="' +
              linkItem.publicFile.id +
              '">' +
              linkItem.publicFile.filename +
              "</span>"
          );
        }
      });
    }
    if (item.learn_content) {
      item.learn_content.forEach((contentItem) => {
        if (contentItem.selector) {
          if (contentItem.content.type == "video") {
            item.htmlMessage = item.htmlMessage.replace(
              contentItem.selector,
              "<span " + (makeEditable ? ' contenteditable="false"' : "") + 'class="inline-video" data-videoid="' + contentItem.content.id + '">' + contentItem.content.title + "</span>"
            );
          } else if (contentItem.content.type == "lesson") {
            item.htmlMessage = item.htmlMessage.replace(
              contentItem.selector,
              "<span " + (makeEditable ? ' contenteditable="false"' : "") + 'class="inline-video" data-lessonid="' + contentItem.content.id + '">' + contentItem.content.title + "</span>"
            );
          } else if (contentItem.content.tyoe == "course") {
            item.htmlMessage = item.htmlMessage.replace(
              contentItem.selector,
              "<span " + (makeEditable ? ' contenteditable="false"' : "") + 'class="inline-video" data-courseid="' + contentItem.content.id + '">' + contentItem.content.title + "</span>"
            );
          }
        }
      });
    }
    if (item.categories_or_sections) {
      item.categories_or_sections.forEach((contentItem) => {
        if (contentItem.selector) {
          let replaceHtml =
            "<span " +
            (makeEditable ? ' contenteditable="false"' : "") +
            ' class="inline-article" data-categoryid="' +
            contentItem.category_or_section.id +
            '">' +
            contentItem.category_or_section.name +
            "</span>";
          if (contentItem.category_or_section.category_id)
            replaceHtml =
              '<span class="inline-article" data-categoryid="' +
              contentItem.category_or_section.category_id +
              '" data-sectionid="' +
              contentItem.category_or_section.id +
              '">' +
              contentItem.category_or_section.name +
              "</span>";
          item.htmlMessage = item.htmlMessage.replace(contentItem.selector, replaceHtml);
        }
      });
    }

    if (item.embeds) {
      item.embeds = item.embeds.map((embed) => {
        if (typeof embed.src == "string") {
          return {
            ...embed,
            src: this.sanitizer.bypassSecurityTrustResourceUrl(embed.src),
          };
        } else {
          return embed;
        }
      });
    }

    return item;
  }

  // Supported services: YouTube, SoundCloud, Spotify
  parseEmbedUrls(text: string, compareArray: MessageEmbed[] = []) {
    if (text.includes("youtube.com/") || text.includes("youtu.be/") || text.includes("soundcloud.com/") || text.includes("open.spotify.com/")) {
      const urls = text.match(this.urlRegex) || [];
      const embeds: MessageEmbed[] = [];

      urls.forEach((url: string) => {
        let embedType: MessageEmbed["type"];
        const cleanUrl = url.replace(/&nbsp;?/g, "").trim();
        let id = cleanUrl;
        let embedSrc;
        let subtype = null;

        if (cleanUrl.includes("soundcloud.com/")) {
          // SoundCloud shortened urls can't be used for embedding.
          if (cleanUrl.includes("on.soundcloud.com")) return;
          embedType = "soundcloud";
          embedSrc = `https://w.soundcloud.com/player/?url=${cleanUrl}`;
        } else if (cleanUrl.includes("youtube.com/") || cleanUrl.includes("youtu.be/")) {
          embedType = "youtube";
          const parts = url.split("/");
          const idIndex = parts[2] == "youtu.be" ? 0 : parts[3].indexOf("v=") + 2;
          // youtube ids are 11 characters
          id = parts[3].substring(idIndex, idIndex + 11);
          embedSrc = `https://www.youtube.com/embed/${id}`;
        } else if (cleanUrl.includes("open.spotify.com/")) {
          const parts = url.split("/");
          const spotifyEmbedType = parts[3];
          const acceptedSpotifyEmbedTypes = ["track", "album", "artist", "episode", "show", "playlist"];

          if (spotifyEmbedType && acceptedSpotifyEmbedTypes.includes(spotifyEmbedType)) {
            id = parts[4].split("?")[0];

            if (id) {
              embedType = "spotify";
              embedSrc = `https://open.spotify.com/embed/${spotifyEmbedType}/${id}`;
              subtype = spotifyEmbedType;
            }
          }
        }

        if (embedType && compareArray.findIndex((embed) => embed.id == id) == -1 && embeds.findIndex((embed) => embed.id == id) == -1) {
          embeds.push({
            id,
            type: embedType,
            subtype,
            pastedUrl: cleanUrl,
            embedSrc,
          });
        }
      });

      return embeds;
    }
  }

  buildCommunityUserData(userData) {
    return {
      photo: userData.photo,
      cover_photo: userData.cover_photo,
      firstName: userData.firstName,
      lastName: userData.lastName,
      public: userData.public,
      tagline: userData.tagline,
      description: userData.description,
      id: userData.id,
      email: userData.email,
      hasSetupCommunity: userData.hasSetupCommunity,
      status: userData.status,
    };
  }
}
