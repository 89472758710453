<div class="modal-header">
  <h4 class="modal-title">{{ "product_detail.upgrade.title" | translate }}</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <myp-inline-loader [loading]="loading" height="200px" spinnerName="upgradeSoftwareLoader"></myp-inline-loader>

  <div class="form-group" *ngIf="!loading">
    <small class="form-text text-muted text-center">{{ "products.enter_new_product_key" | translate }}</small>
    <input id="productkey" class="form-control text-center mono text-sm" (keydown)="keyPressed($event)" guide="false" [maskito]="{ mask: mask }" [(ngModel)]="productKey" />
    <br />
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="performUpgrade()">{{ "product_detail.upgrade.upgrade" | translate }}</button>
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
</div>
