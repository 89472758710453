import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../environments/environment";
import { HelperService } from "./helper.service";
import { StorageKeys, StorageService } from "./storage.service";
import { UserService } from "./user.service";
import { catchError, map } from "rxjs/operators";
import { Observable, of as observableOf, of, Subject, throwError } from "rxjs";
import { ProductKeySearchAttributes, UserGroupUpdate, UserSearchAttributes, UserUpdateAttributes } from "../models";
import { UserSearchCachedData } from "../models/admin.model";

@Injectable({
  providedIn: "root",
})
export class AdminService {
  public allProducts: Array<any>;
  public productMap: any = {};
  public productTypeMap: any = {};
  public userSearchCache: UserSearchCachedData;
  public subscriptionSearchResultsCache: any = {};

  constructor(
    private http: HttpClient,
    private _helpers: HelperService,
    private userService: UserService,
    private storage: StorageService
  ) {}

  public get(method) {
    return this.http.get(environment.paeApiUrl + `admin/${method}`, this._helpers.getHttpOptions());
  }

  public productList(type, all?) {
    if (all && this.productTypeMap[type]) {
      return of(this.productTypeMap[type]);
    } else {
      return this.http.get(environment.paeApiUrl + `product/list/${type}${all ? "?all=true" : ""}`, this._helpers.getHttpOptions()).pipe(
        map((result) => {
          if (all) this.productTypeMap[type] = result;
          return result;
        })
      );
    }
  }

  public productDetail(id) {
    return this.http.get(environment.paeApiUrl + `admin/product/${id}`, this._helpers.getHttpOptions());
  }

  public productContent(productId) {
    return this.http.get(environment.paeApiUrl + `product/content/${productId}`, this._helpers.getHttpOptions());
  }

  public updateProduct(id, data) {
    return this.http.put(environment.paeApiUrl + `admin/product/${id}`, JSON.stringify(data), this._helpers.getHttpOptions());
  }

  public productTypes() {
    return this.http.get(environment.paeApiUrl + `product/types`, this._helpers.getHttpOptions());
  }

  public keygenProducts() {
    return this.http.get(environment.paeApiUrl + `product/key/`, this._helpers.getHttpOptions());
  }

  public generateKeys(args) {
    return this.http.post(environment.paeApiUrl + `product/key/`, JSON.stringify(args), this._helpers.getHttpOptions());
  }

  public getKeySets() {
    return this.http.get(environment.paeApiUrl + `product/keysets/`, this._helpers.getHttpOptions());
  }

  public deletePreviousVersion(id) {
    return this.http.delete(environment.paeApiUrl + `product/previous-version/${id}`, this._helpers.getHttpOptions());
  }

  public getKeySet(keySetId) {
    return this.http.get(environment.paeApiUrl + `product/download-public-key/${keySetId}`, this._helpers.getHttpOptions());
  }

  public addUpdatePreviousVersion(data) {
    let url = environment.paeApiUrl + "product/previous-version/" + (data.id ? data.id : "");
    return this.http[data.id ? "put" : "post"](url, JSON.stringify(data), this._helpers.getHttpOptions());
  }

  public addUpdateProductContent(data) {
    let url = environment.paeApiUrl + "product/content/" + (data.id ? data.id : "") + ("?productId=" + data.product_id);
    let id = data.id;
    delete data.id;
    return this.http[id ? "put" : "post"](url, JSON.stringify(data), this._helpers.getHttpOptions());
  }

  public sendEmail(data) {
    return this.http.post(environment.paeApiUrl + "admin/send-email", JSON.stringify(data), this._helpers.getHttpOptions());
  }

  public createJiraTicket(data: { summary: string; description: string; project?: string; type?: string; priority?: string }) {
    return this.http.post(environment.paeApiUrl + "admin/create-jira-ticket", JSON.stringify(data), this._helpers.getHttpOptions());
  }

  public addUpdateProduct(data) {
    let id = data.id || "";
    if (id) delete data.id;
    let url = environment.paeApiUrl + "admin/product/" + id;
    return this.http[id ? "put" : "post"](url, JSON.stringify(data), this._helpers.getHttpOptions());
  }

  public getProductFirmware(productId) {
    return this.http.get(environment.paeApiUrl + `product/content?productId=${productId}&contentType=firmware`, this._helpers.getHttpOptions());
  }

  public deleteProductContent(contentId, productId, firmware?: boolean) {
    let url = `product/content/${contentId}?productId=${productId}`;
    if (firmware) url += "&contentType=firmware";
    return this.http.delete(environment.paeApiUrl + url, this._helpers.getHttpOptions());
  }

  searchUsers(searchAttributes: UserSearchAttributes) {
    return this.http.post(environment.paeApiUrl + "admin/user/search", searchAttributes, this._helpers.getHttpOptions());
  }

  searchSubscriptions(args) {
    return this.http.post(environment.paeApiUrl + "admin/subscription/search", args, this._helpers.getHttpOptions());
  }

  getRecentSubscriptions(limit = 100) {
    return this.http.get(environment.paeApiUrl + `admin/subscription/recent/${limit}`, this._helpers.getHttpOptions());
  }

  userDetail(userId: string) {
    return this.http.get(environment.paeApiUrl + `admin/user/${userId}`, this._helpers.getHttpOptions());
  }

  getUserSubscriptions(userId: string) {
    return this.http.get(environment.paeApiUrl + `admin/user/${userId}/subscription`, this._helpers.getHttpOptions());
  }

  getCountriesList() {
    return this.http.get(environment.paeApiUrl + `user/countries`, this._helpers.getHttpOptions());
  }

  updateUserDataAndAddress(userId: string, updateAttributes: UserUpdateAttributes) {
    return this.http.post(environment.paeApiUrl + `admin/user/${userId}`, updateAttributes, this._helpers.getHttpOptions());
  }

  setCachedUserSearchResults(searchResults: UserSearchCachedData) {
    this.userSearchCache = searchResults;
  }

  public getBetas() {
    return this.http.get(environment.paeApiUrl + `admin/betas`, this._helpers.getHttpOptions());
  }

  public getBetaMembers(betaId) {
    return this.http.get(environment.paeApiUrl + `admin/betas/${betaId}/members`, this._helpers.getHttpOptions());
  }

  public removeBetaMember(betaId, userId) {
    return this.http.delete(environment.paeApiUrl + `admin/betas/${betaId}/members/${userId}`, this._helpers.getHttpOptions());
  }

  public createBeta(args) {
    return this.http.post(environment.paeApiUrl + `admin/betas`, JSON.stringify(args), this._helpers.getHttpOptions());
  }

  searchProductKey(searchAttributes: ProductKeySearchAttributes) {
    return this.http.post(environment.paeApiUrl + "admin/user/search_product_key", searchAttributes, this._helpers.getHttpOptions());
  }

  updateUserGroupRelation(usergroupsData: UserGroupUpdate) {
    return this.http.put(environment.paeApiUrl + `admin/user/groups`, usergroupsData, this._helpers.getHttpOptions());
  }

  deleteUser(userId: string, softDelete: boolean) {
    return this.http.delete(environment.paeApiUrl + `admin/user/${userId}?softDelete=${softDelete}`, this._helpers.getHttpOptions());
  }

  deregisterHardwareKeys(keys: string[]) {
    return this.http.post(environment.paeApiUrl + `admin/user/deregister-serial-numbers`, keys, this._helpers.getHttpOptions());
  }

  clearUserCache(userId: string) {
    return this.http.get(environment.paeApiUrl + `admin/user/${userId}/clear-cache`, this._helpers.getHttpOptions());
  }
  public cancelSubscription(userId: string, subscriptionId: string, expireImmediately?: boolean) {
    return this.http.get(environment.paeApiUrl + `admin/user/${userId}/cancel-subscription/${subscriptionId}${expireImmediately ? "?expire=true" : ""}`, this._helpers.getHttpOptions());
  }
}
