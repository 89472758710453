<myp-inline-loader [loading]="loading" height="200px" spinnerName="workspaceDetailLoader"></myp-inline-loader>
<div class="container workspace-page" #container *ngIf="!loading && workspace">
  <ng-container>
    <div class="d-flex d-md-flex justify-space-between align-items-center m-b-10 m-t-5">
      <div class="text-left workspace-title">
        <h1 class="text-truncate menu-title">{{ workspace.name }}</h1>
      </div>
      <div class="text-right workspace-action">
        <span class="btn btn-secondary" (click)="editWorkspace()">{{ "general.settings" | translate }}</span>
      </div>
    </div>
  </ng-container>

  <div class="row m-b-10 d-none d-lg-none" [class.d-block]="selectedView == 'details'">
    <div class="col">
      <div class="view-chooser-container d-flex">
        <div class="view-chooser white" style="width: 100%" [ngStyle]="{ opacity: batchOperationMode ? '0' : '1' }">
          <div class="option" [class.selected]="selectedView == 'timeline'" (click)="batchOperationMode ? '' : selectView('timeline')">{{ "sphere.workspaces.timeline" | translate }}</div>
          <div class="option" [class.selected]="selectedView == 'files'" (click)="batchOperationMode ? '' : selectView('files')">
            {{ "sphere.workspaces.shared_files" | translate }}
            <span class="unread-badge" *ngIf="totalUnreadFileMessages">{{ totalUnreadFileMessages }}</span>
          </div>
          <div class="option d-block d-lg-none" [class.selected]="selectedView == 'details'" (click)="selectView('details')">{{ "sphere.details" | translate }}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex">
    <div class="d-none d-lg-block workspace-sidebar" [style]="'width: ' + detailsWidth + '; margin-right: 15px; min-width: 300px;'" [class.d-block]="selectedView == 'details'" *ngIf="workspace">
      <ng-container *ngTemplateOutlet="detailContent"></ng-container>
    </div>
    <div class="d-none d-lg-block overflow-hidden flex-fill" [class.d-block]="selectedView == 'timeline' || selectedView == 'files'">
      <div class="row m-b-10">
        <div class="col">
          <div class="view-chooser-container d-flex">
            <div class="view-chooser white" style="width: 100%" [ngStyle]="{ opacity: batchOperationMode ? '0' : '1' }">
              <div class="option" [class.selected]="selectedView == 'timeline'" (click)="batchOperationMode ? '' : selectView('timeline')">{{ "sphere.workspaces.timeline" | translate }}</div>
              <div class="option" [class.selected]="selectedView == 'files'" (click)="batchOperationMode ? '' : selectView('files')">
                {{ "sphere.workspaces.shared_files" | translate }}
                <span class="unread-badge" *ngIf="totalUnreadFileMessages">{{ totalUnreadFileMessages }}</span>
              </div>
              <div class="option d-block d-lg-none" [class.selected]="selectedView == 'details'" (click)="selectView('details')">{{ "sphere.details" | translate }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" #timelineFilesView style="overflow: hidden" [ngStyle]="{ height: timelineFilesViewHeight + 'px' }">
        <div class="col-md-12 h-full">
          <div
            ng2FileDrop
            class="discussion-files-view timeline-drop-zone"
            [class.hovering]="hasBaseDropZoneOver"
            (onFileDrop)="droppedFile($event)"
            [uploader]="uploader"
            (fileOver)="fileOverBase($event)"
          >
            <div class="files-note" *ngIf="batchOperationMode && !hideBatchDownloadNotice">
              {{ "sphere.workspaces.batch_download_note1" | translate }}
              <br />
              <small *ngIf="!isChrome">
                {{ "sphere.workspaces.batch_download_note2" | translate }}
                <br />
              </small>
              <a class="btn btn-primary" *ngIf="!isChrome" href="https://chrome.com" target="_blank">{{ "sphere.workspaces.download_chrome" | translate }}</a>
              &nbsp;&nbsp;
              <a class="btn btn-primary" href="https://www.howtogeek.com/428416/how-to-enabledisable-multiple-file-downloads-in-chrome/" target="_blank">{{ "general.learn_more" | translate }}</a>
              &nbsp;&nbsp;
              <button class="link" (click)="hideBatchDownloadNotice = true">{{ "sphere.dismiss" | translate }}</button>
            </div>
            <div class="timeline-instruction-overlay" *ngIf="selectedView != 'files' && !loadingTimeline && (!communityService.comments || !communityService.comments.length)">
              <div class="text-center">
                <span *ngIf="hasMessagesPermission" class="gothic">{{ "sphere.workspaces.timeline" | translate }}</span>
                <div *ngIf="myWorkspace && workspace.collaborators.length == 1">
                  This is where the discussion with your collaborators will happen. The first step is to invite someone to collaborate.
                  <br />
                  <br />
                  <button class="small-btn" (click)="manageCollaborators()">{{ "sphere.workspaces.manage_collaborators" | translate }}</button>
                </div>
                <div *ngIf="hasMessagesPermission && ((myWorkspace && workspace.collaborators.length > 1) || !myWorkspace)">Its awfully quiet in here. Send a message below!</div>
              </div>
            </div>

            <div *ngIf="attingCollabs && attingCollabs.length" class="atting-collabs">
              <div *ngFor="let user of attingCollabs" (click)="selectAtCollab(user)" class="pointer" [class.selected]="highlightedAtCollab.user_id == user.user_id">{{ user.name }}</div>
            </div>
            <div class="h-full">
              <div class="d-flex justify-content-center align-items-center t-30 h-full" *ngIf="loadingTimeline && selectedView != 'files'">
                <myp-inline-loader [loading]="true" height="200px" spinnerName="timelineLoader"></myp-inline-loader>
              </div>
              <div class="h-full" *ngIf="selectedView != 'files' && workspace">
                <div #chatWindow class="discussion-container h-full" [ngStyle]="{ 'border-radius': '10px 10px 0px 0px' }">
                  <div class="text-center" *ngIf="!showingAllMessages && !loadingTimeline">
                    <button class="small-btn" *ngIf="displayedMessages.length == 100" (click)="initMessages(true)">{{ "com.get_older_messages" | translate }}</button>
                  </div>
                  <ng-container *ngFor="let msg of communityService.comments">
                    <ng-container *ngIf="collaboratorMap[msg.author.id]">
                      <div *ngIf="msg.type == 'message'" class="msg-container" [class.me]="msg.author.id == user.id" [class.them]="msg.author.id != user.id">
                        <img [src]="collaboratorMap[msg.author.id].photo || './assets/images/default_avatar.jpg'" class="profile-photo" />
                        <div class="msg">
                          <span class="text" *ngIf="msg.type == 'message'" [innerHTML]="msg.htmlMessage | safe: 'html'"></span>
                          <span class="timestamp d-block">
                            {{ msg.timestamp && msg.timestamp.toDate ? (msg.timestamp.toDate() | date: "MMM Do h:mma":true) : "" }}
                            <span *ngIf="msg.author.id != user.id && msg.author.name != 'null null'">&nbsp;{{ msg.author.name }}</span>
                            <span *ngIf="msg.edited" class="c-gray">&nbsp;Edited</span>
                            <div>
                              <button *ngIf="msg.author.id == user.id || myWorkspace" class="msg-options-link" (click)="msgOptions(msg)">{{ "sphere.options" | translate }}</button>
                            </div>
                          </span>
                        </div>
                      </div>

                      <div *ngIf="msg.type == 'file-uploaded' && sharedFilesMap[msg.file.id]" class="m-y-30 p-y-30 text-center">
                        <div class="d-flex justify-content-center align-items-center text-left m-b-10">
                          <img [src]="collaboratorMap[msg.author.id].photo || './assets/images/default_avatar.jpg'" class="profile-photo" />
                          <div style="line-height: 13px">
                            <span class="t-12 d-block">{{ msg.timestamp.toDate() | date: "MMM Do [at] hh:mm":true }}</span>
                          </div>
                          <div class="d-flex m-l-10">
                            <fa-icon
                              icon="download"
                              *ngIf="sharedFilesMap[msg.file.id] && sharedFilesMap[msg.file.id].progress == 100"
                              placement="top"
                              [ngbTooltip]="'download.download' | translate"
                              class="action circle-icon-btn m-r-10"
                              (click)="downloadFile($event, msg.file)"
                            ></fa-icon>
                            <fa-icon icon="ellipsis-h" class="action circle-icon-btn m-r-10" (click)="msgOptions(msg)" placement="top" [ngbTooltip]="'sphere.options' | translate"></fa-icon>
                          </div>
                        </div>
                        <ng-container *ngIf="workspaceService.imageFiles[msg.file.extension]">
                          <h6 class="d-block m-b-10 t-12 bold" *ngIf="msg.file.coverphoto == '1'">
                            {{ "sphere.workspaces.changed_cover_photo" | translate: { author: msg.author.name } }}
                          </h6>
                          <h6 class="d-block m-b-10 t-12 bold" *ngIf="msg.file.coverphoto != '1'">
                            {{ "sphere.workspaces.author_shared_image" | translate: { author: msg.author.name, type: msg.file.extension } }}
                          </h6>
                          <h6 class="d-block m-b-10 t-12 bold" *ngIf="msg.file.coverphoto != '1' && sharedFilesMap[msg.file.id].description">{{ sharedFilesMap[msg.file.id].description }}</h6>
                          <ng-container *ngIf="sharedFilesMap[msg.file.id].progress == 100">
                            <div class="msg-image-container" *inView>
                              <img
                                [src]="workspaceService.buildFileUrl(workspace.user_id, msg.file.workspace_id, msg.file)"
                                (click)="showImage(workspaceService.buildFileUrl(workspace.user_id, msg.file.workspace_id, msg.file))"
                                class="msg-image image-border"
                              />
                            </div>
                          </ng-container>
                          <div *ngIf="sharedFilesMap[msg.file.id].progress != 100" class="file-upload-indicator">
                            <div>
                              {{ msg.file.filename }}
                              <br />
                              <fa-icon icon="spinner" [animation]="'spin'" *ngIf="!sharedFilesMap[msg.file.id].stalled"></fa-icon>
                              <fa-icon icon="times" class="c-red" *ngIf="sharedFilesMap[msg.file.id].stalled"></fa-icon>
                            </div>
                          </div>
                          <span class="warning-note" *ngIf="sharedFilesMap[msg.file.id] && sharedFilesMap[msg.file.id].progress != 100 && !sharedFilesMap[msg.file.id].stalled">
                            This file hasn't completed uploading. Currently at {{ sharedFilesMap[msg.file.id].progress }}%...
                            <br />
                          </span>
                          <span class="failed-note" *ngIf="sharedFilesMap[msg.file.id] && sharedFilesMap[msg.file.id].stalled">
                            The upload appears to have stalled. Please delete it and try uploading again.
                            <br />
                          </span>
                        </ng-container>

                        <ng-container *ngIf="workspaceService.audioFiles[msg.file.extension]">
                          <h6 class="d-block m-b-10 t-12 bold" *ngIf="sharedFilesMap[msg.file.id].mainmix">
                            {{ "sphere.workspaces.author_updated_mix" | translate: { author: msg.author.name } }}
                          </h6>
                          <h6 class="d-block m-b-10 t-12 bold" *ngIf="!sharedFilesMap[msg.file.id].mainmix && !msg.source">
                            {{ "sphere.workspaces.author_shared_file" | translate: { author: msg.author.name, type: msg.file.extension } }}
                          </h6>
                          <h6 class="d-block m-b-10 t-12 bold" *ngIf="!sharedFilesMap[msg.file.id].mainmix && msg.source">
                            {{ "sphere.workspaces.author_shared_file_from" | translate: { author: msg.author.name, type: msg.file.extension, source: msg.source } }}
                          </h6>
                          <h6 class="d-block m-b-10 t-12 bold">{{ sharedFilesMap[msg.file.id].filename }}</h6>
                          <h6 class="d-block m-b-10 t-12 bold" *ngIf="sharedFilesMap[msg.file.id].description">{{ sharedFilesMap[msg.file.id].description }}</h6>
                          <div *ngIf="sharedFilesMap[msg.file.id].progress == 100">
                            <div class="d-flex align-items-center justify-content-center m-y-20" (click)="playAudio(msg.file)">
                              <fa-icon icon="play" class="action circle-icon-btn m-r-10"></fa-icon>
                              <span class="gothic">{{ "sphere.play" | translate }}</span>
                            </div>
                          </div>
                          <div *ngIf="sharedFilesMap[msg.file.id].progress != 100" class="file-upload-indicator">
                            <div>
                              {{ msg.file.filename }}
                              <br />
                              <fa-icon icon="spinner" [animation]="'spin'" *ngIf="!sharedFilesMap[msg.file.id].stalled"></fa-icon>
                              <fa-icon icon="times" class="c-red" *ngIf="sharedFilesMap[msg.file.id].stalled"></fa-icon>
                            </div>
                          </div>
                          <span class="warning-note" *ngIf="sharedFilesMap[msg.file.id] && sharedFilesMap[msg.file.id].progress != 100 && !sharedFilesMap[msg.file.id].stalled">
                            {{ "sphere.workspaces.not_done_uploading" | translate: { progress: sharedFilesMap[msg.file.id].progress } }}
                            <br />
                          </span>
                          <span class="failed-note" *ngIf="sharedFilesMap[msg.file.id] && sharedFilesMap[msg.file.id].stalled">
                            {{ "sphere.workspaces.stalled_upload" | translate }}
                            <br />
                          </span>
                        </ng-container>

                        <ng-container *ngIf="!workspaceService.audioFiles[msg.file.extension] && !workspaceService.imageFiles[msg.file.extension]">
                          <h6 class="d-block m-b-10 t-12 bold" *ngIf="!msg.source">
                            {{ "sphere.workspaces.author_shared_file" | translate: { author: msg.author.name, type: msg.file.extension } }}
                          </h6>
                          <h6 class="d-block m-b-10 t-12 bold" *ngIf="msg.source">
                            {{ "sphere.workspaces.author_shared_file_from" | translate: { author: msg.author.name, type: msg.file.extension, source: msg.source } }}
                          </h6>
                          <h6 class="d-block m-b-10 t-12 bold" *ngIf="sharedFilesMap[msg.file.id].description">{{ sharedFilesMap[msg.file.id].description }}</h6>
                          <div class="generic-shared-timeline-file d-flex justify-content-center" *ngIf="sharedFilesMap[msg.file.id].progress == 100">
                            <div class="d-flex" style="background-color: #232323; border-radius: 10px; padding: 10px">{{ msg.file.filename }}</div>
                          </div>
                          <div *ngIf="sharedFilesMap[msg.file.id].progress != 100" class="file-upload-indicator">
                            <div>
                              {{ msg.file.filename }}
                              <br />
                              <fa-icon icon="spinner" [animation]="'spin'" *ngIf="!sharedFilesMap[msg.file.id].stalled"></fa-icon>
                              <fa-icon icon="times" class="c-red" *ngIf="sharedFilesMap[msg.file.id].stalled"></fa-icon>
                            </div>
                          </div>
                          <span class="warning-note" *ngIf="sharedFilesMap[msg.file.id] && sharedFilesMap[msg.file.id].progress != 100 && !sharedFilesMap[msg.file.id].stalled">
                            {{ "sphere.workspaces.not_done_uploading" | translate: { progress: sharedFilesMap[msg.file.id].progress } }}
                            <br />
                          </span>
                          <span class="failed-note" *ngIf="sharedFilesMap[msg.file.id] && sharedFilesMap[msg.file.id].stalled">
                            {{ "sphere.workspaces.stalled_upload" | translate }}
                            <br />
                          </span>
                          <span class="t-12 d-block">{{ sharedFilesMap[msg.file.id].filesize | bytesToSize }}</span>
                        </ng-container>
                        <button class="small-btn" (click)="fileItemClick(msg.file)">View File Details</button>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
              <ng-container *ngIf="selectedView == 'files' && workspace">
                <div class="h-full">
                  <myp-shared-files
                    #sharedFilesCmp
                    (batchOperationClicked)="toggleBatchOperationMode()"
                    (fileSelectedForBatchOperation)="selectFileForBatchOperation($event)"
                    (msgOptionsClicked)="msgOptions(false, $event.file, $event.event)"
                    (playAudioClicked)="playAudio($event.file, $event.event)"
                    (downloadFileClicked)="downloadFile($event.event, $event.file)"
                    (fileClicked)="fileItemClick($event)"
                    (refreshedFiles)="filesRefreshed($event)"
                    (addFileClicked)="addFile()"
                    (folderChange)="activeFolder = $event"
                    [workspace]="workspace"
                    [files]="sharedFiles"
                    [collaboratorMap]="collaboratorMap"
                    [groupedFilesMap]="groupedFilesMap"
                    [groupedFormatsMap]="groupedFormatsMap"
                    [unReadCounts]="unReadCounts"
                    [batchOperationMode]="batchOperationMode"
                  ></myp-shared-files>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="selectedView == 'files'">
        <div class="upload-file-btn" *ngIf="!batchOperationMode" (click)="addFile()">
          {{ "sphere.workspaces.upload_file" | translate }} to {{ activeFolder ? decodeName(activeFolder.path) : "Home" }}
        </div>
        <div class="upload-file-btn active" *ngIf="batchOperationMode && myRole != 'browser'">
          <span class="pointer" (click)="batchOperation('download')">Download Selected</span>
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <span class="pointer" (click)="batchOperation('move')">Move Selected&nbsp;&nbsp;|&nbsp;&nbsp;</span>
          <span class="pointer" (click)="batchOperation('delete')">Delete Selected</span>
        </div>
      </ng-container>
      <div
        class="msg-input workspace-message-input"
        [ngStyle]="{ width: chatWidth + 'px', left: 'auto', right: rightOffset + 'px', opacity: uiMeasured ? '1' : '0' }"
        *ngIf="!loadingConversation && selectedView != 'files' && hasMessagesPermission"
      >
        <myp-message-input
          #msgInput
          (returnPressed)="addMessage()"
          (loaded)="messageInputCmpLoaded($event)"
          type="span"
          [mentionSource]="true"
          [mentionDataSource]="collabsNotMe"
          (focus)="measureUI()"
          [inputID]="'message_input'"
          [placeholder]="'com.ctrl_enter' | translate"
          (valueChanged)="messageValueChange($event)"
          (inputHeightChange)="messageContainerHeightChange($event)"
          [includeSendButton]="true"
          [includeAttachmentButton]="!activeAttachment"
          (attachmentBtnClicked)="addFile()"
        ></myp-message-input>
      </div>
      <!-- <div class="msg-input" *ngIf="selectedView != 'files'">
        <input
          type="text"
          #msgInput
          (keydown)="timelineMessageKeyDown($event)"
          (keyup)="timelineMessageKeyup($event)"
          [(ngModel)]="newMsg"
          [placeholder]="'sphere.workspaces.enter_msg_dots' | translate"
        />
        <img src="./assets/icons/workspace-upload.png" *ngIf="myRole != 'browser'" (click)="addFile()" class="attach-btn" placement="top" [ngbTooltip]="'sphere.workspaces.share_file' | translate" />
        <span class="send-btn" (click)="addMessage()" [class.disabled]="!newMsg">{{ "general.send" | translate | uppercase }}</span>
      </div> -->
    </div>
  </div>
</div>

<ng-template #detailContent>
  <div class="image-container">
    <img class="w-full border-radius10 pointer image-border" *ngIf="workspace.image" (click)="showImage(workspace.image)" [src]="workspace.image" />
  </div>
  <button class="small-btn" (click)="changeImage()" *ngIf="myWorkspace">
    {{ workspace.image ? ("sphere.account.change_cover_photo" | translate) : ("sphere.account.add_cover_photo" | translate) }}
  </button>

  <div id="workspace-details">
    <div id="details-text" [class.expanded]="descriptionExpanded">
      <span #descTextSpan>{{ workspace.description ? workspace.description : ("sphere.workspaces.no_desc" | translate) }}</span>
    </div>
    <button class="small-btn" *ngIf="shouldShowShowMore" (click)="descriptionExpanded = !descriptionExpanded">
      {{ descriptionExpanded ? ("sphere.workspaces.show_less" | translate) : ("sphere.workspaces.show_more" | translate) }}
    </button>
  </div>
  <div class="m-t-20" *ngIf="workspace.mainMix && refreshFileDone && sharedFilesMap[workspace.mainMix.id] && sharedFilesMap[workspace.mainMix.id].progress == 100">
    <span class="t-16 bold brandfont-bold">{{ "sphere.workspaces.latest_mix" | translate }}</span>
    <br />
    <div>
      <div class="d-flex align-items-center" (click)="playAudio(workspace.mainMix)">
        <fa-icon icon="play" class="action circle-icon-btn m-r-10"></fa-icon>
        <span class="gothic">{{ "sphere.workspaces.play_mix" | translate }}</span>
      </div>
      <div class="main-mix-details">
        {{ workspace.mainMix.filename }}
        <br />
        {{ workspace.mainMix.description }}
        <span class="t-16 bold brandfont-bold">{{ "sphere.workspaces.uploaded_by_on" | translate: { name: workspace.mainMix.creator.name, date: workspace.mainMix.date_created | date } }}</span>
      </div>

      <button class="small-btn" (click)="addFile(true)">{{ "sphere.workspaces.upload_new_mix" | translate }}</button>
    </div>
  </div>

  <div class="collaborators m-t-20">
    <span class="t-16 bold brandfont-bold">{{ "sphere.workspaces.collaborators" | translate }}</span>
    <br />

    <myp-list-item
      *ngFor="let collab of workspace.collaborators"
      [photo]="collab.photo ? collab.photo : './assets/images/default_avatar.jpg'"
      [title]="((collab.name && collab.name != ' ' ? collab.name : false) || collab.email) + (collab.user_id == user.id ? ' (Me)' : '')"
      [item]="collab"
      [includeChevron]="collab.user_id"
      [subData]="[collab.role | uppercase]"
      (itemClicked)="collabClick(collab)"
    >
      <div class="text-right text-nowrap m-r-5" *ngIf="activeUsers && !activeUsers[collab.user_id] && collab.status == 'pending'">
        <fa-icon icon="paper-plane" placement="left" (click)="resendInvite(collab.email, $event)" [ngbTooltip]="'Resend Invite'" class="action circle-icon-btn m-r-10"></fa-icon>
        <span class="collab-status pending">{{ "sphere.workspaces.pending" | translate }}</span>
      </div>
    </myp-list-item>
    <button class="small-btn" (click)="manageCollaborators()" *ngIf="myWorkspace">{{ "sphere.workspaces.manage_collaborators" | translate }}</button>
  </div>
</ng-template>
