<div class="fullwidth-layout-container" basepage="secondary">
  <div class="row" #prodDetailTitle>
    <div class="col">
      <div class="page-nav-header no-pills">
        <div class="back-container" style="display: flex">
          <button type="button" class="btn btn-transparent p-0" (click)="goBack()" aria-label="back">
            <fa-icon icon="arrow-left" class="circle-icon-btn" aria-hidden="true" ngbTooltip="Back"></fa-icon>
            <span class="sr-only">back</span>
          </button>

          <h2 class="m-b-0">{{ snapshot ? snapshot?.product?.title : ("general.loading_dots" | translate) }}</h2>
          <span *ngIf="snapshot?.product?.version" class="version" (click)="setup(true)">v{{ snapshot.product.version }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="!loading && snapshot">
    <div class="col-sm-3 col-lg-2 col-12" #prodDetailSidebar>
      <div #prodDetailSidebarSticky [class.sticky-sidebar]="shouldBeSticky" [ngStyle]="{ width: sidebarWidth + 'px' }">
        <div class="prod_img-container" *ngIf="productImage">
          <img class="prod_img" [src]="productImage" (click)="imageClick()" />
        </div>
        <ng-container *ngIf="listProduct?.includedInSubscription">
          <div class="d-flex t-12 align-items-center m-y-10">
            <img src="./assets/icons/plus-icon-blue.png" class="s1plus-icon-md m-r-5" />
            <span class="serial-btn info">{{ "sphere.included_in_membership" | translate }}</span>
          </div>
          <div class="m-b-20" *ngIf="listProduct.releaseDate || listProduct.aquired">
            <span class="serial-btn info t-12">{{ "sphere.date_added" | translate }}: {{ listProduct.releaseDate || listProduct.aquired | date }}</span>
          </div>
        </ng-container>
        <ng-container *ngIf="licenses">
          <myp-installer-buttons
            [showAllSystems]="showAllSystems"
            [downloads]="snapshot.downloads"
            [license]="licenses ? licenses[0] : null"
            [previousVersions]="snapshot.previousVersions"
            [firmwareList]="snapshot.firmware"
            [product]="snapshot.product"
          ></myp-installer-buttons>
        </ng-container>
      </div>
    </div>

    <div class="col-12 col-lg-10 col-sm-9">
      <div class="prod_details">
        <div #prodDetailHead>
          <div id="text_details" *ngIf="snapshot.product.stringId != 'notion6.demo'">
            <div class="m-b-10" *ngIf="snapshot.product.description" [innerHTML]="snapshot.product.description"></div>
            <span class="sm b" *ngIf="snapshot.product.stringId != 'presonushub' && snapshot.product.partnerKey">
              {{
                (snapshot.product.stringId == "eastwest.composercloud"
                  ? "product_detail.redeem_code"
                  : snapshot.product.categoryId == "software"
                    ? "product_detail.product_keys"
                    : "product_detail.serial_numbers"
                ) | translate
              }}
            </span>
            <ng-container *ngIf="!productService.hasGottenProducts">
              <span class="font-italic">Loading your product data...</span>
            </ng-container>
            <div *ngIf="loadingLicenses">
              <fa-icon icon="spinner" [animation]="'spin'"></fa-icon>
              Loading your licenses...
            </div>
            <div *ngFor="let license of licenses" class="detail_keys">
              <div *ngIf="snapshot.product.partnerKey || license.showKey">
                <div *ngIf="snapshot.product.partnerName != 'third_party' && snapshot.product.stringId != 'presonushub' && snapshot.product.stringId != 'notionmobile3'">
                  <small class="text-muted d-block">{{ license.productKeyString ? "Product Key" : "Serial Number" }}</small>
                  <span class="mono key">{{ license.productKeyString || license.hardwareSerialNumber | uppercase }}</span>
                </div>
                <div *ngIf="snapshot.product.partnerName == 'third_party'">
                  <small class="text-muted d-block">{{ license.productKeyString ? "Product Key" : "Serial Number" }}</small>
                  <span class="mono key">{{ license.productKeyString || license.hardwareSerialNumber }}</span>
                </div>

                <span class="unlock-key" *ngIf="license.productUnlockKey">{{ "product_detail.unlock_key" | translate }}: {{ license.productUnlockKey | uppercase }}</span>
                <span class="unlock-key" *ngIf="license.productKeyOptionTitle">{{ "product_detail.vendor" | translate }}: {{ license.productKeyOptionTitle }}</span>
              </div>
              <div *ngIf="license.categoryId == 'subscription'">
                <small class="text-muted d-block">Product Key</small>
                <span class="mono key">{{ "sphere.included_in_membership" | translate }}</span>
              </div>
              <div class="serial_actions" *ngIf="showKeyActions">
                <span class="serial-btn info" *ngIf="snapshot.product.stringId != 'presonushub' && snapshot.product.categoryId != 'subscription'">
                  {{ license.categoryId == "subscription" ? "Renewed" : ("product_detail.registered" | translate) }}: {{ license.aquired | date }}
                </span>
                <a class="small-btn blue" (click)="showUpgrade(license)" *ngIf="!license.upgraded && snapshot.product.canUpgrade && snapshot.product.stringId != 'presonushub'">
                  {{ "general.upgrade" | translate }}
                </a>
                <button
                  class="small-btn offline-activation offline-activation-btn"
                  tabindex="0"
                  (keyup.enter)="selectedLicense = license"
                  (click)="selectedLicense = license"
                  *ngIf="
                    snapshot.product.categoryId != 'hardware' &&
                    snapshot.product.licenseScheme == 0 &&
                    !snapshot.product.partnerName &&
                    snapshot.product.type != 'bundle' &&
                    snapshot.product.stringId != 'presonushub' &&
                    snapshot.product.categoryId != 'subscription' &&
                    snapshot.product.stringId != 'notionmobile3' &&
                    license.categoryId != 'subscription'
                  "
                  [ngbPopover]="popContent"
                  [popoverTitle]="popTitle"
                >
                  {{ "product_detail.activations.offline_title" | translate }}
                </button>
                <button class="small-btn transfer" tabindex="0" (click)="showTransferLicense(license)" *ngIf="license.transferable && license.transferFee">
                  {{ "product_detail.transfer_license.transfer_license" | translate }}
                </button>

                <ng-container
                  *ngIf="snapshot.product.categoryId != 'subscription' && (snapshot.product.categoryId == 'software' || (snapshot.product.categoryId == 'extensions' && license.activations?.length))"
                >
                  <button class="small-btn manage" *ngIf="license.activations?.length && snapshot.product.licenseScheme != 1" (click)="showManageActivations(license)">
                    {{ (license.activations?.length || 0) + "/" + license.maxActivations }} {{ "product_detail.activations.activations" | translate }} - {{ "general.manage" | translate }}
                  </button>
                  <span class="serial-btn info" *ngIf="!license.activations?.length">
                    {{ (license.activations?.length || 0) + "/" + license.maxActivations }} {{ "product_detail.activations.activations" | translate }}
                  </span>
                </ng-container>
                <span class="small-btn red deregister" (click)="removeHardware(license)" *ngIf="snapshot.product.categoryId == 'hardware'">{{ "product_detail.deregister" | translate }}</span>
              </div>
              <div class="t-12" *ngIf="license.extendedSupportDate">
                Elligible for all updates until {{ license.extendedSupportDate | date }} -
                <a class="small-btn blue" (click)="showUpgrade(license)">Extend</a>
              </div>
            </div>
            <span class="d-block link m-y-10 t-12" (click)="buy()" *ngIf="snapshot.product.id == env.product_ids.s16pro">
              {{ subscriptionOnly ? "Purchase a perpetual Studio One 6 Professional license" : "Purchase an additional perpetual Studio One 6 Professional license" }}

              <fa-icon icon="chevron-right"></fa-icon>
            </span>
          </div>
        </div>

        <div class="trans-bg p-20 border-radius10 d-inline-block m-y-20" *ngIf="productService.elligleToRedeemStudioOneSeven">
          <h4 class="t-14 bold">You are elligible to get Studio One Pro 7 for free!</h4>
          <button class="btn btn-primary" (click)="showS17Redeem()">Redeem Now</button>
        </div>
        <div id="install_instructions" *ngIf="s1OrNotion || installMethods" class="m-t-20 m-b-20">
          <ng-container *ngIf="s1OrNotion">
            <h4 class="section-header m-b-10 m-t-30">
              {{ "product_detail.install_instructions.to_install" | translate }}
            </h4>
            <span *ngIf="snapshot.product.licenseScheme != 1">{{ "product_detail.install_instructions.download_installer" | translate }}</span>
            <span *ngIf="snapshot.product.licenseScheme == 1">{{ "product_detail.install_instructions.notion_download_installer" | translate }}</span>
          </ng-container>
          <ng-container *ngIf="installMethods">
            <div *ngFor="let method of installMethods">
              <div class="m-t-20" *ngIf="method == 's1'">
                <span class="install_instructions_header">{{ "sphere.install_s1_title" | translate }}</span>
                <br />
                <span *ngIf="snapshot.product.stringId == 'presonus.fatchannel'">{{ "sphere.plugin_comes_s1" | translate }}</span>
                <span *ngIf="snapshot.product.stringId != 'presonus.fatchannel'">{{ "sphere.download_plugin_installer" | translate }}</span>
              </div>
              <div class="m-t-20" *ngIf="method == 'hub' && env.features.redeem_ampire_fatchannel">
                <span class="install_instructions_header">{{ "sphere.install_in_hub" | translate }}</span>
                <br />
                {{ "sphere.download_hub_installer" | translate }}
                <br />
                <span class="btn btn-primary m-t-20" (click)="downloadHub()">
                  {{ productService.productsByStringIDs["presonushub"] ? ("download.download" | translate) : ("sphere.redeem_and_download" | translate) }} PreSonus Hub
                </span>
              </div>
              <div class="m-t-20" *ngIf="method == 'uc'">
                <span class="install_instructions_header">{{ "sphere.install_on_studiolive" | translate }}</span>
                <br />
                <span [innerHTML]="'sphere.first_download_uc' | translate"></span>
                <br />

                <a class="btn btn-primary" href="https://www.presonus.com/products/UC-Surface/downloads" target="_blank">{{ "sphere.download_uc" | translate }}</a>
              </div>
            </div>
          </ng-container>
        </div>

        <div class="m-t-20">
          <myp-bundled-downloads [product]="snapshot.product" [bundledDownloads]="snapshot.bundledDownloads" [showAllSystems]="showAllSystems"></myp-bundled-downloads>
        </div>
        <div class="flagship m-y-30" *ngIf="snapshot.product.video_id || snapshot.product.lesson_id || snapshot.product.course_id">
          <h4
            class="section-header m-b-10 m-t-30"
            *ngIf="
              (snapshot.product.video_id && contentService.videosById[snapshot.product.video_id]) ||
              (snapshot.product.lesson_id && contentService.lessonMap[snapshot.product.lesson_id]) ||
              (snapshot.product.course_id && contentService.courseMap[snapshot.product.course_id])
            "
          >
            Getting Started
          </h4>
          <div>
            <div *ngIf="snapshot.product.video_id && contentService.videosById[snapshot.product.video_id]">
              <!-- Watch this video to learn everything you need to know about getting started with {{ snapshot.product.title }}: -->
              <div style="max-width: 300px" class="m-t-20">
                <myp-video-grid-item [video]="contentService.videosById[snapshot.product.video_id]"></myp-video-grid-item>
              </div>
            </div>
            <div [class.m-t-20]="snapshot.product.video_id" *ngIf="snapshot.product.lesson_id && contentService.lessonMap[snapshot.product.lesson_id]">
              <!-- To dig in deeper, complete this lesson to get the most out of {{ snapshot.product.title }}: -->
              <myp-learn-list-item [item]="contentService.lessonMap[snapshot.product.lesson_id]"></myp-learn-list-item>
            </div>
            <div class="m-t-20" *ngIf="snapshot.product.course_id && contentService.courseMap[snapshot.product.course_id]">
              <!-- For a deep dive, complete this whole course to master {{ snapshot.product.title }}: -->
              <myp-learn-list-item [item]="contentService.courseMap[snapshot.product.course_id]"></myp-learn-list-item>
            </div>
          </div>
        </div>
        <ng-container *ngIf="hasExtrasOrOffers">
          <h4 class="section-header m-b-10 m-t-30">
            Extras & Offers
            <span class="section-header-link" (click)="extrasOffersCollapsed = !extrasOffersCollapsed">
              {{ extrasOffersCollapsed ? ("sphere.expand" | translate) : ("sphere.collapse" | translate) }}
              <fa-icon [icon]="extrasOffersCollapsed ? 'chevron-down' : 'chevron-up'"></fa-icon>
            </span>
          </h4>
          <div *ngIf="!extrasOffersCollapsed">
            <div *ngFor="let license of licenses">
              <div class="trans-bg p-20 border-radius10 d-inline-block m-b-20" *ngIf="license.offers && license.offers.length">
                <span class="t-14">{{ "newdash.you_are_elligible" | translate: { product: snapshot.product.title } }}</span>
                <br />
                <button class="btn btn-primary m-t-10" (click)="openOffers(license)">{{ "newdash.view_offers" | translate }}</button>
              </div>
            </div>
            <div>
              <myp-magicbundle-redeem ready="true" [productDetail]="product" [productDetailID]="snapshot.product.id"></myp-magicbundle-redeem>
            </div>

            <div class="m-y-10" *ngIf="productService.gettingProducts">
              <myp-inline-loader [loading]="true" height="200px" spinnerName="gettingProductsLoader"></myp-inline-loader>
            </div>
          </div>
        </ng-container>
      </div>

      <div>
        <div class="children" *ngIf="bundledProducts && bundledProducts.length">
          <h4 class="section-header m-b-10 m-t-30">
            {{ "product_detail.product_comes_with" | translate }}
            <span class="section-header-link" (click)="bundledProductsCollapsed = !bundledProductsCollapsed">
              {{ bundledProductsCollapsed ? ("sphere.expand" | translate) : ("sphere.collapse" | translate) }}
              <fa-icon [icon]="bundledProductsCollapsed ? 'chevron-down' : 'chevron-up'"></fa-icon>
            </span>
          </h4>
          <div class="grid-container pad-10" *ngIf="!bundledProductsCollapsed">
            <myp-product-tile *ngFor="let prod of bundledProducts" (tileClicked)="tileClick(prod)" [product]="prod"></myp-product-tile>
          </div>
        </div>

        <div class="product-downloads" id="product-downloads" *ngIf="snapshot && downloads && downloadSections?.length">
          <h4 class="section-header m-b-10 m-t-30">
            {{ "download.downloads" | translate }}
            <span class="section-header-link" (click)="downloadsCollapsed = !downloadsCollapsed">
              {{ downloadsCollapsed ? ("sphere.expand" | translate) : ("sphere.collapse" | translate) }}
              <fa-icon [icon]="downloadsCollapsed ? 'chevron-down' : 'chevron-up'"></fa-icon>
            </span>
          </h4>
          <div *ngIf="!downloadsCollapsed">
            <myp-product-downloads #productDownloads [downloads]="downloads" [downloadSections]="downloadSections" [product]="snapshot.product"></myp-product-downloads>
          </div>
        </div>

        <div class="product-downloads" *ngIf="snapshot && snapshot?.samples?.length">
          <h4 class="section-header m-b-10 m-t-30">
            Sound Samples
            <span class="section-header-link" (click)="samplesCollapsed = !samplesCollapsed">
              {{ samplesCollapsed ? ("sphere.expand" | translate) : ("sphere.collapse" | translate) }}
              <fa-icon [icon]="samplesCollapsed ? 'chevron-down' : 'chevron-up'"></fa-icon>
            </span>
          </h4>
          <div class="grid-container pad-10" *ngIf="!samplesCollapsed">
            <div *ngFor="let smp of snapshot.samples" (click)="playSample(smp)" class="item-tile p-10">
              <fa-icon icon="play-circle" class="t-20"></fa-icon>
              <span class="brandfont-bold">{{ smp.title_products_audio }}</span>
            </div>
          </div>
        </div>

        <div class="third-party-keys" *ngIf="hasThirdPartyKeys">
          <h4 class="section-header m-t-30 m-b-20">{{ "product_detail.third_party_keys" | translate }}</h4>

          <div *ngFor="let item of licenses" class="m-y-30">
            <div *ngFor="let key of item.oemItems">
              <strong>{{ "product_detail.product_key" | translate }}: {{ item.productKeyString }}</strong>
              <br />
              <span>
                {{ key.title }} -
                <span class="btn btn-primary btn-sm" *ngIf="!key.data && !key.loading" (click)="getOemKey(item, key)">{{ "product_detail.claim_product_key" | translate }}</span>
                <span *ngIf="!key.loading && key.data">{{ key.data }}</span>
                <span *ngIf="key.loading">{{ "product_detail.claiming_key" | translate }}</span>
              </span>
            </div>
          </div>
        </div>

        <div class="product-downloads" *ngIf="resources && resourcesSections?.length">
          <h4 class="section-header m-b-10 m-t-30">
            {{ "product_detail.product_resources" | translate }}
            <span class="section-header-link" (click)="resourcesCollapsed = !resourcesCollapsed">
              {{ resourcesCollapsed ? ("sphere.expand" | translate) : ("sphere.collapse" | translate) }}
              <fa-icon [icon]="resourcesCollapsed ? 'chevron-down' : 'chevron-up'"></fa-icon>
            </span>
          </h4>
          <div *ngIf="!resourcesCollapsed">
            <ul class="nav nav-pills">
              <li class="nav-item" *ngFor="let section of resourcesSections">
                <a class="nav-link" (click)="displayResourceSection(section)" tabindex="0" (keyup.enter)="displayResourceSection(section)" [class.active]="section.displayed">
                  {{ "general." + section.value | translate }}
                </a>
              </li>
            </ul>

            <div *ngIf="displayedResourcesSection.value == 'related_products'" class="grid-container pad-10">
              <myp-product-tile *ngFor="let prod of snapshot.relatedProducts" (tileClicked)="tileClick(prod)" [product]="prod"></myp-product-tile>
            </div>

            <!-- <div *ngIf="displayedResourcesSection.value == 'accessories'" class="grid-container pad-10">
              <myp-product-tile *ngFor="let prod of snapshot.accessories" (tileClicked)="tileClick(prod)" [product]="prod"></myp-product-tile>
            </div> -->

            <div *ngIf="displayedResourcesSection.value == 'videos'" class="grid-container pad-10 wider">
              <myp-video-grid-item *ngFor="let item of snapshot.videos" [video]="item"></myp-video-grid-item>
            </div>
          </div>
        </div>

        <div class="product-downloads row" *ngIf="snapshot?.product?.extendable">
          <div class="col-md-12">
            <h4 class="section-header m-t-30 m-b-20">{{ "product_detail.option_cards.option_card" | translate }}</h4>
            <div *ngIf="optionCards && optionCards.length">
              <div class="option-card" *ngFor="let card of optionCards">
                <img [src]="card.imageUrl" />
                <div>
                  <span class="title">{{ card.title }}</span>
                  <br />
                  <span class="details">
                    {{ "general.serial_number" | translate }}: {{ card.hardwareSerialNumber }}
                    <br />
                    {{ "product_detail.option_cards.prod_with_sn" | translate }} {{ card.parentSerial | uppercase }}
                  </span>
                </div>
                <div class="buttons">
                  <span class="btn btn-primary btn-sm" (click)="optionCardInstructions(card)">{{ "product_detail.option_cards.view_install_instructions" | translate }}</span>
                  <span class="btn btn-danger btn-sm" (click)="deregisterOptionCard(card)">
                    {{ (removingOptionCard ? "product_detail.option_cards.removing" : "product_detail.option_cards.remove") | translate }}
                  </span>
                </div>
              </div>
            </div>
            <span *ngIf="!optionCards?.length">{{ "product_detail.option_cards.no_option_cards" | translate }}</span>
            <br />
            <br />
            <span class="btn btn-primary" (click)="addOptionCard()">{{ "product_detail.option_cards.register_option_card" | translate }}</span>
          </div>
        </div>
        <div class="m-b-50"></div>
      </div>
    </div>
  </div>

  <myp-inline-loader [loading]="loading" height="200px" spinnerName="prodDetailLoader"></myp-inline-loader>
</div>

<ng-template #popContent>
  <button
    *ngIf="snapshot.product.categoryId == 'software'"
    (keyup.enter)="showActivateOffline(selectedLicense)"
    (click)="showActivateOffline(selectedLicense)"
    class="activate_software_manual btn btn-primary btn-success"
  >
    <span class="glyphicon glyphicon-plus-sign"></span>
    {{ "product_detail.activations.activate_software" | translate }}
  </button>
  <a
    class="download_license btn btn-primary btn-primary"
    *ngIf="snapshot.product.categoryId == 'software' && selectedLicense.activations?.length > 0"
    target="_blank"
    [href]="
      env.phpBaseUrl +
      'products/download_license/' +
      user.id +
      '/' +
      selectedLicense.licenseId +
      '/' +
      selectedLicense.activations[0].activationCode +
      '/' +
      selectedLicense.activations[0].computerName +
      '/' +
      selectedLicense.productKeyString
    "
  >
    <span class="glyphicon glyphicon-download"></span>
    {{ "product_detail.activations.download_license" | translate }}
  </a>
  <a
    class="download_license btn btn-primary btn-primary"
    *ngIf="snapshot.product.categoryId == 'extension' && snapshot.product.licenseScheme == 0 && !snapshot.product.partnerName"
    target="_blank"
    [href]="env.phpBaseUrl + '/products/download_license/' + user.id + '/' + selectedLicense.licenseId"
  >
    <span class="glyphicon glyphicon-download"></span>
    {{ "product_detail.activations.download_license" | translate }}
  </a>
</ng-template>
<ng-template #popTitle>
  <span style="color: #333">{{ "product_detail.activations.offline_title" | translate }}</span>
</ng-template>
