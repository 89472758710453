<div class="row">
  <div class="col-sm-12 plan-container">
    <div>
      <h1 class="content-title section-header">{{ env.studioOnePlusBrandName }} Plan</h1>
      <span
        class="brandfont-black t-30"
        [class.hybrid-gradient]="userService.subDetails?.subscription?.subscription_levels_id == 2"
        [class.monthly-gradient]="userService.subDetails?.subscription?.subscription_levels_id != 2"
        *ngIf="userService.subDetails?.subscription && userService.subDetails?.active && userService.subDetails.stripe_subscription"
      >
        {{ userService.subDetails?.subscription?.subscription_levels_id == 1 ? "Monthly" : "Annual" }}
      </span>
      <p *ngIf="userService.subDetails.stripe_subscription && userService.subDetails.stripe_subscription.coupon && userService.subDetails.stripe_subscription.coupon == 'SPHERE4MFREE'" class="c-green">
        4 Months Free Coupon Applied
      </p>
      <p *ngIf="userService.subDetails.stripe_subscription && userService.subDetails.stripe_subscription.coupon && userService.subDetails.stripe_subscription.coupon == 'SPHERE1YFREE'" class="c-green">
        1 Year Free Coupon Applied
      </p>
      <p *ngIf="userService.subDetails.stripe_subscription && userService.subDetails.stripe_subscription.coupon && userService.subDetails.stripe_subscription.coupon == 'SPHEREBF2020'" class="c-green">
        10% Off Coupon Applied
      </p>
      <ng-container *ngIf="userService.subDetails.subscription">{{ "sphere.account.status" | translate }}: {{ statusMap[userService.subDetails.subscription.status].title }}</ng-container>
      <div class="brandfont-bold m-t-20" *ngIf="(!userService.subDetails.subscription || !userService.subDetails.active) && !loading">
        {{ "sphere.account.current_free_account" | translate }}
        <div class="m-y-20">
          <span [innerHTML]="'sphere.account.upgrade_description' | translate"></span>
        </div>
        <div class="m-b-20">
          <span class="brandfont-black">
            {{ "sphere.subscription_details.only_price" | translate: { monthly: userService.spherePrices.monthly, annual: userService.spherePrices.annual } }}
          </span>
        </div>
        <button class="btn btn-primary" (click)="upgrade()">{{ "sphere.subscription_details.upgrade_to_sphere" | translate }}</button>
      </div>
      <div
        class="brandfont-bold"
        *ngIf="
          userService.subDetails.active &&
          userService.subDetails.subscription &&
          !userService.subDetails.subscription.bundle_time &&
          !userService.subDetails.stripe_subscription &&
          !userService.subDetails.recharge_subscription &&
          !loading &&
          !userService.subDetails.scheduled_stripe_subscription
        "
      >
        Your subscription ends on {{ userService.subDetails.subscription.end_date | date: "MMMM Do, YYYY" }}
        <br />
        <br />
        <button *ngIf="env.features.recharge" class="btn btn-primary m-t-20" (click)="rechargePaymentOptions()">Enter Payment Details</button>
        <button *ngIf="!env.features.recharge" class="btn btn-primary m-t-20" (click)="setupSchedule()">Enter Payment Details</button>
      </div>
      <div *ngIf="userService.subDetails?.subscription?.subscription_levels_id == 2 && !hybridElligible">
        You will become eligible to receive a Studio One Pro perpetual license if your subscription renews on {{ userService.subDetails?.subscription?.end_date | date: "MMMM Do, YYYY" }}.
      </div>
      <div *ngIf="hybridElligible">
        Your plan includes a Studio One Pro perpetual license and access to all the extras in {{ env.studioOnePlusBrandName }}. Your access to {{ env.studioOnePlusBrandName }} will expire on the date
        shown below.
      </div>

      <!-- =============START OF BUNDLE DESCRIPTORS============= -->
      <ng-container *ngIf="userService.subDetails?.subscription?.bundle_time">
        <div class="m-t-20" *ngIf="userService.subDetails?.subscription_status == 'monthly_no_schedule'">
          <!-- They have an active 6 months monthly bundle subscription with no scheduled stripe subscription. -->
          <!-- You can maintain uninterrupted access to all the extras in Studio One Pro+ by subscribing to an Annual plan at any time before September 13th, 2025. You will not be charged until September 13th, 2025. -->
          <p>
            You can maintain uninterrupted access to all the extras in {{ env.studioOnePlusBrandName }} by subscribing to a Monthly or Annual plan at any time before
            {{ userService.subDetails.subscription.end_date | date: "MMMM Do, YYYY" }}. You will not be charged until {{ userService.subDetails.subscription.end_date | date: "MMMM Do, YYYY" }}.
          </p>
          <button *ngIf="env.features.recharge" class="btn btn-primary m-t-20" (click)="rechargePaymentOptions()">Enter Payment Details</button>
          <button *ngIf="!env.features.recharge" class="btn btn-primary m-t-20" (click)="setupSchedule()">Enter Payment Details</button>
        </div>

        <div class="m-t-20" *ngIf="userService.subDetails?.subscription_status == 'yearly_no_schedule'">
          <!-- They have an active 12 months hybrid bundle subscription with no scheduled stripe subscription. -->
          <p>
            You can maintain uninterrupted access to all the extras in {{ env.studioOnePlusBrandName }} by subscribing to an Annual plan at any time before
            {{ userService.subDetails.subscription.end_date | date: "MMMM Do, YYYY" }}. You will not be charged until {{ userService.subDetails.subscription.end_date | date: "MMMM Do, YYYY" }}.
          </p>
          <button *ngIf="env.features.recharge" class="btn btn-primary m-t-20" (click)="rechargePaymentOptions()">Enter Payment Details</button>
          <button *ngIf="!env.features.recharge" class="btn btn-primary m-t-20" (click)="setupSchedule()">Enter Payment Details</button>
        </div>

        <div class="m-t-20" *ngIf="userService.subDetails?.subscription_status == 'monthly_scheduled'">
          <p>
            You have selected to continue access with a {{ env.studioOnePlusBrandName }} Monthly plan, and will be charged
            {{
              (isNoDecimalCurrency(userService.subDetails.upcoming_invoice_preview.currency)
                ? isDivisibleBy100(userService.subDetails.upcoming_invoice_preview.currency)
                  ? (userService.subDetails.upcoming_invoice_preview.amount_due / 100 | currency: userService.subDetails.upcoming_invoice_preview.currency.toUpperCase())
                  : (userService.subDetails.upcoming_invoice_preview.amount_due | currency: userService.subDetails.upcoming_invoice_preview.currency.toUpperCase())
                : (userService.subDetails.upcoming_invoice_preview.amount_due / 100 | currency: userService.subDetails.upcoming_invoice_preview.currency.toUpperCase():"symbol":"1.2-2")) +
                " " +
                (isTaxIncluded ? "including tax" : "excluding tax")
            }}
            for this plan starting {{ userService.subDetails?.upcoming_invoice_preview.next_payment_attempt * 1000 | date: "MMMM Do, YYYY" }}. You can cancel this selection or upgrade to a
            {{ env.studioOnePlusBrandName }} Annual plan any time before {{ userService.subDetails?.upcoming_invoice_preview.next_payment_attempt * 1000 | date: "MMMM Do, YYYY" }}.
          </p>
          <button class="btn btn-primary m-t-20 btn-sm" (click)="setupSchedule()">Upgrade to {{ env.studioOnePlusBrandName }} Annual</button>
          <br />
          <br />
          <button class="btn btn-primary m-t-20 btn-sm" (click)="cancelSubscription()">Cancel Scheduled Subscription</button>
        </div>

        <div class="m-t-20" *ngIf="userService.subDetails?.subscription_status == 'yearly_scheduled'">
          <p>
            You have selected to continue access with a {{ env.studioOnePlusBrandName }} Annual plan, and will be charged
            {{
              (isNoDecimalCurrency(userService.subDetails.upcoming_invoice_preview.currency)
                ? isDivisibleBy100(userService.subDetails.upcoming_invoice_preview.currency)
                  ? (userService.subDetails.upcoming_invoice_preview.amount_due / 100 | currency: userService.subDetails.upcoming_invoice_preview.currency.toUpperCase())
                  : (userService.subDetails.upcoming_invoice_preview.amount_due | currency: userService.subDetails.upcoming_invoice_preview.currency.toUpperCase())
                : (userService.subDetails.upcoming_invoice_preview.amount_due / 100 | currency: userService.subDetails.upcoming_invoice_preview.currency.toUpperCase():"symbol":"1.2-2")) +
                " " +
                (isTaxIncluded ? "including tax" : "excluding tax")
            }}
            for this plan starting {{ userService.subDetails?.upcoming_invoice_preview.next_payment_attempt * 1000 | date: "MMMM Do, YYYY" }}. You can cancel this selection any time before
            {{ userService.subDetails?.upcoming_invoice_preview.next_payment_attempt * 1000 | date: "MMMM Do, YYYY" }}.
          </p>
          <button class="btn btn-primary m-t-20 btn-sm" (click)="cancelSubscription()">Cancel Scheduled Subscription</button>
        </div>

        <div class="m-t-20" *ngIf="userService.subDetails?.subscription_status == 'monthly_active_coupon'">
          Your {{ env.studioOnePlusBrandName }} Monthly plan will return to regular payments of
          {{
            (isNoDecimalCurrency(userService.subDetails.upcoming_invoice_preview.currency)
              ? isDivisibleBy100(userService.subDetails.upcoming_invoice_preview.currency)
                ? (userService.subDetails.upcoming_invoice_preview.subtotal / 100 | currency: userService.subDetails.upcoming_invoice_preview.currency.toUpperCase())
                : (userService.subDetails.upcoming_invoice_preview.subtotal | currency: userService.subDetails.upcoming_invoice_preview.currency.toUpperCase())
              : (userService.subDetails.upcoming_invoice_preview.subtotal / 100 | currency: userService.subDetails.upcoming_invoice_preview.currency.toUpperCase():"symbol":"1.2-2")) +
              " " +
              (isTaxIncluded ? "including tax" : "excluding tax")
          }}
          on {{ userService.subDetails.stripe_subscription.discount.end * 1000 | date: "MMMM Do, YYYY" }}. You can cancel your subscription or upgrade to a {{ env.studioOnePlusBrandName }} Annual plan
          any time before {{ userService.subDetails.stripe_subscription.discount.end * 1000 | date: "MMMM Do, YYYY" }}. If you cancel your subscription, your access to
          {{ env.studioOnePlusBrandName }} will end on {{ userService.subDetails.stripe_subscription.discount.end * 1000 | date: "MMMM Do, YYYY" }}.

          <br />
          <br />
          <button class="btn btn-primary m-t-20 btn-sm" (click)="cancelSubscription()">Cancel Subscription</button>
        </div>

        <div class="m-t-20" *ngIf="userService.subDetails?.subscription_status == 'yearly_active_coupon'">
          Your {{ env.studioOnePlusBrandName }} Annual plan will renew on {{ userService.subDetails.subscription.end_date | date: "MMMM Do, YYYY" }} with a discount applied. You can cancel your
          subscription any time before {{ userService.subDetails.subscription.end_date | date: "MMMM Do, YYYY" }}. If you cancel your subscription, your access to {{ env.studioOnePlusBrandName }} will
          end on {{ userService.subDetails.subscription.end_date | date: "MMMM Do, YYYY" }}. Your Studio One Pro license is yours to keep.
          <br />
          <br />
          <button class="btn btn-primary m-t-20 btn-sm" (click)="cancelSubscription()">Cancel Subscription</button>
        </div>

        <!-- <div
          *ngIf="
            (!userService.subDetails.stripe_subscription && userService.subDetails.subscription.bundle_time && userService.subDetails.subscription.bundle_time > 0) ||
            (userService.subDetails.subscription_status == 'monthly_active_coupon' &&
              userService.subDetails.stripe_subscription.discount.start &&
              userService.subDetails.stripe_subscription.discount.end)
          "
        > -->
      </ng-container>
      <div *ngIf="userService.subDetails.active">
        <h2 class="section-header m-t-50">Remaining Time</h2>
        <div class="bundle-time-meter">
          <div class="d-flex">
            <div class="w-full">
              <div class="today-indicator d-flex w-full">
                <div [ngStyle]="{ width: 'calc(' + bundleTimeProgress + '% - 5px)' }"></div>
                <div>
                  <div [class.text-right]="bundleTimeProgress > 87">
                    <span class="t-12 text-muted">Today, {{ bundleDaysLeft }} days left</span>
                  </div>
                  <div class="indicator" *ngIf="bundleTimeProgress < 88"><fa-icon icon="chevron-down"></fa-icon></div>
                </div>
              </div>
            </div>
            <div style="width: 35px"></div>
          </div>
          <div class="meter-container">
            <div class="meter">
              <div
                class="total-used"
                [class.warning]="!userService.subDetails?.stripe_subscription && !userService.subDetails?.scheduled_stripe_subscription && bundleTimeProgress > 66 && bundleTimeProgress < 85"
                [class.danger]="!userService.subDetails?.stripe_subscription && !userService.subDetails?.scheduled_stripe_subscription && bundleTimeProgress > 84"
                placement="top"
                [ngStyle]="{ width: bundleTimeProgress + '%' }"
              ></div>
            </div>
            <div
              class="meter-end"
              [class.canceling]="!userService.subDetails?.scheduled_stripe_subscription && !userService.subDetails?.stripe_subscription"
              [class.scheduled]="userService.subDetails?.scheduled_stripe_subscription || userService.subDetails?.stripe_subscription"
              [ngbTooltip]="userService.subDetails?.scheduled_stripe_subscription || userService.subDetails?.stripe_subscription ? 'Your plan will continue.' : 'Your subscription will end!'"
            >
              <fa-icon [icon]="!userService.subDetails?.scheduled_stripe_subscription && !userService.subDetails?.stripe_subscription ? 'times' : 'check'"></fa-icon>
            </div>
          </div>
          <div class="d-flex justify-content-between m-t-10">
            <div class="t-12 start-end-note">
              <span class="brandfont-black">Started {{ userService.subDetails.stripe_subscription?.discount?.start * 1000 || userService.subDetails.subscription.start_date | date: "M/D/y" }}</span>
            </div>
            <div class="t-12 start-end-note">
              <span class="brandfont-black" *ngIf="userService.subDetails.subscription_status == 'monthly_active_coupon'">
                Next Paid Invoice {{ userService.subDetails.stripe_subscription.discount.end * 1000 | date: "M/D/y" }}
              </span>
              <span class="brandfont-black" *ngIf="userService.subDetails.subscription_status != 'monthly_active_coupon'">
                {{ userService.subDetails?.scheduled_stripe_subscription || userService.subDetails?.stripe_subscription ? "Next Invoice" : "Ends" }}
                {{ userService.subDetails.subscription.end_date | date: "M/D/y" }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <!-- =============END OF BUNDLE DESCRIPTORS============= -->

      <div *ngIf="userService.subDetails.subscription && statusMap[userService.subDetails.subscription.status].title == 'Canceled'" class="m-t-20">
        <span [translate]="'sphere.account.sub_ends_on_base'" [translateParams]="{ date: (userService.subDetails.subscription.end_date | date) }"></span>
        <br />
        <button class="btn btn-primary btn-sm m-t-20" (click)="reactivate()">{{ "sphere.account.reactivate_membership" | translate }}</button>
      </div>
      <div *ngIf="userService.subDetails.subscription.status == 'missed payment'" class="m-t-10">
        <myp-missed-payment-notice [missedPaymentDetails]="missedPaymentDetails"></myp-missed-payment-notice>
      </div>
    </div>

    <div>
      <myp-inline-loader [loading]="loading" height="400px" spinnerName="loadingPlan"></myp-inline-loader>
      <div class="m-t-100 text-center" *ngIf="noSubscriptionFound">
        <h2 class="section-header m-t-50">{{ "sphere.account.sub_info_not_found" | translate }}</h2>
      </div>

      <ng-container *ngIf="subscriptionProduct && subscriptionProduct.activations && subscriptionProduct.activations.length">
        <h2 class="section-header m-t-50">{{ "sphere.account.sphere_activations" | translate }}</h2>
        <div class="manage-activations" *ngFor="let act of subscriptionProduct.activations">
          <span>{{ act.comment }}</span>
          <div>
            <span class="d-inline-block m-r-10">{{ act.aquired | date }}</span>
            <button class="btn btn-danger btn-sm" [disabled]="loading" (click)="deactivate(act)">
              <span *ngIf="!act.loading">{{ "general.remove" | translate }}</span>
              <fa-icon icon="spinner" *ngIf="act.loading" [animation]="'spin'"></fa-icon>
            </button>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="subscriptionProduct && subscriptionProduct.mixerActivations && subscriptionProduct.mixerActivations.length">
        <h2 class="section-header m-t-50">{{ "sphere.account.mixer_activations" | translate }}</h2>
        <div class="manage-activations" *ngFor="let act of subscriptionProduct.mixerActivations">
          <span>{{ act.comment }}</span>
          <span>{{ act.aquired | date }}</span>
          <button class="btn btn-danger btn-sm" [disabled]="loading" (click)="deactivate(act, true)">
            <span *ngIf="!act.loading">{{ "general.remove" | translate }}</span>
            <fa-icon icon="spinner" *ngIf="act.loading" [animation]="'spin'"></fa-icon>
          </button>
        </div>
      </ng-container>

      <ng-container *ngIf="userService.subDetails && userService.subDetails.xchange_subscription">
        <h2 class="section-header m-t-50">Billing Details</h2>
        <div class="m-y-20">
          <div class="text-center">
            <p class="text-center">{{ "sphere.account.membership_title" | translate }}</p>
            <span class="brandfont-bold">{{ "sphere.account.sub_managed_third_party" | translate }}</span>
          </div>
          <div class="m-t-30 m-b-50 plan-detail-section">
            <div class="d-flex align-items-center">
              <div class="w-full t-14 bold c-gray flex-wrap m-x-5 m-y-5 text-right">{{ "sphere.account.managed_by" | translate }}</div>
              |
              <div class="w-full t-14 flex-wrap m-x-5 m-y-5 text-left">{{ userService.subDetails.xchange_subscription.Reseller_Name }}</div>
            </div>
            <div class="d-flex align-items-center">
              <div class="w-full t-14 bold c-gray flex-wrap m-x-5 m-y-5 text-right">{{ "sphere.account.start_date" | translate }}</div>
              |
              <div class="w-full t-14 flex-wrap m-x-5 m-y-5 text-left">{{ userService.subDetails.xchange_subscription.Start_Date.split("+")[0] | date }}</div>
            </div>
            <div class="d-flex align-items-center">
              <div class="w-full t-14 bold c-gray flex-wrap m-x-5 m-y-5 text-right">Next Invoice Date</div>
              |
              <div class="w-full t-14 flex-wrap m-x-5 m-y-5 text-left">{{ userService.subDetails.xchange_subscription.Next_Bill_Date.split("+")[0] | date }}</div>
            </div>
          </div>
        </div>

        <div class="text-center">
          {{ "sphere.account.to_make_changes" | translate }}
        </div>
      </ng-container>

      <ng-container *ngIf="!loading && userService.subDetails?.upcoming_invoice_preview">
        <h2 class="section-header m-t-50">Your Next Invoice</h2>

        <div class="row">
          <div class="col-4 col-lg-3">Payment Date</div>
          <div class="col-8 col-lg-9">
            {{ userService.subDetails.upcoming_invoice_preview.next_payment_attempt * 1000 | date: "MMM Do, YYYY" }}
          </div>
          <div class="col-8 col-lg-9 offset-4 offset-lg-3"><hr class="m-y-10" /></div>

          <div class="col-4 col-lg-3">Subtotal</div>
          <div class="col-8 col-lg-9">
            {{
              isNoDecimalCurrency(userService.subDetails.upcoming_invoice_preview.currency)
                ? isDivisibleBy100(userService.subDetails.upcoming_invoice_preview.currency)
                  ? (userService.subDetails.upcoming_invoice_preview.subtotal / 100 | currency: userService.subDetails.upcoming_invoice_preview.currency.toUpperCase())
                  : (userService.subDetails.upcoming_invoice_preview.subtotal | currency: userService.subDetails.upcoming_invoice_preview.currency.toUpperCase())
                : (userService.subDetails.upcoming_invoice_preview.subtotal / 100 | currency: userService.subDetails.upcoming_invoice_preview.currency.toUpperCase():"symbol":"1.2-2")
            }}
          </div>
          <div class="col-8 col-lg-9 offset-4 offset-lg-3"><hr class="m-y-10" /></div>

          <ng-container *ngIf="userService.subDetails.upcoming_invoice_preview.discount && userService.subDetails.upcoming_invoice_preview.discount.coupon?.percent_off">
            <div class="col-4 col-lg-3">Coupon Applied</div>
            <div class="col-8 col-lg-9 c-green">{{ userService.subDetails.upcoming_invoice_preview.discount.coupon.percent_off }}% Off</div>
            <div class="col-8 col-lg-9 offset-4 offset-lg-3"><hr class="m-y-10" /></div>
          </ng-container>

          <div class="col-4 col-lg-3">Total</div>
          <div class="col-8 col-lg-9">
            {{
              (isNoDecimalCurrency(userService.subDetails.upcoming_invoice_preview.currency)
                ? isDivisibleBy100(userService.subDetails.upcoming_invoice_preview.currency)
                  ? (userService.subDetails.upcoming_invoice_preview.amount_due / 100 | currency: userService.subDetails.upcoming_invoice_preview.currency.toUpperCase())
                  : (userService.subDetails.upcoming_invoice_preview.amount_due | currency: userService.subDetails.upcoming_invoice_preview.currency.toUpperCase())
                : (userService.subDetails.upcoming_invoice_preview.amount_due / 100 | currency: userService.subDetails.upcoming_invoice_preview.currency.toUpperCase():"symbol":"1.2-2")) +
                " " +
                (userService.subDetails.upcoming_invoice_preview.amount_due > 0 ? (isTaxIncluded ? "including tax" : "excluding tax") : "")
            }}
          </div>
          <div class="col-8 col-lg-9 offset-4 offset-lg-3"><hr class="m-y-10" /></div>
        </div>
      </ng-container>

      <ng-container *ngIf="!loading && userService.subDetails?.stripe_subscription?.product && statusMap[userService.subDetails?.subscription.status].title != 'Canceled'">
        <h2 class="section-header m-t-50">Payment Details</h2>
        <div class="m-t-20">
          <div class="row">
            <div class="col-4 col-lg-3">Billing Frequency</div>
            <div class="col-8 col-lg-9">
              {{ userService.subDetails.stripe_subscription.product.price.recurring["interval"] == "month" ? "Monthly" : "Annual" }}
              <ng-container
                *ngIf="statusMap[userService.subDetails.stripe_subscription.status].title != 'Canceled' && userService.subDetails.stripe_subscription.product.price.recurring['interval'] !== 'year'"
              >
                <button class="btn btn-primary btn-sm m-l-10" (click)="previewSwitchPlanPrice()">Switch to {{ env.features.hybrid_plan_messaging ? "Hybrid" : "Annual" }} Plan</button>
              </ng-container>
            </div>
            <div class="col-8 col-lg-9 offset-4 offset-lg-3"><hr class="m-y-10" /></div>
            <div class="col-4 col-lg-3">
              {{ "sphere.account.recurring_amt" | translate }}
            </div>
            <div class="col-8 col-lg-9">
              {{
                (isNoDecimalCurrency(userService.subDetails.stripe_subscription.product.price["currency"])
                  ? isDivisibleBy100(userService.subDetails.stripe_subscription.product.price["currency"])
                    ? (userService.subDetails.stripe_subscription.product.price["unit_amount"] / 100 | currency: userService.subDetails.stripe_subscription.product.price["currency"].toUpperCase())
                    : (userService.subDetails.stripe_subscription.product.price["unit_amount"] | currency: userService.subDetails.stripe_subscription.product.price["currency"].toUpperCase())
                  : (userService.subDetails.stripe_subscription.product.price["unit_amount"] / 100
                    | currency: userService.subDetails.stripe_subscription.product.price["currency"].toUpperCase():"symbol":"1.2-2")) +
                  " " +
                  (isTaxIncluded ? "including tax" : "excluding tax")
              }}
            </div>
            <div class="col-8 col-lg-9 offset-4 offset-lg-3"><hr class="m-y-10" /></div>
            <ng-container *ngIf="userService.subDetails.stripe_subscription && defaultCard">
              <div class="col-4 col-lg-3">Payment Method</div>
              <div class="col-8 col-lg-9">
                <img
                  alt="{{ defaultCard.card.brand }} card"
                  src="{{ checkoutService.cardBrandIcons[defaultCard.card.brand] || checkoutService.cardBrandIcons['generic'] }}"
                  style="width: 24px; margin-right: 10px"
                />
                Ending in: {{ defaultCard.card.last4 }}&nbsp;&nbsp;&nbsp;
              </div>
              <div class="col-8 col-lg-9 offset-4 offset-lg-3"><hr class="m-y-10" /></div>
            </ng-container>

            <ng-container *ngIf="userService.subDetails.stripe_subscription.status && statusMap[userService.subDetails.stripe_subscription.status].title != 'Canceled'">
              <div class="col-4 col-lg-3">
                {{ "sphere.account.next_charge_date" | translate }}
              </div>
              <div class="col-8 col-lg-9">
                {{ userService.subDetails.next_charge_date | date: "MMM Do, YYYY" }}
              </div>
              <div class="col-8 col-lg-9 offset-4 offset-lg-3"><hr class="m-y-10" /></div>
              <div class="col-4 col-lg-3">
                {{ "sphere.account.auto_renew" | translate }}
              </div>
              <div class="col-8 col-lg-9">
                <span class="brandfont-black">{{ userService.subDetails.stripe_subscription.collection_method !== "charge_automatically" ? "OFF" : "ON" }}</span>
              </div>
              <div class="col-8 col-lg-9 offset-4 offset-lg-3"><hr class="m-y-10" /></div>
            </ng-container>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="!loading && this.userService.paymentMethods && this.userService.paymentMethods.length">
        <h2 class="section-header m-t-50">Payment Methods</h2>
        <myp-list-item
          *ngFor="let method of this.userService.paymentMethods"
          [photo]="checkoutService.cardBrandIcons[method.card.brand] || checkoutService.cardBrandIcons.generic"
          [imageRound]="false"
          [imageBorder]="false"
          [includeChevron]="false"
          [hoverState]="false"
          [includeBottomBorder]="true"
          [title]="'Ending in ' + method.card.last4"
          [subData]="['Expires ' + method.card.exp_month + '/' + method.card.exp_year]"
        >
          <span *ngIf="method.id == defaultCard?.id" class="brandfont-black text-nowrap" tabindex="0">Active</span>
          <fa-icon
            icon="check"
            *ngIf="userService.subDetails?.subscription?.provider_subscription_id && method.id != defaultCard?.id && !paymentMethodLineItemLoaders[method.id]"
            ngbTooltip="Make Default"
            class="action circle-icon-btn m-r-5"
            (keyup.enter)="setCardAsDefault(method.id)"
            (click)="setCardAsDefault(method.id)"
            tabindex="0"
          ></fa-icon>
          <fa-icon *ngIf="paymentMethodLineItemLoaders[method.id]" icon="spinner" [animation]="'spin'"></fa-icon>
          <fa-icon
            icon="times"
            *ngIf="method.id != defaultCard?.id && !paymentMethodLineItemLoaders[method.id]"
            ngbTooltip="Delete Payment Method"
            (keyup.enter)="deletePaymentMethod(method.id)"
            (click)="deletePaymentMethod(method.id)"
            class="action circle-icon-btn"
            tabindex="0"
          ></fa-icon>
        </myp-list-item>
        <button class="brand-btn-reverse sm m-t-20" (click)="showAddCardModal()">Create New Payment Method</button>
      </ng-container>

      <ng-container *ngIf="!loading && userService.subDetails?.payment_history?.length">
        <h2 class="section-header m-t-50">{{ "sphere.account.payment_history" | translate }}</h2>

        <div class="row">
          <ng-container *ngFor="let pay of userService.subDetails.payment_history">
            <div class="col-4 col-lg-3">
              {{ pay.created * 1000 | date: "MMM Do, YYYY" }}
            </div>
            <div class="col-8 col-lg-9">
              <div class="d-flex justify-content-between">
                <div>
                  <fa-icon [icon]="pay.status == 'open' ? 'warning' : 'check-circle'" [class.c-orange]="pay.status == 'open'" [class.c-green]="pay.status == 'paid'"></fa-icon>
                  <strong>
                    {{
                      pay.total
                        ? isNoDecimalCurrency(pay.currency)
                          ? isDivisibleBy100(pay.currency)
                            ? (pay.total / 100 | currency: pay.currency.toUpperCase())
                            : ((pay.total / 100) * 100 | currency: pay.currency.toUpperCase())
                          : (pay.total / 100 | currency: pay.currency.toUpperCase():"symbol":"1.2-2")
                        : "$0.00 - "
                    }}
                  </strong>
                  {{ pay.billing_reason == "subscription_cycle" ? "Subscription Payment" : (pay.billing_reason.replace("_", " ") | titlecase) }}
                  <div class="t-12" *ngIf="pay.next_payment_attempt">
                    This payment failed. We have attempted to charge this payment {{ pay.attempt_count }} time{{ pay.attempt_count > 1 ? "s" : "" }}. We will attempt to charge your Active payment
                    method again on {{ { _seconds: pay.next_payment_attempt } | date }}.
                  </div>
                </div>
                <div *ngIf="pay.invoice_pdf">
                  <a class="small-btn text-nowrap" [href]="pay.invoice_pdf" target="_blank">
                    <fa-icon icon="external-link"></fa-icon>
                    &nbsp; View Invoice
                  </a>
                </div>
              </div>
            </div>
            <div class="col-8 col-lg-9 offset-4 offset-lg-3"><hr class="m-y-10" /></div>
          </ng-container>
        </div>
      </ng-container>

      <div class="m-t-50" *ngIf="userService.storageDetails && userService.subDetails && userService.subDetails.active">
        <h2 class="section-header m-t-50">Storage Details - {{ userService.subDetails.subscription.storage_gigabytes_included }} GB Included</h2>
        <myp-storage-meter></myp-storage-meter>
      </div>

      <div class="m-b-20 m-t-50" *ngIf="!loading">
        <button
          class="small-btn"
          *ngIf="
            userService.subDetails &&
            userService.subDetails.stripe_subscription &&
            (!userService.subDetails.stripe_subscription.status || userService.subDetails.stripe_subscription.status != 'failed') &&
            statusMap[userService.subDetails.stripe_subscription.status].title != 'Canceled' &&
            userService.subDetails.stripe_subscription.cancel_at_period_end !== true
          "
          (click)="cancelSubscription()"
        >
          {{ "sphere.account.cancel_membership" | translate }}
        </button>
        <br />
        <br />
        <a href="https://www.presonus.com/privacy-policy" target="_blank" class="tiny-link">Privacy Policy</a>
        &nbsp;|&nbsp;
        <a href="https://www.presonus.com/eula" target="_blank" class="tiny-link">EULA</a>
      </div>
    </div>
  </div>
  <ngx-spinner type="line-scale-pulse-out"></ngx-spinner>
</div>

<ng-template #rechargeConfirmAddPaymentModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-password-update">Add Payment Method</h4>
    <button ngbAutofocus class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    You are about to be redirected to a secure payment page to add a new payment method. It will list a $0 charge to verify the card. Once added, your card will be charged on
    {{ userService.subDetails.subscription.end_date | date: "M/D/y" }}.
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-primary" (click)="setupSchedule(); modal.close()">Continue</button>
    <button type="submit" class="btn btn-primary" *ngIf="userService.subDetails?.subscription?.subscription_levels_id == 1" (click)="setupSchedule(true); modal.close()">
      Upgrade to Annual for $99
    </button>
  </div>
</ng-template>
