<div class="order-item">
  <div class="header row">
    <span class="invoice-id col-md-6">{{ 'general.invoice' | translate }} #{{ order.reference_number }}</span>
    <div class="col-md-6 text-right"><a class="small-btn" [href]="order.invoice_url" target="_blank">{{ 'orders.view_invoice' | translate }}</a></div>
  </div>
  <span class="title">{{ order.title }}</span>
  <div class="order-data">
    <div *ngFor="let item of order.details">
      <myp-order-product *ngIf="productService.productsByProductID[item.product_id]" (click)="goToProduct(productService.productsByProductID[item.product_id])" [product]="productService.productsByProductID[item.product_id]"></myp-order-product>
      <div *ngIf="!productService.productsByProductID[item.product_id]" class="order-detail-item">
        <img *ngIf="item.image_url" [src]="item.image_url" />
        {{ item.name }}
      </div>
    </div>
  </div>
  <div class="text-right">{{ 'general.total' | translate }} {{order.currency}}: ${{order.amount}}</div>
</div>