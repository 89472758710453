<div class="prod-item">
  <div class="prod-img">
    <img src="{{ product.imageUrl }}" />
  </div>
  <div class="product-data">
    <span class="title">{{ product.title }}</span>
    <br />
    <span class="extra-info">{{ ("general.purchased" | translate) + ": " + product.prettyAquired }}</span>
  </div>
  <div class="buttons">
    <fa-icon icon="chevron-right" class="chevron"></fa-icon>
  </div>
</div>
