import { Component, OnInit, ElementRef, ViewChild, OnChanges, HostListener, Input, Output, EventEmitter, Inject } from "@angular/core";
import { VideoPlayerBaseComponent } from "@mypxplat/xplat/features";
import { ContentService, environment, EventBusService, HelperService, Lesson, ProductService, StorageService, UserService, Video, WindowService } from "@mypxplat/xplat/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore, AngularFirestoreCollection } from "@angular/fire/compat/firestore";
import { takeUntil } from "rxjs/operators";
import { AppService, BetaService, WebCommunityService } from "@mypxplat/xplat/web/core";
import { DOCUMENT, Location } from "@angular/common";
import firebase from "firebase/compat/app";
import { EditCommentComponent, SphereSubscribeStripeComponent } from "../modals";
import Player from "@vimeo/player";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpParams } from "@angular/common/http";
declare var YT: any;
@Component({
  selector: "myp-video-player",
  templateUrl: "video-player.component.html",
})
export class VideoPlayerComponent extends VideoPlayerBaseComponent implements OnInit, OnChanges {
  @Output() closed: EventEmitter<boolean> = new EventEmitter();

  public videoRef: AngularFirestoreCollection;
  public videoCommentsRef: AngularFirestoreCollection;
  public videoCommentsSubscription: any;
  public loadingComments: boolean = true;

  public landscape: boolean = false;
  public pinnedToBottom: boolean = false;
  public shouldShowPinOption = true;
  public comments: Array<any>;
  public commentDeleteIndex: number;

  public minimized: boolean = false;
  public dockRight: boolean = true;

  chatHeight: number = 500;

  public videoStats: any;

  public vimeoProgress: any;
  public ytApiLoaded: boolean = false;

  public selectedSection: string = "comments";

  public ytCurrentTimeInterval: any;
  public updateProgressInterval: any;
  public shouldAutoPlay: boolean = false;
  public shouldShowVideo: boolean = true;

  public lesson: Lesson;
  public lessonWatchingIndex;
  public lessonNextVideo;
  public lessonPrevVideo;

  public parentThreadMap: any = {};
  public processedCommentMap: any = {};

  public activeTime: {
    display: string;
    seconds: number;
  };

  public presonusVideo: Video;
  public env = environment;

  public playerVars: YT.PlayerVars = {};

  @ViewChild("videoOverlay", { static: false }) videoOverlay: ElementRef;
  @HostListener("document:keyup.escape", ["$event"]) onKeydownHandler(event: KeyboardEvent) {
    if (this.video) this.close();
  }
  @HostListener("window:popstate", ["$event"]) dismissModal() {
    this.close();
  }
  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.measureUI();
  }

  @ViewChild("ytPlayer", { static: false }) ytPlayer: any;

  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key == "Escape") this.close();
  }

  constructor(
    storageService: StorageService,
    userService: UserService,
    private _win: WindowService,
    public eventBusService: EventBusService,
    public db: AngularFirestore,
    public fbAuth: AngularFireAuth,
    public appService: AppService,
    public helperService: HelperService,
    public contentService: ContentService,
    public communityService: WebCommunityService,
    public activatedRoute: ActivatedRoute,
    public betaService: BetaService,
    public router: Router,
    private _location: Location,
    public productService: ProductService,
    @Inject(DOCUMENT) private document: Document
  ) {
    super(storageService, userService, eventBusService);
  }

  ngOnInit() {
    super.ngOnInit();

    this.videoInitted.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      this.shouldShowVideo = true;
      this.lesson = result.lesson;
      if (this.video.presonusVideo || this.video.presonus_id) this.presonusVideo = this.video.presonusVideo || this.contentService.videosById[this.video.presonus_id];
      if (this.lesson) {
        this.lessonWatchingIndex = 0;
        this.lesson.videos.forEach((item, index) => {
          if (item.id == this.video.presonus_id) this.lessonWatchingIndex = index;
        });
        this.lessonNextVideo = this.lesson.videos[this.lessonWatchingIndex + 1] ? this.lesson.videos[this.lessonWatchingIndex + 1] : false;
        this.lessonPrevVideo = this.lesson.videos[this.lessonWatchingIndex - 1] ? this.lesson.videos[this.lessonWatchingIndex - 1] : false;
      }

      if (this.presonusVideo) {
        this.selectedSection = "details";
      } else {
        this.selectedSection = "comments";
      }
      this.document.body.classList.add("video-open");
      const modalState = {
        modal: true,
        desc: "Fake state so we can close modal with back button",
      };
      history.pushState(modalState, null);

      this.setupComments();
      this.measureUI();
      this.shouldAutoPlay = true;
      if (this.presonusVideo && this.presonusVideo.subscription_only && !this.user.active_subscription) {
        this.shouldAutoPlay = false;
      }
      this.playerVars = {
        modestbranding: 1,
        rel: 0,
        autoplay: this.shouldAutoPlay ? 1 : 0,
      };

      if (this.presonusVideo) {
        if (this.presonusVideo.subscription_only && !this.user.active_subscription) {
          this.shouldShowVideo = false;
        }
        const params = new HttpParams().appendAll({
          viewingVideo: this.video.presonus_id,
        });
        this._location.replaceState(location.pathname, params.toString());
      }
      if (this.video.type == "vimeo" && this.shouldShowVideo) {
        this.initVimeoProgressTracker();
      } else {
        if (!this.ytApiLoaded) {
          // This code loads the IFrame Player API code asynchronously, according to the instructions at
          // https://developers.google.com/youtube/iframe_api_reference#Getting_Started
          const tag = document.createElement("script");
          tag.src = "https://www.youtube.com/iframe_api";
          document.body.appendChild(tag);
          this.ytApiLoaded = true;
        }
        if (this.shouldShowVideo) {
          this._win.setTimeout(() => {
            if (this.ytPlayer) {
              this.videoStats = this.communityService.userVideoDataMap[this.video.type + "_" + this.video.id];
              if (this.shouldAutoPlay && this.ytPlayer && this.ytPlayer._player) this.ytPlayer._player.playVideo();
              this.autoUpdateProgress();
              if (this.contentService.userLearnData.videos[this.video.presonus_id] && this.contentService.userLearnData.videos[this.video.presonus_id].watched) {
                this.ytPlayer._player.seekTo(this.contentService.userLearnData.videos[this.video.presonus_id].watched);
              }
              this.ytCurrentTimeInterval = this._win.setInterval(() => {
                if (this.ytPlayer && this.ytPlayer._player) {
                  this.activeTime = {
                    display: this.helperService.hhmmss(parseInt(this.ytPlayer._player.getCurrentTime().toFixed(0))),
                    seconds: parseInt(this.ytPlayer._player.getCurrentTime().toFixed(0)),
                  };
                } else {
                  this.activeTime = undefined;
                }
              }, 1000);
            }
          }, 2000);
        }
      }
    });
  }

  public vimeoPlayer: Player;
  initVimeoProgressTracker() {
    this.videoStats = this.communityService.userVideoDataMap[this.video.type + "_" + this.video.id];
    this._win.setTimeout(() => {
      let frame = document.getElementById("vimeo_frame");
      this.vimeoPlayer = new Player(frame);
      this.vimeoPlayer.on("timeupdate", (data) => {
        this.vimeoProgress = data;
        this.activeTime = {
          display: this.helperService.hhmmss(parseInt(this.vimeoProgress.seconds)),
          seconds: parseInt(this.vimeoProgress.seconds),
        };
      });
      this.vimeoPlayer.on("play", (data) => {
        this.autoUpdateProgress();
      });
      this.vimeoPlayer.on("pause", (data) => {
        if (this.updateProgressInterval) this._win.clearInterval(this.updateProgressInterval);
        this.updateProgress();
      });
      this.vimeoPlayer.play();
      if (this.contentService.userLearnData.videos[this.video.presonus_id] && this.contentService.userLearnData.videos[this.video.presonus_id].watched) {
        this.vimeoPlayer.setCurrentTime(this.contentService.userLearnData.videos[this.video.presonus_id].watched);
      }
    }, 1000);
  }

  showPreviousVideo() {
    let args: any = {
      id: this.lessonPrevVideo.platform_id,
      title: this.lessonPrevVideo.title,
      type: this.lessonPrevVideo.platform,
      thumb: this.lessonPrevVideo.image,
      presonus_id: this.lessonPrevVideo.id,
    };
    args.lesson = this.contentService.lessonMap[this.lesson.id];
    this.eventBusService.emit(this.eventBusService.types.playVideo, args);
  }

  goToLesson() {
    this.close();
    this.router.navigate(["/learn/lesson", this.lesson.id]);
  }

  upgrade() {
    this.close();
    this.router.navigate(["/onboarding"], { queryParams: { subscribeOnly: true } });
  }

  seekTo(seconds) {
    if (this.presonusVideo.platform == "youtube" && this.ytPlayer && this.ytPlayer._player) {
      this.ytPlayer._player.seekTo(seconds);
    } else {
      this.vimeoPlayer.setCurrentTime(seconds);
      this.vimeoPlayer.play();
    }
  }

  autoUpdateProgress() {
    if (this.updateProgressInterval) this._win.clearInterval(this.updateProgressInterval);
    this.updateProgressInterval = this._win.setInterval(() => {
      this.updateProgress(true);
    }, 5000);
  }

  updateProgress(onlyIfPlaying = false) {
    if (this.video && this.video.type == "vimeo" && this.vimeoProgress) {
      if (this.video.presonus_id) {
        this.contentService
          .saveUserVideoData({
            user_id: this.user.id,
            video_id: this.video.presonus_id,
            watched: this.vimeoProgress.seconds,
            last_watched: new Date(),
          })
          .subscribe({
            next: (result) => {},
            error: (error) => {
              this.appService.alertError(error);
            },
          });
      }
    } else if (this.video && this.video.type == "youtube" && this.ytPlayer && this.ytPlayer._player) {
      if (!onlyIfPlaying || (onlyIfPlaying && this.ytPlayer._player.getPlayerState() == 1)) {
        let args = {
          seconds: this.ytPlayer._player.getCurrentTime(),
          duration: this.ytPlayer._player.getDuration(),
          percent: (this.ytPlayer._player.getCurrentTime() * 100) / this.ytPlayer._player.getDuration(),
        };
        if (args.seconds) {
          if (this.video.presonus_id) {
            this.contentService
              .saveUserVideoData({
                user_id: this.user.id,
                video_id: this.video.presonus_id,
                watched: this.ytPlayer._player.getCurrentTime(),
                last_watched: new Date(),
              })
              .subscribe({
                next: (result) => {},
                error: (error) => {
                  this.appService.alertError(error);
                },
              });
          }
        }
      }
    }
  }

  measureUI() {
    if (this.pinnedToBottom) {
      this.shouldShowPinOption = window.innerHeight < window.innerWidth - 300;
      if (window.innerWidth < 950) {
        this.chatHeight = window.innerHeight - window.innerWidth + 100;
      } else {
        this.chatHeight = 400;
      }
    } else {
      if (window.innerHeight > window.innerWidth - 300) {
        this.landscape = false;
        if (window.innerWidth < 950) {
          this.chatHeight = window.innerHeight - window.innerWidth + 100;
        } else {
          this.chatHeight = 400;
        }
        this.shouldShowPinOption = false;
      } else {
        this.shouldShowPinOption = true;
        this.landscape = true;
        this.chatHeight = window.innerHeight - 80;
      }
    }
  }

  setupComments() {
    const go = () => {
      let conversationID = this.video.type + "_" + this.video.id;
      this.videoRef = this.db.collection("video_comments");
      this.videoRef
        .doc(conversationID)
        .ref.get()
        .then((documentSnapshot) => {
          if (!documentSnapshot.exists) {
            let video: any = {
              created: new Date(),
              video_id: this.video.id,
              type: this.video.type,
            };
            if (this.video.presonus_id) video.presonus_id = this.video.presonus_id;
            if (this.video.title) video.title = this.video.title;
            if (this.video.thumb) video.thumb = this.video.thumb;
            this.videoRef
              .doc(conversationID)
              .set(video)
              .then((doc) => {
                this.videoCommentsRef = this.videoRef.doc(conversationID).collection("comments", (ref) => {
                  return ref.orderBy("created", "desc");
                });
                this.initComments();
              });
          } else {
            this.videoCommentsRef = this.videoRef.doc(conversationID).collection("comments", (ref) => {
              return ref.orderBy("created", "desc");
            });
            this.initComments();
          }
        })
        .catch(() => {});
    };
    go();
  }

  initComments() {
    this.communityService
      .subscribeToComments(this.videoCommentsRef)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (result) => {
          this.loadingComments = false;
        },
        (error) => {
          this.loadingComments = false;
        }
      );
  }

  flagComment(msg) {
    this.videoCommentsRef.doc(msg.key).update({
      flags: firebase.firestore.FieldValue.arrayUnion(this.userService.user.id),
    });
  }

  unflagComment(msg) {
    this.videoCommentsRef.doc(msg.key).update({
      flags: firebase.firestore.FieldValue.arrayRemove(this.userService.user.id),
    });
  }

  likeComment(msg) {
    if (msg.likes && msg.likes.includes(this.user.id)) {
      this.videoCommentsRef.doc(msg.key).update({
        likes: firebase.firestore.FieldValue.arrayRemove(this.userService.user.id),
      });
    } else {
      this.videoCommentsRef.doc(msg.key).update({
        likes: firebase.firestore.FieldValue.arrayUnion(this.userService.user.id),
      });
    }
  }

  addComment(args) {
    if (!this.user.firstName) {
      alert("Your profile is incomplete, please fill out your basic profile information before posting comments.");
    } else {
      let msg: any = {
        message: args.message,
        author: {
          id: this.user.id,
          name: this.user.firstName + " " + this.user.lastName,
          photo: this.user.photoURL,
        },
        created: new Date(),
      };
      if (msg.message.indexOf("&nbsp;")) msg.message = msg.message.replace("&nbsp;", " ");
      if (args.parentID) {
        msg.parentID = args.parentID;
        if (args.parentMessage.author.id != this.user.id) {
          // dont send an email if youre replying to your own comment.
          let notifyArgs = {
            reply: msg.message,
            replyPhoto: msg.author.photo,
            replyUserName: this.user.firstName + " " + this.user.lastName,
            originalComment: args.parentMessage.message,
            originalUserID: args.parentMessage.author.id,
            videoTitle: this.video.title,
          };
          this.contentService.sendEmail(notifyArgs, "notify_comment_reply").subscribe(() => {});
        }
      }

      if (args.replyingToMessage) {
        if (args.replyingToMessage.author.id != this.user.id) {
          // dont send an email if youre replying to your own comment.
          let notifyArgs = {
            reply: msg.message,
            replyPhoto: msg.author.photo,
            replyUserName: this.user.firstName + " " + this.user.lastName,
            originalComment: args.replyingToMessage.message,
            originalUserID: args.replyingToMessage.author.id,
            videoTitle: this.video.title,
          };
          this.contentService.sendEmail(notifyArgs, "notify_comment_reply").subscribe(() => {});
        }
      }
      this.videoCommentsRef.add(msg);
    }
  }

  editComment(comment) {
    const modalRef = this.appService.showModal(EditCommentComponent, { size: "lg" }, true, true);
    modalRef.componentInstance.comment = comment;
    modalRef.result.then(
      (result) => {
        if (result) {
          let updateItem = this.comments.filter((item) => item.key == comment.key)[0];
          this.videoCommentsRef.doc(updateItem.key).update({ message: result.message, edited: true });
        }
      },
      (error) => {}
    );
  }

  deleteComment(msg) {
    if (confirm("Are you sure you want to remove this message? This cannot be undone.")) {
      this.communityService.comments.forEach((item, index) => {
        if (msg.key == item.key) this.commentDeleteIndex = index;
      });
      this.videoCommentsRef.doc(msg.key).delete();
    }
  }

  clearFlag(msg) {
    this.videoCommentsRef.doc(msg.key).update({ cleared: true });
  }

  adminDeleteComment(msg) {
    if (confirm("Are you sure you want to remove this message? This cannot be undone.")) {
      this.communityService.comments.forEach((item, index) => {
        if (msg.key == item.key) this.commentDeleteIndex = index;
      });
      this.videoCommentsRef.doc(msg.key).update({
        deleted: true,
      });
    }
  }

  ngOnChanges(changes) {
    if (changes && changes.videoID && changes.videoID.currentValue) {
      this.setupComments();
    } else if (changes && changes.vimeoID && changes.vimeoID.currentValue) {
      this.setupComments();
    } else if (changes && changes.videoURL && changes.videoURL.currentValue) {
    }
  }

  ngOnDestroy() {
    this.updateProgress();
    this.communityService.comments = [];
    if (this.ytCurrentTimeInterval) this._win.clearInterval(this.ytCurrentTimeInterval);
    if (this.updateProgressInterval) this._win.clearInterval(this.updateProgressInterval);
  }

  close() {
    this.activeTime = undefined;
    if (window.history?.state?.modal) {
      history.back();
    }
    const params = new HttpParams().appendAll({});
    this.selectedSection = "details";

    this._location.replaceState(location.pathname, params.toString());
    if (this.ytCurrentTimeInterval) this._win.clearInterval(this.ytCurrentTimeInterval);
    if (this.updateProgressInterval) this._win.clearInterval(this.updateProgressInterval);
    this.updateProgress();
    this.document.body.classList.remove("video-open");
    this.communityService.comments = [];
    this.video = undefined;
    if (this.videoCommentsSubscription && this.videoCommentsSubscription.unsubscribe) this.videoCommentsSubscription.unsubscribe();
    this.eventBusService.emit(this.eventBusService.types.videoClosed, true);
    this.closed.next(true);
  }
}
