<div class="m-b-50" *ngIf="invites && invites.length">
  <h4 class="section-header is-grid">{{ "sphere.workspaces.collaboration_invitations" | translate }}</h4>
  <span class="d-block t-14 text-left">{{ "sphere.workspaces.your_invites" | translate }}</span>
  <myp-list-item
    *ngFor="let item of invites"
    [photo]="item.workspace.image || './assets/images/sphere-default-workspace-photo.png'"
    [title]="item.workspace.name"
    [imageRound]="true"
    [subData]="[('sphere.workspaces.invited_by' | translate) + ' ' + item.workspace.owner_name + ' (' + item.workspace.owner_email + ')']"
    [includeChevron]="false"
  >
    <ng-container *ngIf="!item.loading">
      <fa-icon icon="check" placement="left" (click)="handle(true, item)" [ngbTooltip]="'sphere.workspaces.accept' | translate" class="action blue circle-icon-btn m-r-10"></fa-icon>
      <fa-icon icon="times" placement="left" (click)="handle(false, item)" [ngbTooltip]="'sphere.workspaces.reject' | translate" class="action circle-icon-btn m-r-10"></fa-icon>
    </ng-container>
    <fa-icon *ngIf="item.loading" icon="spinner" [animation]="'spin'"></fa-icon>
  </myp-list-item>
</div>
