<div class="modal-header">
  <h4 class="modal-title">Add/Edit Announcement</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <myp-inline-loader [loading]="loading" height="200px" spinnerName="addEditAnnouncementLoader"></myp-inline-loader>
  <div *ngIf="!loading">
    <myp-segmented-bar
      (selected)="preview = $event.id == 'preview' ? true : false"
      [options]="[
        {
          name: 'Edit',
          id: 'edit',
          selected: true
        },
        {
          name: 'Preview',
          id: 'preview'
        }
      ]"
    ></myp-segmented-bar>
    <ng-container *ngIf="!preview">
      <div class="form-group m-t-20">
        <label class="control-label" for="title">Title</label>
        <input type="text" name="title" id="title" [(ngModel)]="announcement.title" class="form-control" />
      </div>
      <div class="form-group m-t-20">
        <label class="control-label" for="string_id">String ID</label>
        <input type="text" name="string_id" id="string_id" [(ngModel)]="announcement.string_id" class="form-control" />
      </div>
      <div class="form-group m-t-20">
        <label class="control-label" for="description">Description</label>
        <span class="text-muted t-12 d-block m-b-5">Compose your HTML in an HTML editor an then paste it here.</span>
        <textarea type="text" name="description" id="description" style="height: 300px" [(ngModel)]="announcement.enDescription" class="form-control"></textarea>
      </div>
      <div class="form-group m-t-20">
        <label class="control-label" for="external_link">Date</label>
        <span class="text-muted t-12 d-block m-b-5">Choosing a date in the past will turn this announcement on immediately for everybody.</span>
        <div class="form-control pointer" mode="button" name="release_date" id="release_date" (click)="showDatePicker()">
          {{ date ? (date | date) : "Choose a date..." }}
        </div>
      </div>

      <div class="form-group m-t-20">
        <label class="control-label" for="product">Only show to those who own...</label>
        <span class="text-muted t-12 d-block m-b-5">Search products below, and select the ones you want to limit this announcement to. If you don't select any, it will show to everyone.</span>
        <input class="form-control" name="product" [(ngModel)]="productQuery" />
      </div>
      <div class="product-results m-b-30 trans-bg border-radius10 p-10" *ngIf="productQuery && productResults && productResults.length">
        <ng-container>
          <myp-list-item *ngFor="let product of productResults" [title]="product.title" [subData]="[product.DTYPE]" [includeChevron]="false" [showImage]="false">
            <fa-icon icon="plus" placement="left" [ngbTooltip]="'Select Product'" (click)="selectProduct(product)" class="action green circle-icon-btn m-r-10"></fa-icon>
          </myp-list-item>
        </ng-container>
      </div>
      <div class="m-y-20">
        <div class="list-tags">
          <span class="list-tag-item" *ngFor="let stringId of selectedProductStringIds">
            {{ stringId }}
            <fa-icon icon="times-circle" (click)="removeProduct(stringId)"></fa-icon>
          </span>
        </div>
      </div>
      <div class="form-group m-t-20">
        <label class="control-label" for="description">Audience</label>
        <span class="text-muted t-12">Choose where this will display.</span>
        <div class="d-flex">
          <span class="radio-option" (click)="audience.web = !audience.web" [class.selected]="audience.web">
            <fa-icon icon="check-circle" *ngIf="audience.web"></fa-icon>
            Web
          </span>
          <span class="radio-option" (click)="audience.mobile = !audience.mobile" [class.selected]="audience.mobile">
            <fa-icon icon="check-circle" *ngIf="audience.mobile"></fa-icon>
            Mobile
          </span>
        </div>
      </div>

      <myp-switch
        extraClasses="m-b-10 m-t-20"
        [active]="announcement.members_only"
        [label]="'Only show to ' + env.studioOnePlusBrandName + ' Members.'"
        [labelToRight]="true"
        (toggle)="announcement.members_only = !announcement.members_only"
      ></myp-switch>
      <myp-switch extraClasses="m-b-10 m-t-20" [active]="announcement.dev" label="Only show in development." [labelToRight]="true" (toggle)="announcement.dev = !announcement.dev"></myp-switch>
    </ng-container>
    <div class="text-center m-t-20" *ngIf="preview">
      <h2 *ngIf="announcement.title">{{ announcement.title }}</h2>
      <span class="t-14 d-block m-b-10" *ngIf="pickerDateDate">{{ pickerDateDate | date: "MMM Do" }}</span>
      <div [innerHTML]="announcement.enDescription ? (announcement.enDescription | safe: 'html') : 'No description entered yet...'"></div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" [disabled]="loading" class="btn btn-primary" (click)="submit()">
    <span>Submit</span>
  </button>
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
</div>
