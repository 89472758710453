<div class="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
  <div class="d-flex align-items-center m-b-10 p-t-5 p-x-10">
    <button type="button" class="back-btn m-r-10 p-0 border-0" (click)="goBack()" *ngIf="selectedCategory" (keyup.enter)="selectedCategory = undefined">
      <fa-icon icon="arrow-left" class="circle-icon-btn" aria-hidden="true"></fa-icon>
      <span class="sr-only">back</span>
    </button>
    <h2 class="t-20 brandfont-black text-center m-b-0" [class.small]="selectedCategory">
      {{ selectedCategory ? (selectedCategory.name == "Studio One Plus" ? "Studio One Pro+" : selectedCategory.name) : "Categories" }}
      <fa-icon *ngIf="selectedSection" icon="chevron-left" class="m-x-10"></fa-icon>
      {{ selectedSection ? "" + selectedSection.name : "" }}
    </h2>
  </div>
  <div class="m-b-20 m-x-10 form-group" *ngIf="!selectedCategory">
    <label class="control-label" for="search-knowledgebase-articles">Search knowledgebase articles</label>
    <div class="product-filter">
      <div class="filter-input no-max-width">
        <input
          id="search-knowledgebase-articles"
          type="text"
          [(ngModel)]="query"
          (keyup.enter)="search()"
          (focus)="focused = true"
          (blur)="focused = false"
          placeholder="Type to search knowledgebase articles..."
        />
        <button type="button" class="clear bg-transparent border-0 p-0" aria-label="clear search" *ngIf="query" (click)="clear()">
          <fa-icon icon="times" aria-hidden="true"></fa-icon>
          <span class="sr-only">clear search</span>
        </button>
        <div class="text-muted t-12" style="position: absolute; bottom: -20px; left: 0px" *ngIf="focused">Hit enter to search...</div>
      </div>
    </div>
  </div>
  <ul class="list-group animate-in-from-left" *ngIf="!selectedCategory && !searching && !searchResults">
    <li class="list-group-item bg-transparent border-0 p-0" *ngFor="let cat of categories">
      <div role="group" aria-label="category">
        <myp-list-item
          [subData]="['Updated: ' + (cat.updated_at | date), cat.description ? cat.description : null]"
          [title]="cat.name == 'Studio One Plus' ? 'Studio One Pro+' : cat.name"
          [photo]="
            cat.name == 'Studio One v6'
              ? 'https://studioone-content.presonusmusic.com/pictures/presonus-studio-one-6-professional-R-500x500.png?verify=1668543349-u6rlSXK7pTlzaXPTp86ijQRQxJnSVX2UXvc%2FoFi7z6Q%3D'
              : cat.image
          "
          [imageRound]="false"
          [imageBorder]="false"
          [largeImage]="true"
          (itemClicked)="selectCategory(cat)"
          [isLink]="true"
        >
          <button type="button" class="circle-icon-btn m-r-10 bg-transparent border-0 p-0" placement="left" ngbTooltip="Copy Link To This Category" (click)="copyLink(cat, $event)">
            <fa-icon icon="link" class="action" aria-hidden="true"></fa-icon>
            <span class="sr-only">Copy Link To This Category</span>
          </button>
        </myp-list-item>
      </div>
    </li>
  </ul>

  <div *ngIf="selectedCategory && !selectedSection && !searching && !searchResults" class="animate-in-from-right">
    <myp-inline-loader [loading]="loading" height="400px" spinnerName="knowledgebase-loader"></myp-inline-loader>
    <ul class="list-group animate-in-from-left" *ngIf="categorySections && categorySections.length">
      <li class="list-group-item bg-transparent border-0 p-0" *ngFor="let section of categorySections" role="link" tabindex="0">
        <div role="group" aria-label="category section">
          <myp-list-item
            [subData]="['Updated: ' + (section.updated_at | date), section.description ? section.description : null]"
            [title]="section.name"
            [showImage]="false"
            (itemClicked)="selectSection(section)"
          >
            <button type="button" class="circle-icon-btn m-r-10 bg-transparent border-0 p-0" placement="left" [ngbTooltip]="'Copy Link To This Section'" (click)="copyLink(section, $event)">
              <fa-icon icon="link" class="action" aria-hidden="true"></fa-icon>
              <span class="sr-only">Copy Link To This Section</span>
            </button>
          </myp-list-item>
        </div>
      </li>
    </ul>
  </div>

  <div *ngIf="selectedCategory && selectedSection && !searching && !searchResults" class="animate-in-from-right">
    <myp-inline-loader [loading]="loading" height="400px" spinnerName="knowledgebase-loader"></myp-inline-loader>
    <ng-container *ngIf="sectionArticles && sectionArticles.length">
      <myp-list-item
        *ngFor="let article of sectionArticles"
        [subData]="['Created: ' + (article.created_at | date), article.description ? article.description : null]"
        [title]="article.name"
        [showImage]="false"
        (itemClicked)="selectArticle(article)"
      >
        <button type="button" class="circle-icon-btn m-r-10 bg-transparent border-0 p-0" placement="left" [ngbTooltip]="'Copy Link To This Article'" (click)="copyLink(article, $event)">
          <fa-icon icon="link" class="action" aria-hidden="true"></fa-icon>
          <span class="sr-only">Copy Link To This Article</span>
        </button>
      </myp-list-item>
    </ng-container>
  </div>

  <ng-container *ngIf="searching || (searchResults && searchResults.results.length)">
    <myp-inline-loader [loading]="searching" height="400px" spinnerName="knowledgebase-loader"></myp-inline-loader>
    <ul class="list-group" *ngIf="searchResults && searchResults.results.length">
      <li class="list-group-item bg-transparent border-0 p-0" *ngFor="let article of searchResults.results">
        <div role="group" aria-label="article">
          <myp-list-item
            [subData]="['Created: ' + (article.created_at | date), article.description ? article.description : null]"
            [title]="article.name"
            [showImage]="false"
            (itemClicked)="selectArticle(article)"
          >
            <button type="button" class="circle-icon-btn m-r-10 bg-transparent border-0 p-0" placement="left" ngbTooltip="Copy Link To This Article" (click)="copyLink(article, $event)">
              <fa-icon icon="link" class="action" aria-hidden="true"></fa-icon>
              <span class="sr-only">Copy Link To This Article</span>
            </button>
          </myp-list-item>
        </div>
      </li>
    </ul>
  </ng-container>

  <p *ngIf="searchResults && !searchResults.results.length" class="text-center text-muted m-t-50">No matching articles found.</p>
  <div class="sr-only" aria-live="assertive" *ngIf="searchResults && (searchResults.results.length || !searchResults.results.length)">{{ searchResults.results.length }} records available.</div>
</div>
