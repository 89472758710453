<div class="row">
  <div class="col-md-12">
    <div class="d-flex">
      <myp-segmented-bar [white]="true" (selected)="selectSection($event.id)" [options]="sections"></myp-segmented-bar>
      <button class="fa-button m-l-10" placement="bottom" [ngbTooltip]="'Add New Product'" (click)="create()">
        <fa-icon icon="plus" class="action create-action circle-icon-btn"></fa-icon>
      </button>
    </div>

    <myp-inline-loader [loading]="loading" height="400px" spinnerName="loading"></myp-inline-loader>
    <div class="m-t-20" *ngIf="!loading && products && products.length">
      <myp-product-filter (filterChanged)="filterProducts($event)" (sortChanged)="sortProducts()" [showSortType]="false" [showListGrid]="false"></myp-product-filter>
      <div *ngFor="let product of filteredProducts" class="m-b-10 m-t-10">
        <myp-list-item
          (itemClicked)="go(product.id)"
          [metadata]="product.metadata"
          [photo]="product.imageUrl ? product.imageUrl : ''"
          [showImage]="product.imageUrl"
          [title]="product.title"
          [imageRound]="false"
        ></myp-list-item>
        <hr class="m-y-5" />
      </div>
      <!-- <ng-container *ngIf="selectedSecton == 'bundles'">
        <div *ngFor="let product of filteredProducts" class="m-b-10 m-t-10">
          <myp-list-item [routerLink]="['/products/detail', product.id]" [showImage]="false" [title]="product.title"></myp-list-item>
          <hr class="m-y-5" />
        </div>
      </ng-container> -->
    </div>
  </div>
</div>
