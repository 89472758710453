import { ErrorHandler, inject, Injectable } from "@angular/core";
import { EventBusService, LogService, UserService } from "@mypxplat/xplat/core";
import { BugsnagErrorHandler } from "@bugsnag/plugin-angular";
import Bugsnag from "@bugsnag/js";
import { Router } from "@angular/router";

@Injectable()
export class WebErrorHandler extends ErrorHandler {
  log = inject(LogService);
  router = inject(Router);
  eventBusService = inject(EventBusService);
  userService = inject(UserService)
  bugSnag: BugsnagErrorHandler;

  constructor() {
    super();
    this.bugSnag = new BugsnagErrorHandler();
  }

  handleError(error: any) {
    // Get the current route
    const currentUrl = this.router.url;

    // Check if the current route is /checkout
    if (currentUrl.startsWith("/checkout")) {
      // Optionally log the error locally, but don't report to Bugsnag
      this.log.debug("Error on /checkout route. Not reporting to Bugsnag.");
      console.log(error);
    } else {
      // Proceed with normal error handling (including Bugsnag)
      this.log.debug("error", error);

      if (error && error.status && (error.status == 403 || error.status == 401)) {
        if (error.url && error.url.indexOf("paeapi") > -1) {
          this.userService.tokenExpired$.next(true);
        }
      } else if (error.error && error.error.error) {
        const errorMsg = error.error.error;
        if (errorMsg == "Your access token is invalid or has expired.") {
          this.userService.tokenExpired$.next(true);
        }
      } else if (error.message && error.message.indexOf("ChunkLoadError") > -1) {
        this.eventBusService.emit(this.eventBusService.types.showNewCodeAlert, true);
      } else {
        // report the error to Bugsnag
        this.log.info("Submitting to Bugsnag.");
        this.bugSnag.handleError(error); // <-- Call the original BugsnagErrorHandler's handleError
      }
    }
  }

  reportError(error) {
    Bugsnag.notify(error);
  }
}
