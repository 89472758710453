import { NgModule } from "@angular/core";

// app
import { CoreModule } from "./core/core.module";
import { SharedModule } from "./features/shared/shared.module";
import { AppRoutingModule } from "./app.routing";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Amplify } from "aws-amplify";
import { environment } from "@mypxplat/xplat/core";

let searchParams = new URLSearchParams(window.location.search);
let clientId = environment.cognito.clientId;
if (searchParams.has("client_id") && environment.cognito.appClients[searchParams.get("client_id")]) clientId = environment.cognito.appClients[searchParams.get("client_id")];

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: clientId,
      userPoolId: environment.cognito.userPoolId,
    },
  },
});

@NgModule({
  imports: [CoreModule, SharedModule, AppRoutingModule, BrowserAnimationsModule],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
