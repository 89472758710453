import { Component, OnInit } from "@angular/core";
import { BaseComponent, UserService, ProductService, WindowService, environment, HelperService, CheckoutService, EventBusService, ProductLicenseItem, SimpleProduct } from "@mypxplat/xplat/core";
import { take, takeUntil } from "rxjs/operators";
import { AppService, AvoService, WebErrorHandler } from "@mypxplat/xplat/web/core";
import { UpdatePaymentSourceStripeComponent, CancelSubscriptionStripeComponent, SphereSubscribeStripeComponent, ConfirmPlanSwitchComponent, RechargeOptionsComponent } from "../../../modals";
import { ProductDetail } from "@mypxplat/xplat/core";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { CurrencyPipe } from "@angular/common";
import { addMonths } from "date-fns";
import { Router } from "@angular/router";

declare var Stripe;

@Component({
  selector: "myp-account-plan-stripe",
  templateUrl: "./plan-stripe.component.html",
})
export class PlanStripeComponent extends BaseComponent implements OnInit {
  public statusMap = {
    active: { title: "Active", color: "green" },
    canceled: { title: "Canceled", color: "red" },
    unpaid: { title: "UnPaid", color: "orange" },
    past_due: { title: "Past Due", color: "orange" },
    incomplete: { title: "Incomplete", color: "orange" },
    expired: { title: "Expired", color: "gray" },
    incomplete_expired: { title: "Incomplete And Expired", color: "red" },
    trialing: { title: "Trailing", color: "orange" },
    ended: { title: "Expired", color: "gray" },
    "missed payment": { title: "Missed Payment", color: "orange" },
  };

  public subscriptionProduct: ProductLicenseItem;
  public defaultCard: any;
  public loading: boolean = true;
  public noSubscriptionFound: boolean = false;
  public nextChargeDate: any;
  public editingExpDetails: any = false;
  public expDetails: any = {};
  public proratedTotal: any;
  public formatedProratedTotal: any;
  public prorationDate: any;
  public clientSecret: string;
  public subscriptionId: string;
  public stripe: any;
  public priceList: any;
  public isConverted: boolean = false;
  public convertedAmount: any;
  public isTaxIncluded: boolean = false;
  public monthDifference: number;
  public loadingPaymentMethod: boolean = false;
  public loadingPaymentMethodSuccess: boolean = false;
  public paymentMethodLineItemLoaders: any = {};
  public missedPaymentDetails: any;
  public hybridElligible: boolean;
  public bundleTimeProgress: any;
  public bundleDaysLeft: number;
  public today: Date;

  public isNoDecimalCurrency(currency) {
    if (currency) {
      return environment.stripe.no_decimal_currencies.includes(currency.toUpperCase());
    }
    return false;
  }

  public isDivisibleBy100(currency) {
    if (currency) {
      return environment.stripe.divisible_by_100_currencies.includes(currency.toUpperCase());
    }
    return false;
  }
  constructor(
    public userService: UserService,
    public appService: AppService,
    public spinner: NgxSpinnerService,
    public productService: ProductService,
    private _win: WindowService,
    public translate: TranslateService,
    private currencyPipe: CurrencyPipe,
    public avoService: AvoService,
    public helperService: HelperService,
    public errorService: WebErrorHandler,
    public checkoutService: CheckoutService,
    private _router: Router,
    private _eventbusService: EventBusService
  ) {
    super(userService);
  }

  ngOnInit() {
    this.userService.getStorageDetails().subscribe();
    this.stripe = Stripe(environment.stripe.public_key);
    this.loading = true;
    this.userService.subDetails$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      this.init();
    });
    this.userService.getSubscriptionDetails(true).subscribe(() => (this.loading = false));
  }

  init(callBack?) {
    this.today = new Date();
    if (this.userService.subDetails && this.userService.subDetails.active) {
      this.loading = false;
      if (this.userService.subDetails?.stripe_subscription?.default_payment_method && this.userService.paymentMethods) {
        this.defaultCard = this.userService.paymentMethods.find((p) => p.id == this.userService.subDetails.stripe_subscription.default_payment_method);
      }
      this.hybridElligible = this.userService.subDetails.subscription.subscription_levels_id == 2 && new Date(this.userService.subDetails.subscription.end_date) > new Date("2024-12-31");
      if (this.userService.subDetails.subscription) {
        if (this.userService.subDetails.subscription.status == "missed payment") {
          let missedPayment = this.userService.subDetails.payment_history.filter((p) => p.next_payment_attempt)[0];
          this.missedPaymentDetails = missedPayment;
        }
        if (this.userService.subDetails.next_charge_date) {
          this.nextChargeDate = this.userService.subDetails.next_charge_date;
          this.monthDifference = monthDiff(new Date(this.nextChargeDate), new Date());
        }
        this.isTaxIncluded = this.userService.subDetails?.stripe_customer?.metadata?.IsTaxInclusive == "true" ? true : false;

        let remaining = this.calculateFreeTimeProgress(this.userService.subDetails.subscription.start_date, this.userService.subDetails.subscription.end_date);
        if (this.userService.subDetails.subscription.bundle_time) {
          if (
            this.userService.subDetails.subscription_status == "monthly_active_coupon" &&
            this.userService.subDetails.stripe_subscription.discount.start &&
            this.userService.subDetails.stripe_subscription.discount.end
          ) {
            remaining = this.calculateFreeTimeProgress(
              new Date(this.userService.subDetails.stripe_subscription.discount.start * 1000),
              new Date(this.userService.subDetails.stripe_subscription.discount.end * 1000)
            );
          }
          this.bundleTimeProgress = remaining.percentage;
          this.bundleDaysLeft = remaining.daysLeft;
        } else {
          this.bundleTimeProgress = remaining.percentage;
          this.bundleDaysLeft = remaining.daysLeft;
        }
      }
      if (callBack) callBack();
      if (this.userService.subDetails.subscription.Asset_id) {
        this.productService.getLicenseDetails(this.userService.subDetails.subscription.Asset_id).subscribe((result: { product: SimpleProduct; license: ProductLicenseItem }) => {
          this.subscriptionProduct = result.license;
        });
      }
    }
  }

  cancelSubscription() {
    const modalRef = this.appService.showModal(CancelSubscriptionStripeComponent, { size: "lg" });
    modalRef.componentInstance.canceled.pipe(take(1)).subscribe((result) => {
      this.avoService.trackEvent().orderCanceled({
        userId_: this.userService.subDetails.subscription.Customer_id,
        prsId: this.userService.subDetails.subscription.Customer_id,
        prsSubStatus: this.userService.subDetails.subscription.status,
        subProvider: this.userService.subDetails.subscription.provider,
        subStartDate: this.userService.subDetails.subscription.start_date,
        subEndDate: this.userService.subDetails.subscription.end_date,
        subType: this.userService.subDetails.subscription.type,
        subCancelReason: result.subCancelReason,
        subCancelDetails: result.subCancelDetails,
      });
      this.spinner.show();
      this.userService.getSubscriptionDetails(true).subscribe(() => {
        this.spinner.hide();
      });
    });
  }

  upgrade() {
    this._router.navigate(["/onboarding"], { queryParams: { subscribeOnly: true } });
  }

  previewSwitchPlanPrice() {
    const modalRef = this.appService.showModal(ConfirmPlanSwitchComponent, { size: "lg" });
    modalRef.componentInstance.planSwitched.subscribe((_) => {
      this.spinner.show();
      this.userService.getSubscriptionDetails(true).subscribe(() => {
        this.spinner.hide();
      });
    });
  }

  deactivate(activation: any, mixer?) {
    this._win
      .confirm(this.translate.instant("sphere.account.remove_activation_confirm"))
      .then((_) => {
        let args: any = {
          activation_id: activation.id,
        };
        if (mixer) {
          args.hardwareSerial = activation.hardwareSerialNumber;
        } else {
          args.productid = activation.licenseId;
        }
        activation.loading = true;
        this.productService.deactivateSoftware(args).subscribe((result) => {
          this.productService.getLicenseDetails(this.userService.subDetails.subscription.Asset_id).subscribe((result: { product: SimpleProduct; license: ProductLicenseItem }) => {
            activation.loading = false;
            this.subscriptionProduct = result.license;
          });
        });
      })
      .catch((_) => {});
  }

  reactivate() {
    let reactivate = {
      subscription_id: this.userService.subDetails.stripe_subscription.id,
      source: "myp_upgrade",
    };
    this.spinner.show();
    this.userService.reactivateSubscription(reactivate).subscribe((result: any) => {
      if (result.subscriptions) {
        this.userService.getSubscriptionDetails(true).subscribe(() => {
          this.spinner.hide();
        });
      }
    });
  }

  setCardAsDefault(paymentMethodId) {
    this.paymentMethodLineItemLoaders[paymentMethodId] = true;
    this.userService.setDefaultPaymentMethod(paymentMethodId).subscribe({
      next: (result) => {
        this.userService.getSubscriptionDetails(true).subscribe(() => {
          this.spinner.hide();
          this.paymentMethodLineItemLoaders[paymentMethodId] = false;
          this.loadingPaymentMethod = false;
          this.loadingPaymentMethodSuccess = true;
          this._win.setTimeout(() => {
            this.loadingPaymentMethodSuccess = false;
          }, 2000);
        });
      },
      error: (error) => {
        this.paymentMethodLineItemLoaders[paymentMethodId] = false;
        this.loadingPaymentMethod = false;
        alert(error);
      },
    });
  }

  deletePaymentMethod(paymentMethodId) {
    if (confirm("Are you sure you want to delete this payment method?")) {
      this.paymentMethodLineItemLoaders[paymentMethodId] = true;
      this.checkoutService.deletePaymentMethod(paymentMethodId).subscribe(() => {
        this.userService.getPaymentMethods(true).subscribe((result) => {
          this.paymentMethodLineItemLoaders[paymentMethodId] = false;
        });
      });
    }
  }

  rechargePaymentOptions() {
    const modalRef = this.appService.showModal(RechargeOptionsComponent, {
      size: "xl",
      backdrop: "static",
    });
  }

  showAddCardModal(scheduleDetails?) {
    const modalRef = this.appService.showModal(UpdatePaymentSourceStripeComponent, { size: "lg" });
    modalRef.componentInstance.userCountry = this.userService.subDetails?.stripe_subscription?.metadata?.Address_Country;
    modalRef.componentInstance.isTaxInclusive = this.userService.subDetails?.stripe_subscription?.metadata?.IsTaxInclusive == "true" ? true : false;
    if (scheduleDetails) modalRef.componentInstance.scheduleDetails = scheduleDetails;
    modalRef.componentInstance.cardAdded.pipe(take(1)).subscribe((result) => {
      this.userService.getSubscriptionDetails(true).subscribe((result) => {});
    });
  }

  setupSchedule() {
    let sixMonthsLater = addMonths(new Date(this.userService.subDetails.subscription.end_date), 6);
    let details = {
      time: this.userService.subDetails.subscription.bundle_time,
      subscription_start_date: this.userService.subDetails.subscription.end_date,
      annual_upgrade_subscription_start_date: sixMonthsLater,
    };
    this.checkoutService.scheduleDetails = details;
    if (this.userService.subDetails?.subscription?.subscription_levels_id == 2) this.checkoutService.selectedS1PlusPlan = "annual";
    this.appService.goToCheckout("/checkout?showScheduleOptions=true");
  }

  calculateFreeTimeProgress(startDateStr, endDateStr) {
    // Parse the dates from strings to Date objects
    const startDate: any = new Date(startDateStr);
    const endDate: any = new Date(endDateStr);
    const currentDate: any = new Date();

    // Calculate the total duration and the elapsed duration in milliseconds
    const totalDuration = endDate - startDate;
    const elapsedDuration = currentDate - startDate;

    // Calculate the remaining duration in milliseconds
    const remainingDuration = endDate - currentDate;

    // Convert the remaining duration from milliseconds to days
    const daysLeft = remainingDuration / (1000 * 60 * 60 * 24);

    // Calculate the percentage
    const progressPercentage = (elapsedDuration / totalDuration) * 100;

    return {
      percentage: progressPercentage.toFixed(2), // Returns the percentage rounded to two decimal places
      daysLeft: Math.ceil(daysLeft), // Rounds up to the nearest whole number to ensure a whole day is counted
    };
  }
}

function monthDiff(startingDate, endingDate) {
  let startDate = new Date(new Date(startingDate).toISOString().substr(0, 10));
  if (!endingDate) {
    endingDate = new Date().toISOString().substr(0, 10); // need date in YYYY-MM-DD format
  }
  let endDate = new Date(endingDate);
  if (startDate > endDate) {
    const swap = startDate;
    startDate = endDate;
    endDate = swap;
  }
  const startYear = startDate.getFullYear();
  const february = (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0 ? 29 : 28;
  const daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  let monthDiff = endDate.getMonth() - startDate.getMonth();
  if (monthDiff < 0) {
    monthDiff += 12;
  }
  let dayDiff = endDate.getDate() - startDate.getDate();
  if (dayDiff < 0) {
    if (monthDiff > 0) {
      monthDiff--;
    } else {
      monthDiff = 11;
    }
    dayDiff += daysInMonth[startDate.getMonth()];
  }

  return monthDiff;
}
