<div class="modal-header">
  <h4 class="modal-title">Products You Can Redeem</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <myp-inline-loader [loading]="redeeming" height="400px" spinnerName="redeemingProducts"></myp-inline-loader>
  <ng-container *ngIf="!redeeming && !redeemed">
    You are elligible to redeem the following products at no charge!
    <div class="bold m-y-20" *ngFor="let prod of productService.redeemableProducts">{{ prod.title }}</div>
  </ng-container>
  <ng-container *ngIf="redeemed">
    <div class="text-center t-16 m-y-20">
      <fa-icon icon="check-circle" style="color: green; font-size: 65px"></fa-icon>
      <br />
      <br />
      You successfully redeemed your products! They will now appear in your products list.
      <br />
      <br />
      <button class="btn btn-primary" (click)="goToProducts()">Go To Products</button>
    </div>
  </ng-container>
</div>
<div class="modal-footer" *ngIf="!redeeming && !redeemed">
  <button type="button" class="btn btn-primary" (click)="redeemAll()">{{ redeeming ? "Redeeming..." : "Redeem Products" }}</button>
  <button type="button" class="btn btn-blank" (click)="activeModal.close(false)">Close</button>
</div>
