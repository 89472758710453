<ng-container *ngIf="!addingNewCard">
  <div class="container-fluid row" *ngIf="checkoutService.loadingPaymentMethods || (userService.paymentMethods && userService.paymentMethods.length)">
    <div *ngIf="checkoutService.loadingPaymentMethods" class="col-12 brandfont-bold">
      <fa-icon icon="spinner" [animation]="'spin'"></fa-icon>
      Loading your saved payment methods...
    </div>
    <div class="col-12 m-t-30" *ngIf="!checkoutService.loadingPaymentMethods && userService.paymentMethods && userService.paymentMethods.length">
      <span class="brandfont-bold d-block m-b-30">Choose your preferred payment method.</span>
      <div class="flex">
        <div
          class="m-y-10 d-flex justify-content-left pointer"
          style="border-bottom: 1px solid rgba(255, 255, 255, 0.1)"
          *ngFor="let payment of userService.paymentMethods"
          (click)="checkoutService.selectPaymentMethod(payment)"
        >
          <span class="radio-option">
            <fa-icon
              [icon]="checkoutService.selectedExistingPaymentMethod == payment.id ? 'check-circle' : 'circle'"
              [class.c-green]="checkoutService.selectedExistingPaymentMethod == payment.id"
            ></fa-icon>
            &nbsp;
            <span>{{ (payment.card.brand | titlecase) + " | **** **** **** " + payment.card.last4 }}</span>
          </span>
        </div>
        <div class="d-flex justify-content-left pointer m-t-20" *ngIf="!checkoutService.addNewCard" (click)="checkoutService.addNewPaymentMethod()">
          <span class="d-block link t-12 bold m-t-10">{{ "Add a new card" }}</span>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<div class="container-fluid row" *ngIf="!checkoutService.selectedExistingPaymentMethod || checkoutService.addNewCard">
  <div class="col-12 m-b-20 d-flex justify-content-between">
    <h2 class="brandfont-bold mb-0 font-h6" id="payment_details">{{ "onboarding.enter_payment_details" | translate }}</h2>
    <span class="small font-italic">
      <sup><code>*</code></sup>
      indicates mandatory fields.
    </span>
  </div>
</div>

<div class="credit-card-info container-fluid" [hidden]="checkoutService.selectedExistingPaymentMethod && !checkoutService.addNewCard">
  <div class="row">
    <div class="form-group col-sm-6 col-md-6">
      <label class="control-label" for="firstName">
        {{ "profile.edit.first_name" | translate }}
        <sup><code>*</code></sup>
      </label>
      <input
        type="text"
        (blur)="validateForm(); checkoutService.onBillingInfoChange('firstName', $event.target.value)"
        id="firstName"
        name="firstName"
        data-cy="payment-first-name-input"
        class="form-control"
        [(ngModel)]="checkoutService.billingInfo.firstName"
        autocomplete="given-name"
        required
      />
    </div>
    <div class="form-group col-sm-6 col-md-6">
      <label class="control-label" for="lastName">
        {{ "profile.edit.last_name" | translate }}
        <sup><code>*</code></sup>
      </label>
      <input
        type="text"
        name="lastName"
        (blur)="validateForm(); checkoutService.onBillingInfoChange('lastName', $event.target.value)"
        id="lastName"
        data-cy="payment-last-name-input"
        class="form-control"
        [(ngModel)]="checkoutService.billingInfo.lastName"
        autocomplete="family-name"
        required
      />
    </div>
    <div class="form-group col-sm-6 col-md-6">
      <label class="control-label" for="address">
        {{ "profile.edit.address" | translate }}
        <sup><code>*</code></sup>
      </label>
      <input
        (blur)="validateForm(); checkoutService.onBillingInfoChange('address', $event.target.value)"
        id="address"
        name="address"
        data-cy="payment-address-input"
        type="address"
        class="form-control"
        [(ngModel)]="checkoutService.billingInfo.address"
        autocomplete="street-address"
        required
      />
    </div>
    <div class="form-group col-sm-6 col-md-6">
      <label class="control-label" for="city">
        {{ "profile.edit.city" | translate }}
        <sup><code>*</code></sup>
      </label>
      <input
        (blur)="validateForm(); checkoutService.onBillingInfoChange('city', $event.target.value)"
        id="city"
        name="city"
        data-cy="payment-city-input"
        type="address"
        class="form-control"
        [(ngModel)]="checkoutService.billingInfo.city"
        autocomplete="address-level2"
        required
      />
    </div>
    <div class="form-group col-sm-6 col-md-6">
      <label class="control-label" for="country">
        {{ "profile.edit.country" | translate }}
        <sup><code>*</code></sup>
        <fa-icon icon="spinner" *ngIf="loadingCountries" [animation]="'spin'"></fa-icon>
      </label>
      <select
        id="country"
        data-cy="payment-country-select"
        name="country"
        (ngModelChange)="checkoutService.onBillingInfoChange('country', $event)"
        (blur)="validateForm()"
        class="form-control"
        [(ngModel)]="checkoutService.billingInfo.country"
        autocomplete="country-name"
        required
      >
        <option *ngFor="let country of countries" [ngValue]="country.code">{{ country.title }}</option>
      </select>
    </div>
    <div class="form-group col-sm-6 col-md-6">
      <ng-container
        *ngIf="checkoutService.billingInfo.country && (checkoutService.billingInfo.country == 'US' || checkoutService.billingInfo.country == 'CA' || checkoutService.billingInfo.country == 'IN')"
      >
        <label class="control-label" for="state">
          {{ "profile.edit.state" | translate }}
          <sup><code>*</code></sup>
          <fa-icon icon="spinner" *ngIf="loadingStates" [animation]="'spin'"></fa-icon>
        </label>
        <select
          id="state"
          (ngModelChange)="checkoutService.onBillingInfoChange('state', $event)"
          data-cy="payment-state-select"
          name="state"
          (blur)="validateForm()"
          class="form-control"
          [(ngModel)]="checkoutService.billingInfo.state"
          autocomplete="address-level1"
          required
        >
          <option value="">Select your state</option>
          <option *ngFor="let state of checkoutService.billingInfo.country == 'US' ? states : checkoutService.billingInfo.country == 'CA' ? canadianProvinces : indianStates" [ngValue]="state.code">
            {{ state.title }}
          </option>
        </select>
      </ng-container>
      <ng-container
        *ngIf="!(checkoutService.billingInfo.country && (checkoutService.billingInfo.country == 'US' || checkoutService.billingInfo.country == 'CA' || checkoutService.billingInfo.country == 'IN'))"
      >
        <label class="control-label" for="state">
          {{ "profile.edit.state" | translate }}
          <sup><code>*</code></sup>
        </label>
        <input
          id="state"
          name="state"
          data-cy="payment-state-input"
          (blur)="validateForm(); checkoutService.onBillingInfoChange('state', $event.target.value)"
          type="address"
          class="form-control"
          [(ngModel)]="checkoutService.billingInfo.state"
          autocomplete="address-level1"
          required
        />
      </ng-container>
    </div>
    <div class="form-group col-sm-6 col-md-6">
      <label class="control-label" for="zip">
        {{ "profile.edit.zip" | translate }}
        <sup><code>*</code></sup>
      </label>
      <input
        id="zip"
        name="zip"
        data-cy="payment-zip-input"
        (blur)="validateForm(); checkoutService.onBillingInfoChange('zip', $event.target.value)"
        class="form-control"
        [(ngModel)]="checkoutService.billingInfo.zip"
        autocomplete="postal-code"
      />
    </div>
  </div>

  <div class="row" data-cy="stripe-elements-form">
    <div class="form-group col-sm-6 col-md-6">
      <label class="control-label">
        {{ "sphere.subscription_details.cc_no" | translate }}
        <sup><code>*</code></sup>
        &nbsp;&nbsp;&nbsp;
        <fa-icon icon="spinner" *ngIf="loadingStripeElements" [animation]="'spin'"></fa-icon>
      </label>
      <div #cardNumber id="sub_cardNumber" class="form-control p-10">
        <!-- A Stripe Element will be inserted here. -->
      </div>
    </div>
    <div class="form-group col-sm-6 col-md-6">
      <label class="control-label">
        {{ "sphere.subscription_details.exp" | translate }}
        <sup><code>*</code></sup>
        &nbsp;&nbsp;&nbsp;
        <fa-icon icon="spinner" *ngIf="loadingStripeElements" [animation]="'spin'"></fa-icon>
      </label>
      <div #cardExpiry id="sub_cardExpiry" class="form-control p-10">
        <!-- A Stripe Element will be inserted here. -->
      </div>
    </div>

    <div class="form-group col-sm-6 col-md-6">
      <label class="control-label">
        {{ "sphere.subscription_details.card_security_code" | translate }}
        <sup><code>*</code></sup>
        &nbsp;&nbsp;&nbsp;
        <fa-icon icon="spinner" *ngIf="loadingStripeElements" [animation]="'spin'"></fa-icon>
      </label>
      <div #cardCVC id="sub_cardCVC" class="form-control p-10">
        <!-- A Stripe Element will be inserted here. -->
      </div>
    </div>
  </div>

  <div class="row" *ngIf="checkoutService.checkoutError">
    <div id="onboard-error-id" class="onboard-error m-y-20 t-18 col-12" *ngIf="checkoutService.checkoutError">
      <fa-icon icon="times-circle" class="pointer" (click)="checkoutService.checkoutError = undefined"></fa-icon>
      {{ checkoutService.checkoutError.message }}

      <span class="sr-only" aria-live="assertive">{{ checkoutService.checkoutError.message }}</span>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="row" *ngIf="showCouponButton && !checkoutService.loadingCheckout">
    <div class="col-6">
      <ng-container *ngIf="!couponData">
        <button
          class="d-block link m-t-10 t-12 bold p-0"
          data-cy="coupon-link"
          (keyup.enter)="showCouponField = !showCouponField"
          (keyup.space)="showCouponField = !showCouponField"
          (click)="showCouponField = !showCouponField"
          [attr.aria-controls]="coupon_div"
          [attr.aria-expanded]="showCouponField"
        >
          {{ "onboarding.enter_coupon" | translate }}
        </button>
        <div *ngIf="showCouponField" id="coupon_div" class="form-group">
          <label class="control-label">Promo code</label>
          <input
            id="coupon"
            data-cy="coupon-input"
            [disabled]="checkoutService.applyingCoupon"
            (keyup.enter)="checkoutService.applyCoupon()"
            class="form-control mono text-sm cloud-product-key m-t-5"
            placeholder="Enter your promo code..."
            [(ngModel)]="checkoutService.coupon"
            aria-describedby="onboard-error-id"
          />
          <button class="brand-btn-reverse sm m-t-20" [disabled]="checkoutService.applyingCoupon" data-cy="apply-coupon-button" (click)="checkoutService.applyCoupon()">
            {{ "onboarding.apply_coupon" | translate }}
            <fa-icon icon="spinner" *ngIf="checkoutService.applyingCoupon" [animation]="'spin'"></fa-icon>
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</div>
