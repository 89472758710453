<div
  class="list-item hover dontgrow"
  [class.hover]="hoverState"
  [class.bottom-border]="includeBottomBorder"
  (keydown.enter)="itemClick($event); $event.stopPropagation()"
  (keydown.space)="itemClick($event); $event.stopPropagation()"
  (click)="itemClick($event); $event.stopPropagation()"
>
  <div class="list-item-container" [class.align-top]="alignTop">
    <img
      [src]="photo ? photo : './assets/images/default_avatar.jpg'"
      *ngIf="showImage"
      [ngStyle]="{ opacity: inactive ? '.3' : '1' }"
      class="item-image"
      [class.large]="largeImage"
      [class.round]="imageRound"
      [class.image-border]="imageBorder"
      alt=""
    />
    <fa-icon *ngIf="icon" class="list-item-icon" [icon]="icon"></fa-icon>
    <div class="main-data" (click)="itemClick($event); $event.stopPropagation()" tabindex="0" [attr.role]="isLink ? 'link' : 'button'">
      <h3 class="title">{{ title }}</h3>

      <div class="sub-data" *ngIf="subDataHtml">
        <ng-container [ngTemplateOutlet]="subDataHtml"></ng-container>
      </div>

      <span class="sub-data" *ngIf="subData && subData.length">
        <div class="muted" *ngFor="let data of subData" [innerHTML]="data"></div>
      </span>
      <div class="metadata" *ngIf="metadata && metadata.length">
        <div class="tag" *ngFor="let data of metadata">
          <span class="title">{{ data.title }}</span>
          <span class="value">{{ data.value }}</span>
        </div>
      </div>
    </div>
    <div class="right-data-buttons">
      <ng-content></ng-content>
      <fa-icon aria-label="Select" icon="chevron-right" class="m-l-5" *ngIf="includeChevron" aria-hidden="true"></fa-icon>
    </div>
  </div>
</div>
