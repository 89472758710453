<div class="modal-header">
  <h4 class="modal-title">Edit Video</h4>

  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body edit-video" ngbAutofocus *ngIf="video">
  <div style="height: 100%; width: 100%">
    <div class="responsive-video-container m-b-30">
      <iframe
        *ngIf="video.platform == 'youtube'"
        [src]="url | safe"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <iframe *ngIf="video.platform == 'vimeo'" [src]="url | safe" id="vimeo_frame" frameborder="0" allow="fullscreen" allowfullscreen></iframe>
    </div>
  </div>

  <div class="form-group">
    <small class="form-text text-muted">Status</small>
    <select class="form-control" [(ngModel)]="video.status">
      <option value="approved">Approved</option>
      <option value="pending">Pending</option>
      <option value="ignored">Ignored</option>
    </select>
    <small class="form-text text-muted">Videos will not display at all for customers unless it is Approved.</small>
  </div>

  <myp-segmented-bar
    [white]="true"
    (selected)="selectedSection = $event.id"
    [options]="[
      {
        name: 'Video Details',
        id: 'details',
        selected: true
      },
      {
        name: 'Tags',
        id: 'tags'
      },
      {
        name: 'Associated Products',
        id: 'products'
      }
    ]"
  ></myp-segmented-bar>

  <div class="m-t-20" *ngIf="selectedSection == 'details'">
    <div class="form-group">
      <small class="form-text text-muted">Title*</small>
      <input class="form-control" [(ngModel)]="video.title" />
    </div>
    <div class="form-group">
      <small class="form-text text-muted">Description*</small>
      <textarea type="text" name="description" style="height: 120px" id="description" [(ngModel)]="video.description" class="form-control"></textarea>
    </div>
    <div class="form-group">
      <small class="form-text text-muted">Snippet*</small>
      <textarea type="text" name="snippet" style="height: 60px" id="snippet" [(ngModel)]="video.snippet" class="form-control"></textarea>
    </div>
    <div class="row">
      <div class="form-group col-md-6">
        <small class="form-text text-muted">Level</small>
        <select class="form-control" [(ngModel)]="videoLevel">
          <option value="-1">Not Set</option>
          <option value="0">Easy</option>
          <option value="1">Intermediate</option>
          <option value="2">Difficult</option>
          <option value="3">Expert</option>
        </select>
      </div>
      <div class="form-group col-md-6">
        <small class="form-text text-muted">Duration* (in seconds)</small>
        <input class="form-control" [(ngModel)]="video.duration" />
      </div>
    </div>

    <div class="row">
      <div class="form-check text-left col-md-6">
        <small class="form-text d-none d-md-block text-muted">&nbsp;</small>
        <div class="m-t-5">
          <input class="m-r-10" type="checkbox" [(ngModel)]="video.subscription_only" id="subscription_only" />
          <label class="form-check-label" for="subscription_only">Only allow subscribers to watch.</label>
        </div>
      </div>
    </div>

    <form class="row row-cols-sm-auto">
      <div class="col-12 col-sm-4">
        <small class="form-text text-muted">Date Added</small>
        <div class="input-group">
          <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="createdDate" ngbDatepicker #d="ngbDatepicker" />
          <fa-icon icon="calendar" class="btn btn-outline-secondary calendar" (click)="d.toggle()"></fa-icon>
        </div>
        <small class="form-text text-muted">Changes the video's displayed release date and sort order.</small>
      </div>
      <div class="col-12 col-sm-4">
        <small class="form-text text-muted">Start Date</small>
        <div class="input-group">
          <input class="form-control" placeholder="yyyy-mm-dd" name="start" [(ngModel)]="startDate" ngbDatepicker #s="ngbDatepicker" />
          <fa-icon icon="calendar" class="btn btn-outline-secondary calendar" (click)="s.toggle()"></fa-icon>
        </div>
        <small class="form-text text-muted">Video will only display for users after this date. Leave empty to display immediately.</small>
      </div>
      <div class="col-12 col-sm-4">
        <small class="form-text text-muted">End Date</small>
        <div class="input-group">
          <input class="form-control" placeholder="yyyy-mm-dd" name="end" [(ngModel)]="endDate" ngbDatepicker #e="ngbDatepicker" />
          <fa-icon icon="calendar" class="btn btn-outline-secondary calendar" (click)="e.toggle()"></fa-icon>
        </div>
        <small class="form-text text-muted">Video will be removed from display after this date. Leave empty to leave up forever.</small>
      </div>
    </form>

    <ng-container *ngIf="lessons && lessons.length">
      <hr />
      <span class="brandfont-bold">Lessons containing this video...</span>
      <myp-learn-list-item *ngFor="let lesson of lessons" [large]="false" [item]="lesson" [showCopyLink]="false" [admin]="true" (itemClickedEvent)="learnGroupClick($event)"></myp-learn-list-item>
    </ng-container>

    <ng-container *ngIf="folders && folders.length">
      <hr />
      <span class="brandfont-bold">Folders containing this video...</span>
      <myp-learn-list-item *ngFor="let folder of folders" [item]="folder" [large]="false" [showCopyLink]="false" [admin]="true" (itemClickedEvent)="learnGroupClick($event)"></myp-learn-list-item>
    </ng-container>
  </div>
  <div class="m-t-20" *ngIf="selectedSection == 'tags'">
    <span class="text-center m-b-20 d-block" *ngIf="!video.tags || !video.tags.length">No tags have been selected for this video.</span>

    <div class="form-group">
      <small class="form-text text-muted">Start typing to add tags...</small>
      <input class="form-control" [(ngModel)]="tagQuery" (keyup.enter)="createTagIfNotExist()" />
    </div>
    <div class="tag-results" *ngIf="tagQuery && tagResults && tagResults.length">
      <ng-container *ngFor="let tag of tagResults">
        <span class="add-tag-btn m-r-5" (click)="associateTag(tag)">
          <fa-icon icon="plus-circle" class="c-green t-14"></fa-icon>
          {{ tag.title }}
        </span>
      </ng-container>
    </div>
    <div class="tag-results" *ngIf="tagQuery && tagResults && !tagResults.length">
      No matching tag found.
      <span class="small-btn blue" (click)="createTag(tagQuery)">Add "{{ tagQuery }}"</span>
    </div>

    <div class="video-tags m-t-10">
      <span *ngFor="let tag of video.tags" class="associated-tag">
        {{ tag.title }}
        <fa-icon icon="times-circle" class="m-l-5" (click)="removeTag(tag)"></fa-icon>
      </span>
    </div>
  </div>

  <div class="m-t-20" *ngIf="selectedSection == 'products'">
    <h4 class="section-header m-b-20">
      Associated Products
      <span class="section-header-link" (click)="associatedProductsCollapsed = !associatedProductsCollapsed">
        {{ associatedProductsCollapsed ? "Expand" : "Collapse" }}
        <fa-icon [icon]="'chevron-' + (associatedProductsCollapsed ? 'up' : 'down')"></fa-icon>
      </span>
    </h4>
    <ng-container *ngIf="!associatedProductsCollapsed">
      <div class="form-group">
        <small class="form-text text-muted">Start typing to associate this video with a product...</small>
        <input class="form-control" [(ngModel)]="productQuery" />
      </div>
      <div class="product-results m-b-30 trans-bg border-radius10 p-10" *ngIf="productQuery && productResults && productResults.length">
        <ng-container>
          <myp-list-item *ngFor="let product of productResults" [title]="product.title" [subData]="[product.DTYPE]" [includeChevron]="false" [showImage]="false">
            <fa-icon icon="plus" placement="left" [ngbTooltip]="'Associate This Product'" (click)="associateProduct(product)" class="action green circle-icon-btn m-r-10"></fa-icon>
          </myp-list-item>
        </ng-container>
      </div>

      <div class="product-results" *ngIf="video.products && video.products.length">
        <span class="t-14 gothic">Products Associated With This Video</span>
        <ng-container>
          <myp-list-item *ngFor="let product of video.products" [title]="product.title" [subData]="[product.DTYPE]" [includeChevron]="false" [showImage]="false">
            <fa-icon icon="minus" placement="left" [ngbTooltip]="'Remove This Product'" (click)="removeProductAssociation(product)" class="action red circle-icon-btn m-r-10"></fa-icon>
          </myp-list-item>
        </ng-container>
      </div>
    </ng-container>

    <h4 class="section-header m-b-20 m-t-50">
      Set as Flagship Video
      <span class="section-header-link" (click)="flagshipProductsCollapsed = !flagshipProductsCollapsed">
        {{ associatedProductsCollapsed ? "Expand" : "Collapse" }}
        <fa-icon [icon]="'chevron-' + (flagshipProductsCollapsed ? 'up' : 'down')"></fa-icon>
      </span>
    </h4>
    Find a product below to set this video as that product's Flagship Video. This video will be displayed prominently in the product detail page.

    <ng-container *ngIf="!flagshipProductsCollapsed">
      <div class="form-group">
        <small class="form-text text-muted">Start typing to search products...</small>
        <input class="form-control" [(ngModel)]="flagshipProductQuery" />
      </div>
      <div class="product-results m-b-30 trans-bg border-radius10 p-10" *ngIf="flagshipProductQuery && flagshipProductResults && flagshipProductResults.length">
        <ng-container>
          <myp-list-item *ngFor="let product of flagshipProductResults" [title]="product.title" [subData]="[product.DTYPE]" [includeChevron]="false" [showImage]="false">
            <fa-icon
              icon="plus"
              placement="left"
              [ngbTooltip]="'Set This Video as the Flagship Video For This Product'"
              (click)="setAsFlagship(product)"
              class="action green circle-icon-btn m-r-10"
            ></fa-icon>
          </myp-list-item>
        </ng-container>
      </div>
    </ng-container>

    <div class="product-results" *ngIf="video.flagship_products && video.flagship_products.length">
      <span class="t-14 gothic">This video is the Flagship Video for the following products</span>
      <ng-container>
        <myp-list-item *ngFor="let product of video.flagship_products" [title]="product.title" [subData]="[product.DTYPE]" [includeChevron]="false" [showImage]="false">
          <fa-icon icon="minus" placement="left" [ngbTooltip]="'Remove This Product'" (click)="unsetAsFlagship(product)" class="action red circle-icon-btn m-r-10"></fa-icon>
        </myp-list-item>
      </ng-container>
    </div>
  </div>
</div>

<div class="modal-footer d-flex justify-content-between">
  <div>
    <button *ngIf="video.id" type="button" class="btn btn-danger" (click)="delete()">{{ "general.delete" | translate }}</button>
  </div>
  <div>
    <button type="button" class="btn btn-primary" (click)="save()">Save</button>
    <button type="button" class="btn btn-blank m-r-5" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
  </div>
</div>
