import { Component, EventEmitter, Input, Output, OnInit } from "@angular/core";

import { AdminService, BaseComponent, ProductService, UserService } from "@mypxplat/xplat/core";
import { AppService, BetaService } from "@mypxplat/xplat/web/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { DatePickerComponent } from "../date-picker/date-picker.component";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { interval, skip, Subject, takeUntil, throttle } from "rxjs";
import { GenericAlertComponent } from "../generic-alert/generic-alert.component";
import { Router } from "@angular/router";

@Component({
  selector: "myp-add-edit-beta-program",
  templateUrl: "add-edit-beta-program.component.html",
})
export class AddEditBetaProgramComponent extends BaseComponent implements OnInit {
  private _productQuery: string;
  private _productQueryStream$: Subject<any> = new Subject();
  set productQuery(query) {
    this._productQuery = query;
    if (this._productQuery) {
      this._productQueryStream$.next(query);
    } else {
      this.productResults = undefined;
    }
  }

  get productQuery() {
    return this._productQuery;
  }

  @Input() program: any = {};
  public startDate: any;
  public loading: boolean = false;
  public endDate: any;
  @Output() programAdded: EventEmitter<any> = new EventEmitter();
  public groups: Array<any>;
  public groupMap: any = {};
  public betaTags: Array<any>;
  public betaTagMap: any = {};
  public betaTagName: string;
  public creatingTag: boolean = false;
  public productResults: Array<any> = [];
  public associatedProducts: Array<any> = [];
  public associatedProductsMap = {};
  public members: Array<any>;
  public programGroups: Array<any> = [];
  constructor(
    userService: UserService,
    public activeModal: NgbActiveModal,
    public betaService: BetaService,
    public adminService: AdminService,
    public appService: AppService,
    public productService: ProductService,
    public db: AngularFirestore,
    public router: Router
  ) {
    super(userService);
  }

  ngOnInit() {
    this.loading = true;
    if (this.program.id) {
      this.initTags();
      if (this.program.products?.length) {
        this.associatedProducts = this.program.products;
        this.associatedProducts.forEach((product) => {
          this.associatedProductsMap[product.id] = product;
        });
      }
    }
    this.adminService.getBetaMembers(this.program.id).subscribe((result: any) => {
      this.members = result;
      this.members.sort((a, b) => {
        return a.lastName.localeCompare(b.lastName);
      });
    });
    this.adminService.get("groups").subscribe((result: any) => {
      this.groups = result;
      let programGroupIds = this.program.group_ids?.split(",");
      this.programGroups = this.groups.filter((group) => programGroupIds?.includes(group.id.toString()));
      this.groups.forEach((group) => {
        this.groupMap[group.id] = group;
      });
      if (this.program.group_ids) {
        let groupIds = this.program.group_ids.split(",");
        groupIds.forEach((groupId) => {
          this.groupMap[groupId].selected = true;
        });
      }
      this.loading = false;
    });
    this._productQueryStream$
      .pipe(
        skip(1),
        throttle(
          () => {
            return interval(1000);
          },
          { leading: true, trailing: true }
        ),
        takeUntil(this.destroy$)
      )
      .subscribe((result) => {
        this.productAutocomplete();
      });
  }

  productAutocomplete() {
    if (this.productQuery) {
      this.productService.search(this.productQuery).subscribe((result: Array<any>) => {
        this.productResults = result;
      });
    } else {
      this.productResults = [];
    }
  }

  associateProduct(product) {
    if (!this.associatedProductsMap[product.id]) {
      this.associatedProducts.push(product);
      this.associatedProductsMap[product.id] = product;
    }
  }

  removeProductAssociation(product) {
    this.associatedProducts = this.associatedProducts.filter((p) => p.id !== product.id);
    delete this.associatedProductsMap[product.id];
  }

  initTags() {
    this.betaService.getBetaCommunityTags(this.program.string_id).subscribe((result) => {
      this.creatingTag = false;
      this.betaTags = result;
      this.betaTags.forEach((tag) => {
        this.betaTagMap[tag.id] = tag;
      });
    });
  }

  removeBetaMember(userId) {
    let user = this.members.find((m) => m.user_id === userId);
    if (
      confirm(
        `Are you sure you want to remove ${user.firstName + " " + user.lastName} from the beta program? This will remove them from the beta, but if they are still in a group that is elligible to join the beta, they will be able to rejoin.`
      )
    ) {
      this.adminService.removeBetaMember(this.program.id, userId).subscribe((result) => {
        this.members = this.members.filter((m) => m.user_id !== userId);
        let modalRef = this.appService.showModal(GenericAlertComponent, { size: "lg" });
        let msg = `
          <span class="brandfont-bold">${user.firstName + " " + user.lastName} has been successfully removed!</span><br /><br /> If you are trying to ban a user from the beta program entirely,
          you will need to remove them from the group that is elligible to join the beta. Users in
          the following groups can join this beta, click below to edit this user's profile and remove them from the relevant groups.<br /><br />
          <ul>
        `;
        this.programGroups.forEach((group) => {
          msg += `<li>${group.description}</li>`;
        });
        msg += "</ul>";
        modalRef.componentInstance.html = msg;
        modalRef.componentInstance.submitText = "Edit " + user.firstName + "'s Groups";
        modalRef.componentInstance.submitFn = () => {
          this.appService.closeModals();
          this.router.navigate([`/customer/detail/${userId}`], { queryParams: { section: "profile" } });
        };
      });
    }
  }

  removeTag(tag) {
    this.betaTags = this.betaTags.filter((t) => t.id !== tag.id);
    this.betaService.deleteBetaTag(tag.id).subscribe();
  }

  addTag() {
    this.creatingTag = true;
    let name = this.betaTagName;
    this.betaTagName = "";
    this.betaService
      .createBetaTag({
        title: name,
        string_id: name.replace(" ", "_").toLowerCase(),
        beta_string_id: this.program.string_id,
      })
      .subscribe((result) => {
        this.initTags();
      });
  }

  showDatePicker(start?) {
    let modalInstance = this.appService.showModal(DatePickerComponent, { size: "lg", backdrop: "static", ariaLabelledBy: "modal-title" });
    modalInstance.componentInstance.past = false;
    if (start && this.program.start_date) {
      modalInstance.componentInstance.initWithDate = new Date(this.program.start_date);
    } else if (!start && this.program.end_date) {
      modalInstance.componentInstance.initWithDate = new Date(this.program.end_date);
    }
    modalInstance.componentInstance.earliestYear = new Date().getFullYear();
    modalInstance.componentInstance.dateSelected.subscribe((date) => {
      this.program[start ? "start_date" : "end_date"] = date;
    });
  }

  submit() {
    this.loading = true;
    let groupIds = [];
    for (const i in this.groupMap) {
      if (this.groupMap[i].selected) groupIds.push(i);
    }
    this.program.group_ids = groupIds.join(",");
    if (this.associatedProducts?.length) {
      this.program.product_id = this.associatedProducts.map((p) => p.id).join(",");
    } else {
      this.program.product_id = "";
    }
    if (this.program.start_date) this.program.start_date = moment(this.program.start_date).format("YYYY-MM-DD");
    if (this.program.end_date) this.program.end_date = moment(this.program.end_date).format("YYYY-MM-DD");
    delete this.program.products;
    this.adminService.createBeta(this.program).subscribe((result) => {
      this.programAdded.next(result);
      this.activeModal.close();
    });
  }
}
