<div class="d-flex d-md-flex justify-content-between align-items-center m-b-10">
  <h4 class="text-truncate content-title">Manage Beta Programs</h4>
  <button class="btn btn-secondary" (click)="addEditBetaProgram()">
    <fa-icon icon="plus"></fa-icon>
    Add
  </button>
</div>
<div class="row">
  <div class="col-md-12">
    <ng-container *ngIf="betas && !betas.length">
      <span class="t-16 d-block m-t-30 gothic text-center">No beta programs are currently scheduled.</span>
    </ng-container>
    <myp-inline-loader [loading]="true" height="200px" spinnerName="loader" *ngIf="loading"></myp-inline-loader>
    <ng-container *ngIf="betas && betas.length">
      <myp-list-item
        *ngFor="let item of betas"
        [title]="item.title"
        [showImage]="false"
        [subData]="[
          [item.description.substring(0, 200) + '...'],
          item.start_date ? 'Starts ' + (item.start_date | date) : 'No start date.',
          item.end_date ? 'Ends ' + (item.end_date | date) : 'No end date.'
        ]"
        [item]="item"
        (itemClicked)="addEditBetaProgram(item)"
      >
        <button class="fa-button">
          <fa-icon icon="edit" placement="top" [ngbTooltip]="'Edit'" class="action circle-icon-btn m-r-5"></fa-icon>
          <span class="sr-only">Edit Beta Program</span>
        </button>
        <button class="fa-button" (click)="deleteBetaProgram(item); $event.stopPropagation()">
          <fa-icon icon="times" placement="top" [ngbTooltip]="'Delete'" class="action circle-icon-btn m-r-5"></fa-icon>
          <span class="sr-only">Delete Beta Program</span>
        </button>
      </myp-list-item>
    </ng-container>
  </div>
</div>
