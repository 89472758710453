<main class="column-layout-container" basepage="primary">
  <div class="menu animate-in-from-left" [class.mobile-shown]="appService.mobileMenuShown">
    <aside role="region" aria-label="side navigation">
      <div class="menu-top d-flex justify-content-between align-items-center">
        <h1 class="menu-title">
          {{ "header.nav.products" | translate }}
        </h1>
        <div>
          <button class="fa-button m-r-10" (click)="registerProduct($event)" placement="bottom" [ngbTooltip]="'general.register_product' | translate">
            <fa-icon icon="plus" class="action create-action circle-icon-btn" aria-hidden="true"></fa-icon>
            <span class="sr-only">{{ "general.register_product" | translate }}</span>
          </button>
          <button
            class="fa-button m-r-10"
            placement="bottom"
            [ngbTooltip]="(refreshing ? 'general.refreshing_dots' : 'products.refresh_products') | translate"
            (click)="refresh()"
            aria-label="refresh list"
          >
            <fa-icon icon="sync" class="action refresh circle-icon-btn" [animation]="refreshing ? 'spin' : undefined" aria-hidden="true"></fa-icon>
            <span class="sr-only">{{ (refreshing ? "general.refreshing_dots" : "products.refresh_products") | translate }}</span>
          </button>
        </div>
      </div>
      <nav class="nav flex-column" aria-label="side menu">
        <ul class="menu-options">
          <li class="menu-option" [class.active]="selectedParentCategory == 'all'">
            <a
              class="primary-option"
              href="javascript:void(0);"
              (click)="appService.mobileMenuShown = false; changeCategory('all')"
              (keyup.enter)="appService.mobileMenuShown = false; changeCategory('all')"
              (keyup.space)="appService.mobileMenuShown = false; changeCategory('all')"
              [attr.aria-current]="selectedParentCategory == 'all' ? 'page' : false"
            >
              {{ "newdash.all" | translate }}
            </a>
          </li>
          <ng-container *ngFor="let cat of productService.mainCategories">
            <li class="menu-option" *ngIf="cat.productCount" [class.active]="selectedParentCategory == cat.id_producttypes">
              <a
                class="primary-option"
                href="javascript:void(0);"
                (click)="appService.mobileMenuShown = false; changeCategory(cat.id_producttypes)"
                (keyup.enter)="appService.mobileMenuShown = false; changeCategory(cat.id_producttypes)"
                (keyup.space)="appService.mobileMenuShown = false; changeCategory(cat.id_producttypes)"
              >
                {{ cat.type_producttypes }}
                <span class="list-item-badge">
                  <span class="sr-only">total count</span>
                  {{ cat.productCount }}
                </span>
              </a>
              <ul class="menu-suboptions" *ngIf="getCategoryChildren(cat)">
                <ng-container *ngFor="let subcat of getCategoryChildren(cat)">
                  <li class="sub-option d-block" *ngIf="subcat.productCount">
                    <a
                      href="javascript:void(0);"
                      (keyup.enter)="changeCategory(subcat.id_producttypes)"
                      (keyup.space)="changeCategory(subcat.id_producttypes)"
                      (click)="appService.mobileMenuShown = false; selectedParentCategory = cat.id_producttypes; changeCategory(subcat.id_producttypes)"
                      [class.active]="selectedCategory == subcat.id_producttypes"
                    >
                      {{ subcat.type_producttypes }}
                      <span class="list-item-badge">
                        <span class="sr-only">total count</span>
                        {{ subcat.productCount }}
                      </span>
                    </a>
                    <ul class="menu-suboptions" *ngIf="getCategoryChildren(subcat)">
                      <ng-container *ngFor="let subsubcat of getCategoryChildren(subcat)">
                        <li class="sub-option d-block" *ngIf="subsubcat.productCount">
                          <a
                            href="javascript:void(0);"
                            (keyup.enter)="changeCategory(subsubcat.id_producttypes)"
                            (keyup.space)="changeCategory(subsubcat.id_producttypes)"
                            (click)="appService.mobileMenuShown = false; selectedParentCategory = cat.id_producttypes; changeCategory(subsubcat.id_producttypes)"
                            [class.active]="selectedCategory == subsubcat.id_producttypes"
                          >
                            {{ subsubcat.type_producttypes }}
                            <span class="list-item-badge">
                              <span class="sr-only">total count</span>
                              {{ subsubcat.productCount }}
                            </span>
                          </a>
                        </li>
                      </ng-container>
                    </ul>
                  </li>
                </ng-container>
              </ul>
            </li>
          </ng-container>

          <li class="menu-option" [class.active]="selectedParentCategory == 'other'" *ngIf="sharedProductsListCmp.uncategorizedProducts && sharedProductsListCmp.uncategorizedProducts.length">
            <a class="primary-option" tabindex="0" (click)="appService.mobileMenuShown = false; changeCategory('other')" (keyup.enter)="appService.mobileMenuShown = false; changeCategory('other')">
              {{ "sphere.other" | translate }}
              <span class="list-item-badge">
                <span class="sr-only">total count</span>
                {{ sharedProductsListCmp.uncategorizedProducts.length }}
              </span>
            </a>
          </li>
        </ul>
      </nav>
    </aside>
  </div>
  <section
    role="contentinfo"
    #contentContainer
    (scroll)="contentContainerScroll($event)"
    class="content animate-in-from-right"
    [class.mobile-shown]="!appService.mobileMenuShown"
    aria-label="products"
  >
    <div class="mobile-back-btn" (click)="appService.mobileMenuShown = true">
      <fa-icon icon="chevron-left"></fa-icon>
      Product Categories
    </div>
    <myp-shared-products-list
      class="h-full"
      #sharedProductsListCmp
      (registerClicked)="registerProduct()"
      (listLoaded)="listLoaded($event)"
      [selectedCategory]="selectedCategory"
    ></myp-shared-products-list>
  </section>
</main>
