<div>
  <div class="d-flex" *ngIf="profile && !loading">
    <div class="profile-info text-center m-b-20" style="width: 100%">
      <div class="cover-photo" [ngStyle]="{ 'background-image': coverPhoto ? 'url(' + coverPhoto + ')' : 'url(./assets/images/studio-one-plus-profile-banner.png)' }">
        <div class="profile-back-container">
          <button type="button" class="btn btn-transparent p-0" (click)="helperService.goBack()" aria-label="back">
            <fa-icon icon="arrow-left" class="circle-icon-btn" aria-hidden="true" ngbTooltip="Back"></fa-icon>
            <span class="sr-only">back</span>
          </button>
        </div>
        <img
          [src]="
            profile.photo && (profile.id == user.id || profile.public || isFeatured)
              ? profile.photo + (userService.profilePhotoCacheBuster ? '?' + userService.profilePhotoCacheBuster : '')
              : './assets/images/default_avatar.jpg'
          "
          class="profile-photo"
          [alt]="(profile.feature ? profile.feature.name : profile.firstName && profile.lastName ? profile.firstName + ' ' + profile.lastName : 'user') + ' profile photo'"
        />
      </div>
      <div class="profile-details d-flex m-t-50">
        <div class="center-col">
          <h1 class="bold">
            {{ profile.feature ? profile.feature.name : profile.firstName && profile.lastName ? profile.firstName + " " + profile.lastName : ("com.no_name_entered" | translate) }}
          </h1>
          <div>
            <a class="small-btn" tabindex="0" role="link" *ngIf="account_id == user.id" [routerLink]="['/account/general']">{{ "profile.edit_profile" | translate }}</a>
            <button class="small-btn" *ngIf="user.is_admin" (click)="uploadWare()">Upload Exchange Item</button>
            <button class="small-btn" *ngIf="user.is_admin" (click)="moderate()">Moderate</button>
          </div>
          <span *ngIf="profile.public || isFeatured || profile.id == user.id">{{ profile.tagline }}</span>
          <div class="col-md-8 offset-md-2 col-lg-6 offset-lg-3 w-full m-t-10" *ngIf="communityService.communityWizards[profile.id]">
            {{
              profile.firstName +
                " is an official Studio One Pro+ Community Moderator. " +
                profile.firstName +
                " both proactively moderates the posts and conversations in Community, and personally reviews any flags initiated by Community members."
            }}
          </div>
        </div>
      </div>
      <div class="profile-content text-center m-t-50" *ngIf="!profile.public && !isFeatured && user.active_subscription">
        {{ (profile.id == user.id ? "com.your_profile_private" : "com.their_profile_private") | translate }}
        <div *ngIf="profile.id == user.id">
          <span class="small-btn blue" (click)="makePublic()">{{ "com.make_public" | translate }}</span>
        </div>
      </div>
      <div class="w-full align-items-center d-flex justify-content-center m-t-50">
        <div class="profile-content text-left" *ngIf="profile && (profile.public || profile.id == user.id || isFeatured)">
          <ul class="nav nav-pills justify-content-center">
            <li class="nav-item">
              <a
                class="nav-link"
                (click)="switchSection('about')"
                tabindex="0"
                href="javascript:void(0)"
                (keyup.enter)="switchSection('about')"
                [class.active]="displayedSection.id == 'about'"
                [attr.aria-current]="displayedSection.id == 'about' ? 'page' : false"
              >
                {{ "com.about" | translate }}
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                (click)="switchSection('posts')"
                tabindex="0"
                href="javascript:void(0)"
                (keyup.enter)="switchSection('posts')"
                [class.active]="displayedSection.id == 'posts'"
                [attr.aria-current]="displayedSection.id == 'posts' ? 'page' : false"
              >
                {{ "com.posts" | translate }}
              </a>
            </li>
            <li class="nav-item" *ngIf="wares && wares.data.length">
              <a
                class="nav-link"
                (click)="switchSection('exchangecontent')"
                tabindex="0"
                href="javascript:void(0)"
                (keyup.enter)="switchSection('exchangecontent')"
                [class.active]="displayedSection.id == 'exchangecontent'"
              >
                {{ "com.exchange_content" | translate }}
              </a>
            </li>
            <li class="nav-item" *ngIf="account_id == user.id && ((connections && connections.length) || (invites && invites.length))">
              <a
                class="nav-link"
                (click)="switchSection('connections')"
                tabindex="0"
                href="javascript:void(0)"
                (keyup.enter)="switchSection('connections')"
                [class.active]="displayedSection.id == 'connections'"
              >
                {{ "com.connections" | translate }}
              </a>
            </li>
            <li class="nav-item" *ngIf="account_id == user.id">
              <a
                class="nav-link"
                (click)="switchSection('pinned_posts')"
                tabindex="0"
                href="javascript:void(0)"
                (keyup.enter)="switchSection('pinned_posts')"
                [class.active]="displayedSection.id == 'pinned_posts'"
              >
                Pinned Posts
              </a>
            </li>
          </ul>
        </div>
        <div class="m-b-10 m-l-30" *ngIf="account_id != user.id && profile.public">
          <button *ngIf="!connectionStatus" class="btn btn-primary" (click)="showConnectModal()">
            <fa-icon icon="plus"></fa-icon>
            {{ "com.connect" | translate }}
          </button>
          <span *ngIf="connectionStatus && connection.initiated_by == user.id" class="gothic t-14 m-r-5">
            <fa-icon icon="check"></fa-icon>
            {{ (connectionStatus == "accepted" ? "com.connected" : "com.invite_sent") | translate }}
          </span>

          <div *ngIf="connectionStatus && connectionStatus == 'pending' && connection.initiated_by == profile.id" class="d-flex align-items-center">
            <span class="m-r-10">Connection Requested</span>
            <span class="btn btn-primary" (click)="reviewConnection(connection)">Review</span>
          </div>

          <fa-icon
            *ngIf="connectionStatus && connectionStatus == 'accepted'"
            icon="comments"
            class="action circle-icon-btn m-r-5"
            [ngbTooltip]="'com.send_message' | translate"
            (click)="sendMessage()"
          ></fa-icon>
          <fa-icon
            *ngIf="connectionStatus && connectionStatus == 'accepted'"
            icon="ellipsis-h"
            class="action circle-icon-btn"
            [ngbTooltip]="'sphere.options' | translate"
            (click)="showOptions()"
          ></fa-icon>
          <!-- <button *ngIf="connectionStatus && connectionStatus == 'accepted'" class="btn m-l-10 btn-primary" (click)="showOptions()"> <fa-icon icon="ellipsis-h"></fa-icon> Options</button> -->
        </div>
      </div>
    </div>
  </div>
  <myp-inline-loader [loading]="loading" height="400px" spinnerName="myItemsExchange"></myp-inline-loader>
  <ng-container *ngIf="profile && !loading && (profile.public || profile.id == user.id || isFeatured)">
    <div *ngIf="displayedSection.id == 'about'" class="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
      <h2 class="section-header">{{ "profile.edit.description" | translate }}</h2>
      <div class="m-b-20">
        <span class="description" *ngIf="profile.feature || profile.description" [innerHTML]="profile.feature ? profile.feature.htmlDescription : profile.htmlDescription"></span>
        <div class="text-center m-y-20 gothic" *ngIf="!profile.feature && !profile.description">
          {{ (profile.id == user.id ? "com.you_no_description" : "com.they_no_description") | translate: { name: profile.firstName } }}
        </div>
      </div>

      <ng-container *ngIf="(profile.id == user.id || (mySkills && mySkills.length)) && user.active_subscription">
        <h2 class="section-header">{{ "com.skills_word" | translate }}</h2>
        <div class="m-b-20">
          <div class="list-item hover dontgrow" *ngFor="let skill of mySkills">
            <div class="list-item-container">
              <div class="main-data">
                <h3 class="title">{{ "com.skills." + skill.string_id | translate }}</h3>
                <span class="sub-data" *ngIf="skill.is_service || skill.notes">
                  <ng-container *ngIf="skill.is_service">
                    <div>{{ "com.offered_as_service" | translate }}</div>
                    <!-- <div *ngIf="skill.hourly_rate">Hourly rate: ${{ skill.hourly_rate }}</div> -->
                  </ng-container>
                  <div *ngIf="skill.notes" [innerHTML]="skill.htmlNotes"></div>
                </span>
              </div>
            </div>
          </div>
          <div class="text-center m-y-20" *ngIf="!mySkills || !mySkills.length">
            <span class="brandfont-bold">{{ "com.no_skills_added" | translate }}</span>
            <br />
            <span>{{ "com.skills_desc" | translate }}</span>
          </div>
          <div *ngIf="account_id == user.id" [class.text-center]="!mySkills || !mySkills.length">
            <a class="small-btn" tabindex="0" role="link" [routerLink]="['/account/skills']">{{ "com.add_edit_skills" | translate }}</a>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="(profile.id == user.id || (showcases && showcases.length)) && user.active_subscription">
        <h2 class="section-header">{{ "com.showcases" | translate }}</h2>
        <div class="m-b-50">
          <div class="m-t-30" *ngFor="let showcase of showcases">
            <span class="t-20 bold m-b-20 d-block">{{ showcase.description }}</span>
            <myp-waveform-comments [showComments]="false" #waveformComments [isPostAttachment]="true" [file]="showcase"></myp-waveform-comments>

            <div class="d-flex flex-wrap align-items-center position-relative">
              <span class="t-12 text-muted">{{ "com.skills_word" | translate }}:</span>
              <ng-container *ngFor="let channel of showcase.skills">
                <span class="showcase-selected-skill">{{ "com.skills." + channel.string_id | translate }}</span>
              </ng-container>
            </div>
          </div>
          <div class="text-center m-y-20" *ngIf="!showcases || !showcases.length">
            <span class="brandfont-bold">{{ "com.no_showcases_added" | translate }}</span>
            <br />
            <span>{{ "com.showcases_desc" | translate }}</span>
          </div>
          <div *ngIf="account_id == user.id" [class.text-center]="!showcases || !showcases.length">
            <a class="small-btn" tabindex="0" role="link" [routerLink]="['/account/showcases']">{{ "com.add_showcase" | translate }}</a>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="text-center" *ngIf="displayedSection.id == 'posts' || displayedSection.id == 'pinned_posts'">
      <div class="text-left">
        <ng-container *ngFor="let post of communityService.activeParentPosts; let i = index">
          <myp-comment [comment]="post" [isCommunityPost]="true" [replies]="communityService.activeParentThreadMap[post.key]" [index]="i"></myp-comment>
        </ng-container>
        <div class="text-center m-t-50" *ngIf="displayedSection.id == 'pinned_posts' && !loadingPins && noPins">
          You haven't pinned any posts. If you find a post you want to pin, click the thumbtack icon and it will display here. You will also be notified of activity on that post.
        </div>
        <div class="text-center m-t-50" *ngIf="displayedSection.id == 'posts' && !loadingPosts && noPosts">No posts to display.</div>
      </div>
    </div>
    <div class="m-b-30" *ngIf="displayedSection.id == 'exchangecontent'">
      <div class="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
        <div *ngIf="!loading && wares && wares.data.length" class="m-t-30">
          <myp-exchange-list
            (itemTapped)="viewAsset($event)"
            (filterByCategory)="selectWareCategory($event)"
            [creator_id]="account_id"
            [wares]="filteredWares"
            [filterCategories]="wareCategories"
          ></myp-exchange-list>
        </div>
        <div class="text-center text-lg" style="opacity: 0.5; max-width: 300px; margin: 50px auto" *ngIf="wares && wares.data && !wares.data.length">
          {{ isMe ? ("exchange.havent_created_anything" | translate) : ("sphere.user_not_created_anything" | translate) }}
        </div>
      </div>
    </div>
    <div class="col-md-8 offset-md-2 col-lg-6 offset-lg-3" *ngIf="displayedSection.id == 'connections'">
      <div class="m-b-20" *ngIf="invites && invites.length">
        <h2 class="section-header">PENDING INVITES</h2>
        <myp-list-item
          *ngFor="let invite of invites"
          [photo]="invite?.photoURL ? invite.photoURL : './assets/images/default_avatar.jpg'"
          [title]="invite.firstName + ' ' + invite.lastName"
          [imageRound]="true"
          [item]="invite"
          (itemClicked)="reviewConnection(invite)"
          [subData]="['Invited you to connect on ' + (invite.created | date)]"
        >
          <span class="m-r-10">{{ "general.review" | translate }}</span>
        </myp-list-item>
      </div>
      <div *ngIf="connections && connections.length">
        <h2 class="section-header">MY CONNECTIONS</h2>
        <div class="item-filter m-b-10">
          <div class="filter-input no-max-width">
            <input type="text" [(ngModel)]="connectionFilter" (ngModelChange)="doFilter()" [placeholder]="'com.type_to_filter' | translate" />
            <div class="clear" (click)="connectionFilter = ''; doFilter()" *ngIf="connectionFilter"><fa-icon icon="times"></fa-icon></div>
          </div>
        </div>
        <myp-list-item
          *ngFor="let connection of displayedConnections"
          [photo]="connection?.photoURL ? connection.photoURL : './assets/images/default_avatar.jpg'"
          [title]="connection.firstName + ' ' + connection.lastName"
          [item]="connection"
          [inactive]="connection.status == 'pending'"
          [subData]="['Status: ' + (connection.status | titlecase)]"
          (itemClicked)="goToConnection($event)"
        >
          <fa-icon icon="ellipsis-h" placement="left" (click)="connectionOptions($event, connection)" [ngbTooltip]="'Options'" class="action circle-icon-btn m-r-10"></fa-icon>
        </myp-list-item>
      </div>
    </div>
  </ng-container>
</div>
